import React from 'react';
import styled from "styled-components";
import { Input } from 'antd';

const StyledInput = styled(Input).attrs({className: 'inputField'})`

&[type], input {
    background-color: ${props => props.theme[props.theme.mode].background.primary
    };
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66;
    border-radius: 5px;
}
&[type]:hover, input:hover {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
}
&[type]:focus, input:focus {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
* {
  color: ${props => props.theme[props.theme.mode].primary.main};
}
&.lightBg {
    background-color: rgba(255, 255, 255, 0.5);
}
`;

const InputWrapper = (props) => {
  return (
    props.password ? (
      <StyledInput as={Input.Password} {...props}></StyledInput>
    ) : (
        <StyledInput {...props}></StyledInput>
      )
  );
}

export default InputWrapper;