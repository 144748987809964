import { put, takeLatest } from "redux-saga/effects";
import {
  UPLOAD_STATEMENTS_REQUEST,
  GET_STATEMENTS_REQUEST,
  DELETE_STATEMENTS_REQUEST,
  UPDATE_STATEMENTS_REQUEST
} from "../configurations/Types";
import { BANK_ACCOUNT_ENDPOINT } from "../configurations/Constants";
import {
  uploadStatementsRequest,
  uploadStatementsSuccess,
  uploadStatementsError,
  updateStatementsRequest,
  updateStatementsSuccess,
  updateStatementsError,
  getStatementsRequest,
  getStatementsSuccess,
  getStatementsError,
  deleteStatementsRequest,
  deleteStatementsSuccess,
  deleteStatementsError
} from "../actions/Statements";
import {
  STATEMENT_ENDPOINT,
} from "../configurations/Constants";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

import { CancelToken } from "../configurations/AxiosAgent";


let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");
let token;
// addTransactions Deposit & WithDrawl
const uploadStatementApi = body => {
  // current_time;
  console.log("body", body);
  // console.log("saga Field", body.bankName.split("*"[0]));

  // if(body.splits) {
  //   for (const splitObj of body.splits){
  //     console.log("Split Obj: ", splitObj);
  //     for (const split in splitObj) {
  //       splitObj[split] = split === "amount" ? splitObj[split] ? parseFloat(splitObj[split]).toFixed(2) : "" : parseInt(splitObj[split]); // split_data.job_id
  //     }
  //   }
  // }
  console.log("Body API before: ", body)
  let bodyAPI = {
    file: body.image,
    current_time: current_time(),
  };
  console.log("Body API after:", body);
  // if(!body.is_split) {
  //   // delete bodyAPI.account_head_id;
  //   // delete bodyAPI.job_id;
  //   delete bodyAPI.split_data;
  // }

  let formData = new FormData();
  console.log("obj k bhair");

  for (const key in bodyAPI) {
    console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    formData.append(key, bodyAPI[key]);
  }
  // Object.keys(bodyAPI).forEach((item, index) => {
  //   console.log("item sagasas",item)
  //   // formData.append(item);
  // });

  console.log("BODY API", bodyAPI);
  return privateAgent.post(
    `${STATEMENT_ENDPOINT}`,
    formData,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
};
function* uploadStatement(action) {
  let res;
  console.log("ACTION IS THIS: ", action);
  try {
    res = yield uploadStatementApi(action.body);
    yield put(uploadStatementsSuccess());
    info("success", res.data.data.message);
    yield put(getStatementsRequest());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(uploadStatementsError());
    }
  }
}

const getStatementAPI = (token) => {
  //console.log("singleBankDetailsAPI", params);

  // let id = params.id;
  // delete params.id;
  return privateAgent.get(
    `${STATEMENT_ENDPOINT}`,
    {
      cancelToken: token.token
    }
  );
};

function* getStatement(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield getStatementAPI(token);
    console.log("USER API GET", res);
    yield put(
      getStatementsSuccess(res.data.data)
    );
    if (action.cb) {
      action.cb(res.data.data, null);
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getStatementsError(e));
  } finally {
    // if (yield cancelled()) {
    //   console.log("CANCELLED");
    // }
  }
  //console.log("In saga", res);
  // if (action.callback) {
  //   action.callback(res);
  // }
}
const deleteStatementApi = (id) => {
  return privateAgent.delete(
    `${STATEMENT_ENDPOINT}/${id}`
  );
};

function* deleteStatement(action) {
  let res;
  try {
    res = yield deleteStatementApi(action.id);
    yield put(deleteStatementsSuccess());
    info("success", "Statement successfully deleted");
    yield put(getStatementsRequest());
    console.log("YEIDLER: ", yield put(getStatement()));
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response) info("error", `${e.response.data.error.message}`);
    yield put(deleteStatementsError());
  }
}


const updateStatementApi = (nameOfFile, id) => {
  console.log("body updateSTATEMENTApi", nameOfFile);
  let file_name = nameOfFile.fileName;
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  // console.log("current_time", current_time);
  return privateAgent.put(`${STATEMENT_ENDPOINT}/${id}`, {
    file_name,
    //open_date: moment(body.open_date).format("YYYY-MM-DD"),
    current_time
  });
};

function* updateStatements(action) {
  let res;
  console.log("This is action :", action);
  try {
    // action.body.open_date = moment(action.body.open_date).format("YYYY-MM-DD");
    res = yield updateStatementApi(action.file_name, action.fileID);
    // console.log("updateBankApi",res)
    info("success", `${res.data.data.message}`);
    yield put(updateStatementsSuccess());
    yield put(getStatementsRequest());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(updateStatementsError());
  }
}
export function* watchStatement() {
  yield takeLatest(UPLOAD_STATEMENTS_REQUEST, uploadStatement);
  yield takeLatest(DELETE_STATEMENTS_REQUEST, deleteStatement);
  yield takeLatest(GET_STATEMENTS_REQUEST, getStatement);
  yield takeLatest(UPDATE_STATEMENTS_REQUEST, updateStatements);
}
