import React from "react";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
import ReconciliationForLawyersForm from "../forms/ReconciliationForLawyers";

import { openModal, closeAllModal } from "../../../../actions/Modal"


const ReconiliationForLawyers = props => (
  <ReportTypeCard
  initialValue={props.initialType || "pdf"} 
  heading="Reconciliation for Lawyers" 
  onSubmit={format => {
    props.openModal(
      null,
      <ReconciliationForLawyersForm initialValues={props.initialFormValues} format={format} />,
      null,
      null,
      {
        footer: null
      }
    );
  }} 
  />
)

export default connect(null, { openModal, closeAllModal })(ReconiliationForLawyers);
