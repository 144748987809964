import React from "react";
import { CardCvcElement } from "@stripe/react-stripe-js";
import { StripeStyle } from "./index";
import styled, { withTheme } from "styled-components";
import useScreenWidth from "../hooks/useScreenWidth";

const StyledCardCVC = styled(CardCvcElement).attrs({ className: "" })`
  ${StripeStyle} /* .desktop {
    font-size: 2px;
  }
  .mediaquery557px {

  } */
`;

const CardCVCWrapper = props => {
  const screenWidth = useScreenWidth();
  let theme = props.theme[props.theme.mode];
  return (
    <StyledCardCVC
      {...props}
      //onChange={(e) => console.log("stripe event", e)}
      options={{
        style: {
          base: {
            color: theme.textColor.primary,
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize:
              screenWidth > 1199
                ? `${screenWidth *
                    ((parseFloat(theme.textSizes.relative.captions) * 1) /
                      100)}px`
                : screenWidth > 991
                ? `${screenWidth *
                    ((parseFloat(theme.textSizes.relative.captions) * 1.15) /
                      100)}px`
                : screenWidth > 767
                ? `${((parseFloat(theme.textSizes.relative.captions)) * 16) - 4}px`
                : screenWidth > 576
                ? `${(parseFloat(theme.textSizes.actual.body)) * 16}px`
                : `${(parseFloat(theme.textSizes.actual.body)) * 13}px`,
            //lineHeight: 1,
            "::placeholder": {
              textTransform: "uppercase",
              fontWeight: 600,
              color: theme.textColor.hint
            },
          },
          invalid: {
            color: theme.textColor.primary,
          },
        }
      }}
    />
  );
};

export default withTheme(CardCVCWrapper);
