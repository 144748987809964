import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import AuthContainer from "../../../appcomponents/shares/auth/AuthContainer";
import RadioGrid from "../../../appcomponents/general/RadioGrid";
import { Link } from "react-router-dom";
import Button from "../../../components/button/index";
import Input from "../../../components/input";
// import RadioGroup from "../../../components/radio/RadioGroup";
// import Radio from "../../../components/radio/Radio";
import { withTheme } from "styled-components";
import {
  verificationCodeRequest,
  //passwordResetRequest
} from "../../../actions/Auth";


class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amountOption: null,
      amount: null,
      error: ""
    };
  }
  handleErrors = () => {
    let error = "";
    if (!this.state.amount && !this.state.amountOption) {
      error = "Please specify an amount";
    }
    else if (this.state.amount > 500) {
      error = "Please enter an amount less than $500";
    }
    else {
      error = "";
    }
    this.setState({ error: error })
    return error;
  }
  apiCall = () => {
    let isValid = this.handleErrors();
    if (isValid == "") {
      this.props.dispatch(
        verificationCodeRequest(
          {
            email: this.state.email,
            token: this.state.token
          },
          this.props.cb
        )
      );
    }
    else {
      console.error("Error: ", isValid);
    }
  }

  render() {
    // console.log("Verification Code State", this.state.token.length);
    // console.log("Verification Code Props", this.props);
    return (
      <AuthContainer
      heading="Let's Create An Account" 
      footer={<p>Already have an account? <Link to="/login">Sign In</Link></p>}
      steps={this.props.steps}
      backButtonLink="/main"
      >
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <p className="mr-b-md fontLight hintColor">
              CHOOSE AN AMOUNT YOU WANT TO DEPOSIT. OR ENTER MANUALLY
            </p>
          </Col>
          <Col xs={20} sm={16}>
            <RadioGrid
            value={this.state.amountOption}
            onChange={(e) => this.setState({ amountOption: e.target.value, amount: null, error: "" })}
            options={[
              {
                label: "$25",
                value: 25
              },
              {
                label: "$50",
                value: 50
              },
              {
                label: "$100",
                value: 100
              },
              {
                label: "$250",
                value: 250
              },
            ]}
            />
          </Col>
          <Col className="mr-t mr-b-md" xs={24} sm={18}>
            <p className="fontMedium">OR</p>
          </Col>
          <Col xs={24} sm={18}>
            <Input 
            className="inputField"
            type="number" 
            value={this.state.amount} 
            onChange={(e) => this.setState({ amount: parseInt(e.target.value), amountOption: null, error: "" })}
            placeholder="Other Amount"
            />
          </Col>
          <Col xs={24} sm={18} style={{ textAlign: "left" }}>
            <span className="dangerColor mr-b small">{this.state.error}</span>
          </Col>
          <Col xs={24} sm={18}>
            <Button
              loading={this.props.Auth.loading}
              onClick={() => {
                this.apiCall();
              }}
              className="mr-t-md mr-b-md"
              size="large"
              style={{ width: "50%" }}
            >
              Next
            </Button>
          </Col>
          <Col className="mr-b-lg" span={24}>
            <Link to="/login">Skip for now</Link>
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default withTheme(connect(storeState => ({ Auth: storeState.Auth }))(
  VerificationCode)
);