import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col, Progress } from "antd";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import BackButton from "../../appcomponents/shares/header/BackButton";
import { Link } from "react-router-dom";
import Button from "../../components/button/index";
import CodeWrapper from "../../components/input/Code";
import { withTheme } from "styled-components";
import { verificationCodeRequest, resendCodeRequest } from "../../actions/Auth";
import "./auth.css";

class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      code: "",
      error: "",
      timer: false,
      countDown: 60,
      timerId: null,
      renderCodeInput: false
    };
  }
  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState(
          {
            email: this.props.history.location.state.email
          },
        );
      } else {
        this.props.history.push({
          pathname: "/login"
        });
      }
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  }
  handleErrors = () => {
    let error = "";
    if (this.state.code.length == 0) {
      error = "Please enter the code ";
    } else if (this.state.code.length < 4 && this.state.code.length > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };
  handleChanged = val => {
    this.setState({
      code: val
    });
  };
  redirectNext = () => {
    this.props.history.push({
      pathname: "/reset-password",
      state: {
        email: this.state.email
      }
    });
  };
  apiCall = () => {
    let isValid = this.handleErrors();
    if (isValid == "") {
      this.props.dispatch(
        verificationCodeRequest(
          {
            email: this.state.email,
            code: this.state.code
          },
          this.redirectNext
        )
      );
    } else {
      console.error("Error: ", isValid);
    }
  };
  reRenderCodeInput = () => {
    if (this.state.renderCodeInput) {
      return (
        <React.Fragment>
          <CodeWrapper
            key="1"
            value=""
            renderCodeInput={this.state.renderCodeInput}
            className="mr-b"
            type="tel"
            fields="6"
            onChange={this.handleChanged}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <CodeWrapper
            key="2"
            value=""
            renderCodeInput={this.state.renderCodeInput}
            className="mr-b"
            type="tel"
            fields="6"
            onChange={this.handleChanged}
          />
        </React.Fragment>
      );
    }
  };
  resendApiCall = () => {
    this.setState({ code: "", renderCodeInput: !this.state.renderCodeInput });
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      } else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
    this.props.dispatch(
      resendCodeRequest({ email: this.state.email }, () => {
        console.log("Reset Fields");
      })
    );
  };

  render() {
    // console.log("Verification Code State", this.state);
    // console.log("Verification Code State", this.state.code.length);
    // console.log("Verification Code Props", this.props);
    return (
      <Fragment>
        <Row type="flex" className="setPosition">
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <BackButton
              title="Reset Your Password"
              action={() =>
                this.props.history.push(
                  this.props.backButtonLink
                    ? "/forgot-password"
                    : "/login"
                )
              }
            />{" "}
          </Col>
        </Row>

        <AuthContainer>
          <Row type="flex" justify="center" align="middle">
            <Col xs={24} md={14} lg={9}>
              <h4 className="mr-b mr-t-md">
                Enter the 6 digit code we've sent on your email
              </h4>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {this.reRenderCodeInput()}
            </Col>
            <Col span={20}>
              {/* //Resend code */}
              {this.state.timer == true ? (
                <Progress
                  //className="card-link-out"
                  width="50px"
                  format={per => this.state.countDown}
                  type="circle"
                  strokeColor={{
                    "0%": this.props.theme[this.props.theme.mode].primary.main,
                    "100%": this.props.theme[this.props.theme.mode].primary.main
                  }}
                  percent={(this.state.countDown / 60) * 100}
                />
              ) : (
                <Link
                  onClick={() => this.resendApiCall()}
                  //className="card-link-out"
                >
                  Resend Code
                </Link>
              )}
            </Col>
            <Col span={20}>
              <span style={{ color: "#f44336" }}>{this.state.error}</span>
            </Col>
            <Col xs={16} md={12} lg={10} xl={8}>
              <Button
                loading={this.props.Auth.loading}
                onClick={() => {
                  this.apiCall();
                }}
                className="mr-t-md mr-b-lg"
                size="large"
                block
              >
                Continue
              </Button>
            </Col>
          </Row>
        </AuthContainer>
      </Fragment>
    );
  }
}

export default withTheme(
  connect(storeState => ({ Auth: storeState.Auth }))(VerificationCode)
);
