import React, { useState, useEffect, Fragment } from "react";
import { useFormik } from "formik";

function createFakeEvent(name, value) {
  return {
    target: {
      name,
      value
    }
  };
}

const useFormikWrapper = props => {

  const formik = useFormik({
    ...props,
  });

  //Custom handle change to wrap incoming values in a fake event and also to call parent change method
  formik.customHandleChange = (e, name) => {
    if (e && e.target) {
      //formik.handleChange(e);
      formik.setFieldValue(e.target.name, e.target.value);
    } else {
      //formik.handleChange(createFakeEvent(name, e));
      formik.setFieldValue(name, e);
    }
  };

  formik.customHandleBlur = (e, name) => {
    if (e && e.target) {
      formik.handleBlur(e);
    } else {
      formik.handleBlur(createFakeEvent(name, ""));
    }
  }

  return formik;
};

export default useFormikWrapper;
