import React from "react";
import { Row, Col } from "antd";
import Card from ".././../../components/card";
import PDFICON from ".././../../assets/images/pdf.png";
import { FiDownload } from "react-icons/fi";

const DownloadSettingCard = (props) => {
  console.log("Instruction PDF", props);
  return (
    <Card smallPadding>
      <Row type="flex" justify="center" align="middle">
        <Col span={24} style={{ textAlign: "center" }}>
          <Row type="flex" justify="space-between" align="middle">
            {/* <Col>
              <img
                style={{ width: "90%" }}
                src={PDFICON}
                alt="PDF ICON NOt Found"
              />
            </Col> */}
            <Col>
              <h3 className="" style={{ marginTop: "5px", fontSize:"13px" }}>
                {props.heading}
              </h3>
            </Col>
            {/* <Col>
              <img
                style={{ width: "90%" }}
                src={PDFICON}
                alt="PDF ICON NOt Found"
              />
            </Col> */}

            <Col style={{marginRight: "58px"}}>
              <img
                style={{ marginRight: "5px" }}
                src={PDFICON}
                alt="PDF ICON NOt Found"
              />
              <FiDownload
                style={{ cursor: "pointer" }}
                className="appColor"
                onClick={() =>
                  window.open(
                    "https://api2.escrowtrakker.com/backend/web/instructions_phase_1.pdf"
                  )
                }
              ></FiDownload>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default DownloadSettingCard;
