import React, { useState } from "react";
import { Tabs } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";

const StyledTabs = styled(Tabs)`
  color: ${props => props.theme[props.theme.mode].textColor.primary};
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
  font-weight: 700;
  /* * {
    transition: 0s !important;
  } */
  & .ant-tabs-bar {
    border: none;
    margin: 0;
  }
  .ant-tabs-nav-container {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
    font-weight: 700;
  }
  & .ant-tabs-nav {
    width: 100%;
  }
  & .ant-tabs-nav > div {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
  }
  & .ant-tabs-nav .ant-tabs-tab {
    margin: 0.15rem 0;
    padding: 0.7rem 1.15rem;
    color: ${props => props.theme[props.theme.mode].primary.main};
    width: 100%;
    text-align: center;
    border: 0.09rem solid ${props => props.theme[props.theme.mode].primary.main};
    border-left-width: 0.04rem;
    border-right-width: 0.04rem;
    border-radius: 0;
    :first-child {
      border-radius: 0.45rem 0 0 0.45rem;
      border-left-width: 0.09rem;
    }
    :last-child {
      border-radius: 0 0.45rem 0.45rem 0;
      border-right-width: 0.09rem;
    }
  }

  &.alternateColors .ant-tabs-nav .ant-tabs-tab:nth-child(even) {
    color: ${props => props.theme[props.theme.mode].secondary.main};
    border: 0.09rem solid ${props => props.theme[props.theme.mode].secondary.main};
    }
  }
  &.alternateColors .ant-tabs-nav .ant-tabs-tabnth-child(odd) {
    color: ${props => props.theme[props.theme.mode].primary.main};
    border: 0.09rem solid ${props => props.theme[props.theme.mode].primary.main};
    }
  }

  & .ant-tabs-nav .ant-tabs-tab-active {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].primary.main};
    font-weight: 700;
  }
  .ant-tabs-nav .ant-tabs-tab:active,
  .ant-tabs-nav .ant-tabs-tab:focus,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].primary.main};
  }

  &.alternateColors .ant-tabs-nav .ant-tabs-tab-active:nth-child(even) {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].secondary.main};
  }
  &.alternateColors .ant-tabs-nav .ant-tabs-tab-active:nth-child(odd) {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].primary.main};
  }

  &.alternateColors .ant-tabs-nav .ant-tabs-tab:active:nth-child(even),
  &.alternateColors .ant-tabs-nav .ant-tabs-tab:focus:nth-child(even),
  &.alternateColors .ant-tabs-nav .ant-tabs-tab:hover:nth-child(even) {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].secondary.main};
  }
  &.alternateColors .ant-tabs-nav .ant-tabs-tab:active:nth-child(odd),
  &.alternateColors .ant-tabs-nav .ant-tabs-tab:focus:nth-child(odd),
  &.alternateColors .ant-tabs-nav .ant-tabs-tab:hover:nth-child(odd) {
    color: ${props => props.theme[props.theme.mode].common.white};
    background-color: ${props => props.theme[props.theme.mode].primary.main};
  }

  & .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none !important;
  }
  @media screen and (max-width: 991px) {
    .ant-tabs-nav-container {
      font-size: calc(
        ${props => props.theme[props.theme.mode].textSizes.relative.btn} - 4px
      );
    }
  }
  @media screen and (max-width: 767px) {
    .ant-tabs-nav-container {
      font-size: ${props => props.theme[props.theme.mode].textSizes.actual.btn};
    }
  }
`;

const { TabPane } = Tabs;
const TabsComponent = props => {
  const [current, setCurrent] = useState(
    props.tabItem.map(v => v.key).includes(props.tabItem.tab)
      ? props.tabItem.tab
      : props.tabItem[0].key
  );

  const tabItems = items => {
    return items.map(val => (
      <TabPane key={val.key} tab={val.tab} {...val.attr}>
        {val.element}
      </TabPane>
    ));
  };

  const handleTabChange = e => {
    console.log("Tab Change", e, current);
    setCurrent(e);
  };

  return (
    <StyledTabs
      //defaultActiveKey={current}
      onChange={handleTabChange}
      {...props.tabWrap}
      keyVal={e => handleTabChange(e)}
    >
      {tabItems(props.tabItem)}
    </StyledTabs>
  );
};

export default withRouter(connect(null)(TabsComponent));
