import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useTransferForm from "../../../appcomponents/EscrowTrakker/forms/useTransferForm";
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import TextArea from "../../../components/input/TextArea";
import Button from "../../../components/button/index";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
// import { loginSchema } from "../../../configurations/Schemas/login";
import { openModal, closeAllModal } from "../../../actions/Modal";
import { recordTransferRequest } from "../../../actions/transaction/TransferTransaction";
import Job from "./Job";

import AddAccountName from "./AddAccountName";
const DepositTranscation = props => {
  let { bank, isRedirect } = props;
  const [formId, setFormId] = useState(0);
  const mainForm = useTransferForm({
    initialValues: {
      bankName: bank ? bank.bankId + "" : "",
      fromLawyerName: "",
      fromCustomerName: "",
      fromAccountName: "",
      fromJobName: "",
      toLawyerName: "",
      toCustomerName: "",
      toAccountName: "",
      toJobName: "",
      amount: "",
      dateOfTransaction: moment(),
      additionalNote: "",
      fromAccountType: "",
      toAccountType: "",
      type: "transfer"
    },
    //validationSchema: deposit,
    onSubmit: (values, { ...rest }) => props.recordTransferRequest(values, () => {
      rest.resetForm({ 
        values: {
          bankName: "",
          fromLawyerName: values.fromLawyerName,
          fromCustomerName: "",
          fromAccountName: "",
          fromJobName: "",
          toLawyerName: values.toLawyerName,
          toCustomerName: "",
          toAccountName: "",
          toJobName: "",
          amount: "",
          dateOfTransaction: moment(),
          additionalNote: "",
          fromAccountType: "",
          toAccountType: "",
          type: "transfer"
        }
       });
      setFormId(formId + 1);
      (isRedirect ? 
        props.history.push({
          pathname: `/reconcile`,
          //pathname: `/send-transactionimage`,
          //state: props.location.state,
          state: {
            referrer: props.location.pathname,
            // referrerState: {
            //   initialValues: values
            // },
          }
          //   image: localStorage.getItem("imageURL")
          //   //accname: localStorage.getItem("accname")
          // }
          //image: localStorage.getItem("imageFile")
          //format: "pdf"
          }) : props.history.push({
            pathname: `/bank-account-details/${mainForm.values.bankName}`,
            //pathname: `/send-transactionimage`,
            //state: props.location.state,
            state: {
              referrer: props.location.pathname,
              // referrerState: {
              //   initialValues: values
              // },
            }
            //   image: localStorage.getItem("imageURL")
            //   //accname: localStorage.getItem("accname")
            // }
            //image: localStorage.getItem("imageFile")
            //format: "pdf"
            }) )
    })
  });

  useEffect(()=>{
    let { bank } = props;
    if(bank){
      mainForm.customHandleChange(bank.contactId, "defaultBankContact");
      mainForm.customHandleChange(bank.jobId, "defaultBankJob");
    }
  },[])

  console.log("FORM", mainForm);
  return (
    <React.Fragment>
      <Row type="flex" gutter={[40, 30]}>
        <Col span={24}>
          <Row type="flex" align="middle" gutter={[40, 30]}>
            <Col xs={24} sm={12}>
              <BankFieldName
                title="Bank Account Name"
                placeholder="Select Bank Account"
                addBtnText="Add Bank Account"
                initialValue={bank ? bank.bankName : null}
                // value={mainForm.values.bankName}
                name="bankName"
                link="/add-bank"
                onChange={(e, name, lawfirmName, defaultContact, defaultJob) => {
                  mainForm.customHandleChange(e, "bankName");
                  // mainForm.customHandleChange(lawfirmName, "bankLawfirmName");
                  mainForm.customHandleChange(defaultContact, "defaultBankContact");
                  mainForm.customHandleChange(defaultJob, "defaultBankJob");
                }}
                onBlur={e => mainForm.customHandleBlur(e, "bankName")}
              />

              <span className="dangerColor caption textCenter">
                {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingBottom: 0 }}>
          <h3>Payor</h3>
        </Col>
        <Col xs={24} sm={12}>
          <AccountNameField
          key={formId}
            title="Account Name"
            withoutAddButton
            addBtnText="Add Account Name"
            accountType="expense"
            disabled={!mainForm.values.bankName}
            bankAccount={mainForm.values.bankName}
            placeholder="Select Account Name"
            onMouseDown={e => e.preventDefault()}
            addBtnClick={() =>
              props.openModal(
                null,
                <AddAccountName accType="income" />,
                null,
                null,
                { footer: null }
              )
            }
            name="fromAccountName"
            filterIds={mainForm.values.is_split ? mainForm.values.splits.map(val => val.account_head_id) : [mainForm.values.toAccountName]}
            withType
            onChange={(e, type) => mainForm.handleSenderAccountChange(e, "fromAccountName", type)}
            onBlur={e => mainForm.customHandleBlur(e, "fromAccountName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromAccountName"] &&
              mainForm.errors["fromAccountName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
          key={formId}
            title="Customer Name"
            addBtnText="Add Customer"
            link="/add-contact"
            contactType="customer"
            placeholder="Select Customer"
            name="fromCustomerName"
            withoutAddButton
            onChange={e => mainForm.customHandleChange(e, "fromCustomerName")}
            onBlur={e => mainForm.customHandleBlur(e, "fromCustomerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromCustomerName"] &&
              mainForm.errors["fromCustomerName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <JobNameField
          key={formId}
            title="Job Name"
            addBtnText="Add Job Name"
            placeholder="Select Job"
            withoutAddButton
            addBtnClick={() =>
              props.openModal(null, <Job />, null, null, {
                footer: null
              })
            }
            name="fromJobName"
            onChange={e => mainForm.customHandleChange(e, "fromJobName")}
            onBlur={e => mainForm.customHandleBlur(e, "fromJobName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromJobName"] && mainForm.errors["fromJobName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <Field
          key={formId}
            name="amount"
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            value={mainForm.values.amount}
            onChange={mainForm.handleTotalChange}
            onBlur={mainForm.handleBlur}
          />
          <span className="dangerColor caption textCenter">
              {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            name="fromLawyerName"
            link="/add-contact"
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={(e, name) => {
              mainForm.customHandleChange(e, "fromLawyerName");
              mainForm.customHandleChange(e, "toLawyerName");
              mainForm.customHandleChange(name, "lawyer");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "fromLawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromLawyerName"] &&
              mainForm.errors["fromLawyerName"]}
          </span>
        </Col>
        {/* To */}
        <Col span={24} style={{ paddingBottom: 0 }}>
          <h3>Payee</h3>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
          key={formId}
            title="Customer Name"
            addBtnText="Add Customer"
            link="/add-contact"
            contactType="customer"
            placeholder="Select Customer"
            name="toCustomerName"
            disabled={true}
            onChange={e => mainForm.customHandleChange(e, "toCustomerName")}
            onBlur={e => mainForm.customHandleBlur(e, "toCustomerName")}
            getFromBankLawfirm={mainForm.values.defaultBankContact}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toCustomerName"] &&
              mainForm.errors["toCustomerName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
          disabled={true}
          lawyerValue={mainForm.values.lawyer}
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            name="toLawyerName"
            link="/add-contact"
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={e => mainForm.customHandleChange(e, "toLawyerName")}
            onBlur={e => mainForm.customHandleBlur(e, "toLawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toLawyerName"] &&
              mainForm.errors["toLawyerName"]}
          </span>
        </Col>
        {/* <Col span={24}>
          <Split
            splits={mainForm.values.splits}
            amount={mainForm.values.amount}
            remainingAmount={
              (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
            }
            errors={mainForm.errors.splits}
            touched={mainForm.touched.splits}
            onSplitChange={mainForm.handleSplitChange}
            onSplitBlur={mainForm.handleSplitBlur}
            onAddSplit={mainForm.addSplit}
            onClearSplits={mainForm.clearSplits}
            filterIds={[mainForm.values.fromAccountName]}
            accountType={mainForm.values.toAccountType}
            disabled={!mainForm.values.fromAccountName}
          />
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
          </div>
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["remainingAmount"]}
          </div>
        </Col> */}

        {!(mainForm.values.splits && mainForm.values.splits.length) ? (
          <React.Fragment>
            <Col xs={24} sm={12}>
              <AccountNameField
              key={formId}
                id="senderAccountField"
                title="Account Name"
                addBtnText="Add Account Name"
                //disabled={!mainForm.values.fromAccountName}
                disabled={true}
                //bankAccount={mainForm.values.bankName}
                //accountType={mainForm.values.toAccountType}
                placeholder="Select Account Name"
                defaultReserveLawyer
                withType
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType={mainForm.values.toAccountType} />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                filterIds={[mainForm.values.fromAccountName]}
                name="toAccountName"
                onChange={(e, type) => {
                  mainForm.customHandleChange(e, "toAccountName");
                  mainForm.customHandleChange(type, "toAccountType");
                }}
                onBlur={e => mainForm.customHandleBlur(e, "toAccountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["toAccountName"] &&
                  mainForm.errors["toAccountName"]}
              </span>
            </Col>

            <Col xs={24} sm={12}>
            <JobNameField
            key={formId}
            title="Job Name"
            addBtnText="Add Job Name"
            placeholder="Select Job"
            addBtnClick={() =>
              props.openModal(null, <Job />, null, null, {
                footer: null
              })
            }
            name="toJobName"
            getFromBankLawfirm={mainForm.values.defaultBankJob}
            onChange={e => mainForm.customHandleChange(e, "toJobName")}
            onBlur={e => mainForm.customHandleBlur(e, "toJobName")}
            disabled={true}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toJobName"] && mainForm.errors["toJobName"]}
          </span>
            </Col>
          </React.Fragment>
        ) : null}

        {/* <Col xs={24} sm={12}>
          <OptionsField
            title="Payee"
            addBtnText="Add Payee"
            contactType=""
            placeholder="Select Payee"
          />
        </Col> */}

        <Col xs={24} sm={12}>
        <Field
        key={formId}
            title="Date Of Transaction"
            customInput={DatePicker}
            value={mainForm.values.dateOfTransaction}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="dateOfTransaction"
            onChange={e => mainForm.customHandleChange(e, "dateOfTransaction")}
            onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["dateOfTransaction"] &&
              mainForm.errors["dateOfTransaction"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Field title="Check Number" placeholder="Check Number" />
        </Col> */}

        {/* <Col xs={24} sm={12}>
          <Field title="Cleared" span noHeadingMargin customInput={Switch} />
        </Col> */}

        <Col xs={24} sm={12}>
        <Field
        key={formId}
            title="Additional Notes"
            //name="additionalNotex"
            placeholder="Note"
            style={{ height: "8.8rem" }}
            customInput={TextArea}
            name="additionalNote"
            onChange={e => mainForm.customHandleChange(e, "additionalNote")}
            onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["additionalNote"] &&
              mainForm.errors["additionalNote"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Image
            title="Image"
            name="avatar"
            btnText="Upload Image"
            listType="picture-card"
            // style={{ height: "9rem" }}
            className="avatar-uploader"
          />
        </Col> */}

        <Col span={24}>
          <Row type="flex" justify="center" align="middle">
            <Col xl={8} lg={10} md={14} sm={18} xs={20}>
              <Button loading={props.loading} onClick={mainForm.handleSubmit} block className="">
                Record
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};
const mapStateToProps = state => ({loading: state.transaction.recordTransferLoading, settings: state.settings.settings});

export default withRouter(
  connect(mapStateToProps, { recordTransferRequest, openModal, closeAllModal })(DepositTranscation)
);
