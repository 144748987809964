import React from 'react';
import { Radio } from 'antd';
import styled from "styled-components";

const StyledRadioButton = styled(Radio.Button)`

`;


const RadioButtonWrapper = (props)=>{
    return (
    <StyledRadioButton {...props}/>
    );
}

export default RadioButtonWrapper;