import React, { useState } from "react";
import MenuWrapper from "../menu";
import { Menu } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const NavigationBar = (props) => {
  const [current, setCurrent] = useState(
    props.menuItem.map(v => v.key).includes(props.location.pathname) ?
    props.location.pathname : props.menuItem[0].key);

  const menuItem = (items) => {
    return (
      items.map((val) =>
        <Menu.Item key={val.key} {...val.attr}>
          {val.element}
        </Menu.Item>)
    )
  }

  const handleMenuClick = (e) => {
    setCurrent(e.key);
  }
  
  return (
    <MenuWrapper selectedKeys={[current]} onClick={handleMenuClick} {...props.menuWrap}>
      {menuItem(props.menuItem)}
    </MenuWrapper>
  )
}

export default withRouter(connect(null)(NavigationBar));