import { css } from "styled-components"

const style = css`
//Should make global variables in theme for row height, box-shadows, borders and others, also have to take into account sorting through simple props
& {
    width: 100%;
    overflow: auto;
    border: none !important;
    border-radius: 15px;
    //box-shadow: 0 2px 32px -10px rgba(0,0,0,0.15) !important;
    //box-shadow: 0px 6px 16px -6px rgba(0,0,0,0.15) !important;
    box-shadow: 0px 5px 20px #1E1E2214;
}

.rt-table {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    //overflow: visible;
}

//min-width will depend on number of columns and their widths
.rt-thead {
    min-width: ${props => props.content.reduce((total, current) => 
    current.large ? total + 150 : total + 100, 0)}px !important;
    position: relative;
    /* z-index: 100; */
    box-shadow: none !important;
}

//header row
.rt-thead .rt-tr {
  /* box-shadow: 0px 5px 20px #1E1E2214 !important; */
  border-radius: 10px;
}

.rt-th {
    word-break: initial !important;
    * {
      margin: 0;
    }
}

//Row height must be read from theme, font size in the whole table too
.rt-tr {
    background-color: ${props => props.theme[props.theme.mode].background.primary};
    min-height: 50px;
    //height: 3.183rem;
}

/* .rt-tr.cleared {
  background-color: ${props => props.theme[props.theme.mode].primary.light};
} */

.rt-td {
  //text-transform: uppercase;
}

.rt-th, .rt-td {
    display: flex;
    align-items: center;
    white-space: normal !important;
    word-break: break-word;
    padding: 10px 15px !important;
    //padding: .6366rem .95486rem !important;
    border: none !important;
}

//Sorting specific styling
.rt-th .descending, .rt-th .ascending {
    line-height: 0 !important;
}

.rt-th.-sort-desc, .rt-th.-sort-asc {
    box-shadow: none !important;
}

.rt-th.-sort-desc .descending polyline, .rt-th.-sort-asc .ascending polyline {
    color: ${props => props.theme[props.theme.mode].primary.main};
}




//The following should be app-specific as well

// .rt-th:first-child, .rt-td:first-child {
//     flex: 30 0 auto !important;
//     justify-content: center;
// }

${props => props.content.map((val, ind) => val.centered ? css`
.rt-th:nth-child(${ind + 1}), .rt-td:nth-child(${ind + 1}) {
    justify-content: center;
}
` : null)}

${props => props.content.map((val, ind) => val.small ? css`
.rt-th:nth-child(${ind + 1}), .rt-td:nth-child(${ind + 1}) {
    flex: 30 0 auto !important;
}
` : null)}

${props => props.content.map((val, ind) => val.large ? css`
.rt-th:nth-child(${ind + 1}), .rt-td:nth-child(${ind + 1}) {
    min-width: 150px;
}
` : null)}

// .rt-th:last-child, .rt-td:last-child {
//     min-width: 150px !important;
// }

.rt-tbody {
    //min-width: ${props => props.content.length * 100}px !important;
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.captions};
    font-weight:600;
    color: ${props => props.theme[props.theme.mode].textColor.hint};
    min-width: ${props => props.content.reduce((total, current) => 
    current.large ? total + 150 : total + 100, 0)}px !important;
    background-color: rgb(249, 249, 249, 1);
}

.rt-tr-group {
    border-bottom: 1px solid ${props => props.theme[props.theme.mode].border} !important;
    //margin-bottom: 5px;
}

.rt-tr-group:last-child {
    border: none !important;
    //margin-bottom: 0px;
}


// .-loading.-active {
    //background: rgba(240,255,255,0.1);
// }



.pagination-bottom {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.-pagination {
    height: 65px !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: none !important;
    background-color: ${props => props.theme[props.theme.mode].background.primary};
}

.-previous, .-center, .-next {
    flex: unset !important;
}

.-previous button, .-next button {
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 40px !important;
    width: 40px !important;
    line-height: 0 !important;
    text-align: center !important;
    color: ${props => props.theme["dark"].textColor.primary} !important;
    padding: 0 !important;
    border-radius: 40px !important;
    background-color: ${props => props.theme[props.theme.mode].primary.main} !important;
}

.-center {
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
}

.-center input {
    height: 36px !important;
    width: 36px !important;
    padding: 0 !important;
    background: ${props => props.theme[props.theme.mode].background.primary} !important;
    color: ${props => props.theme[props.theme.mode].textColor.primary} !important;
    margin: 0 !important;
    border: 2px solid ${props => props.theme[props.theme.mode].primary.main} !important;
    border-radius: 40px !important;
}

.-center input[type='number'] {
    -moz-appearance:textfield;
}

.-center input::-webkit-outer-spin-button, .-center input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
`;

export default style;