import React from "react";
import { Row, Col } from "antd";
import Container from "../../../components/charts/ResponsiveContainer";
import Card from "../../../components/card";

const PieContainer = props => {
  console.log("Pei Container: ", props.children);
  return (
    <Card>
      <Row type="flex" gutter={[30, 30]} justify="center" align="middle">
        <h4 className={`small ${props.negative ? "secondaryAppColor" : ""}`}>{props.totalAmount}</h4>
        <Col span={24}>
          <h4 className="textCenter">{props.heading}</h4>
        </Col>
        <Col span={24}>
          {/* <Container {...props.container}> */}
            {props.children}
          {/* </Container> */}
        </Col>
      </Row>
    </Card>
  );
};

export default PieContainer;
