/// Constants
// export const GOOGLE_API_KEY = "AIzaSyAFnHI7y7n5eV9J-gHk-Ald5W9bnzKqCgI";
// export const STRIPE_PUBLISHABLE_API_KEY =
//   "pk_test_jUPgEi4tIpr7lEDkWC1xegOo00Trmps34D";
//export const STRIPE_SECRET_API_KEY = "pk_test_rBeOTa2VkXZ16aKpzBQiA8qT00SmODzsPk";
export const DEFAULT_TABLE_PAGE_SIZE = 10;
export const protocol_head = "https://";
export const current_sprint = 2;

// API base URL
export const API_BASE_URL =
  //"http://clients2.5stardesigners.net/escrow_uat/backend/web/v1"; *DISREGARD*
  //"http://clients3.5stardesigners.net/developer1/escrow/backend/web/v1" FOR UAT *DISREGARD*
  //"http://clients3.5stardesigners.net/escrowt/escrow/backend/web/v1"; // FOR DEVELOP *DISREGARD*
  // "https://escrowt.5stardesigners.net/escrow/backend/web/v1/"; // FOR DEVELOP
  //"https://escrowt.5stardesigners.net/uat/backend/web/v1/"; //FOR UAT
  "https://api2.escrowtrakker.com/backend/web/v1"; //For Live
  //"https://escrowt.5stardesigners.net/escrow/backend/web/v1";//FOR QA
export const USERS_URL = "/users";
//export const AUTH_URL = "/card";
// export const USER_URL = "/user";

//API endpoints

//App
// export const FAQS_ENDPOINT = "/get-faq";
export const CONTACT_ENDPOINT = "/contact";

//Auth
export const REGISTER_ENDPOINT = "/register";
export const SIGNUP_VALIDATION_REQUEST_ENDPOINT = "/signup-validation";
export const LOGIN_ENDPOINT = "/login";
export const VERIFY_EMAIL_ENDPOINT = "/resend-verify-email";
export const FORGOT_PASSWORD_ENDPOINT = "/request-password-reset";
export const VERIFY_CODE_ENDPOINT = "/verify-code";
export const RESET_PASSWORD_ENDPOINT = "/reset-password";

//Edit Profile
export const UPDATE_PROFILE_ENDPOINT = "/profile";
export const CHANGE_PASSWORD_ENDPOINT = "/change-password";
//change password api is in the app saga
//Edit Profile

//BankAccount
export const BANK_ACCOUNT_ENDPOINT = "/bank-accounts";
//Contacts
export const CONTACT_USER_ENDPOINT = "/user-contacts";
// Transactions
export const TRANSACTION_ENDPOINT = "/transactions";
//Unreconciled Transactions
export const UNRECONCILED_TRANSACTION_ENDPOINT = "/unreconcilled-transactions";
// Reports
export const REPORTS_ENDPOINT = "/reports";
//Accounts
export const ACCOUNTS_ENDPOINT = "/get-accounts";
//Jobs
export const GET_JOBS_ENDPOINT = "/get-user-jobs";
//Contacts
export const CREATE_CONTACT_ENDPOINT = "/user-contacts/create";
// Create Account Name
export const ACCOUNT_NAME_ENDPOINT = "/create-account";
// Edit Account Name
export const EDIT_ACCOUNT_NAME_ENDPOINT = "/update-account";
// Statements
export const STATEMENT_ENDPOINT = "/bank-statements";
//Jobs
export const ADD_JOB_ENDPOINT = "/create-user-job";
// Create Deposit and withDrawl Tranasaction
export const CREATE_TRANSACTION_ENDPOINT = "/create-transaction";
export const UPDATE_TRANSACTION_ENDPOINT = "/update-transaction";
export const UPDATE_RECURRING_ENDPOINT = "/recurring";
export const UPDATE_TRANSFER_ENDPOINT = "/update-transfer-transaction";
// Serach Transactions
export const SERACH_TRANSACTIONS_ENDPOINT = "/search-transactions";
// Delete Transactions
export const DELETE_TRANSACTIONS_ENDPOINT = "/delete-transaction";

// Cleared Transactions
export const CLEARED_TRANSACTIONS_ENDPOINT = "/reconcile-transaction";

// Bank Account Summary
export const BANK_ACCOUNT__SUMMARY_ENDPOINT = "/balance-querry";

// Get Recurings
export const GET_RECURRING_ENDPOINT = "/recurring-transactions";

// Get Transfer Journal
export const GET_TRANSFER_JOURNAL = "/transfer-journal";

// Create Tranasactions
export const RECORD_TRANSFER_ENDPOINT = "/create-transfer-transaction";
export const RECORD_RECURRING_ENDPOINT = "/create-recurring";
export const DELETE_ACCOUNT_HEAD_ENDPOINT = "/delete-account-head";
export const DELETE_JOB_ENDPOINT = "/delete-user-job";
// Message - This will become the transactions screen
export const MESSAGE_ENDPOINT = "/transaction";
export const BALANCE_ENDPOINT = "/current-balance";
export const REPLY_MESSAGE_ENDPOINT = "/message-reply";
export const DELETE_MESSAGE_ENDPOINT = "/transaction-details";

export const CHARTS_ENDPOINT = "/charts";
export const ACCOUNTS_CHARTS_ENDPOINT = "/accounts-charts";

// Delete Contact
export const DELETE_CONTACT_ENDPOINT = "/delete";
// Edit Contact
export const EDIT_CONTACT_ENDPOINT = "/update";
//CHART DETAILS GET
export const GET_CHARTS = "/get-details";
// -- print checks
export const PRINT_CHECQUE_TRANSACTION_ENDPOINT = "/cheques";
// Reports
export const SEND_REPORT_ENDPOINT = "/send-report";
export const CHART_OF_ACCOUNTS_ENDPOINT = "/chart-of-accounts";
export const BANK_ACCOUNT_LEDGER_ENDPOINT = "/bank-account-ledger";
export const TRIAL_BALANCE_SUMMARY_ENDPOINT = "/trial-balance-summery";
export const PROFIT_AND_LOSS_ENDPOINT = "/profit-and-loss";
export const TRANSFER_JOURNAL_LEDGER_ENDPOINT = "/transfer-journal-ledger";
export const RECONICILIATION_FOR_LAWYERS_ENDPOINT = "/reconciliation-ledger";
export const CHART_REPORT_ENDPOINT = "/print-report";
export const DISCLOSURE_STATEMENT_ENDPOINT = "/disclosure-statement";

// Users Settings  GET
export const USERS_SETTINGS_ENDPOINT = "/user-settings";

// Users Settings Update

export const UPDATE_USER_SETTINGS_ENDPOINT = "/update";
// -- Create Checks
export const CREATE_CHECK_ENDPOINT = "/create-cheque";
// -- subscription
export const BUY_SUBSCRIPTION_ENDPOINT = "/user-subscriptions";
export const SUBSCRIPTION_ENDPOINT = "/user-settings";
export const CANCEL_SUBSCRIPTION_ENDPOINT = "/cancel";

export const BANK_STATEMENT_ENDPOINT = "/bank-statements";
