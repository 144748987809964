import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
//import ChartOfAccountsForm from "../forms/ChartOfAccounts";

import { chartOfAccountsRequest } from "../../../../actions/Reports"


const ChartOfAccounts = props => {
  console.log("REDUCER", props)
  return (
    <ReportTypeCard
      initialValue={props.initialType || "pdf"}
      heading="Chart of Accounts"
      loading={props.reports.chartOfAccountsLoading}
      onSubmit={format => props.chartOfAccountsRequest({ format }, report => {
        props.history.push({ pathname: "/reports/preview-chart-of-accounts", state: { referrer: "/reports", referrerState: { ...props.location.state, chartOfAccountsValues: { format } }, report, format } })
      })}
    />
  )
}

export default withRouter(connect(state => ({ reports: state.Reports }), { chartOfAccountsRequest })(ChartOfAccounts));
