import React from "react";
import propType from "prop-types";
import styled from "styled-components";
import DropDown from "../../../../../components/input/Select";
import Field from "../index";
import { Divider } from "antd";
import PlusButton from "../../IconButton";
import { useHistory } from "react-router-dom";

const SelectWrapper = styled(Field)`
  & {
    width: 100%;
  }
  & .ant-select-selection {
    /* box-shadow: none;
    border: 1px solid ${props => props.theme[props.theme.mode].primary.main}66;
    padding: 0.7vw 0.8vw; */
  }
  & .ant-select-selection .ant-select-arrow {
    right: 0.8rem;
  }
`;
const OptWrapper = styled.div`
  & .selectOpts {
    max-height: 10rem;
    overflow: auto;
  }
  & .selectOpts .ant-select-dropdown-menu {
    max-height: none;
  }
  & .addLink {
    padding: 5px 12px;
  }
`;

const Select = props => {
  console.log("General", props);

  // const history = useHistory();

  return (
    <SelectWrapper
      {...props}
      style={{ width: "100%" }}
      customInput={DropDown}
      showSearch={true}
      dropdownRender={menu => (
        <OptWrapper>
          <div className="selectOpts">{menu}</div>
          {!props.withoutAddButton && 
          <React.Fragment>
          <Divider
            style={{
              margin: "0 0"
            }}
          />
          <div className="addLink">
            <PlusButton
              onMouseDown={props.addBtnClick}
              onClick={
                props.link
                  // ? () =>
                  //     history.push({
                  //       pathname: props.link,
                  //       referrer: "/add-transaction"
                  //     })
                  // : props.onAddClick
              }
            >
              {props.addBtnText}
            </PlusButton>
          </div>
          </React.Fragment>
          }
        </OptWrapper>
      )}
    />
  );
};
Select.propType = {
  onAddClick: propType.func,
  link: propType.string
};
export default //connect({},{})
Select;
