import React from "react";
import { connect } from "react-redux";
import { Row, Col, Empty } from "antd";
import Button from "../../../../components/button/index";
import HeaderWrapper from "../../../../components/header/index";

const EmptyState = props => {

  //console.log("FORM", mainForm);
  return (
    <Row type="flex" justify="center" gutter={[30, 150]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
         rightAlign={[
          <Button>
            {props.edit ? "Save Changes" : "Edit Transaction"}
          </Button>
        ]} 
         externalscreen={1} 
         />
      </Col>
      <Col>
        <Empty description="No Transaction Found"/>
      </Col>
    </Row>
  );
};

export default (EmptyState)
