import React, { useState } from "react";
import HeaderWrapper from "../../../components/header/index";
import { Row, Col } from "antd";
import Button from "../../../components/button";
import { FiPrinter } from "react-icons/fi";
import Select from "../../../components/input/Select";
import Options from "../../../components/input/SelectOptions";
import CheckCard from "../../../appcomponents/EscrowTrakker/checkscard/CheckCard";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { getCreateCheckRequest } from "../../../actions/PrintChecks";
// import DocumentCard from "../../../appcomponents/EscrowTrakker/setting/DownloadSettingFileCard";
import { AccountingFormat } from "../../../configurations/Config";
import info from "../../../components/message/index";

const PrintChecksSummary = (props) => {
  const [chequeCount, setChequeCount] = useState(1);
  const [checques, setChecques] = useState(
    props.location.state ? props.location.state.printData : []
  );
  const [lastCheckNumber] = useState(
    props.location.state ? props.location.state.lastCheckNumber : 0
  );

  function handleDateChange(val, index) {
    let newChecques = [...checques];

    newChecques[index].date_of_transaction = val;
    setChecques(newChecques);
  }

  // console.log("PrintChecksSummary", checques, chequeCount, lastCheckNumber);
  console.log("last check", lastCheckNumber);
  return props.location.state ? (
    <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
          rightAlign={[
            // <Row type="flex" justify="end">
            //   <Col xs={24} sm={24} md={10} lg={10}>
                <Button
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() =>
                    props.getCreateCheckRequest(
                      checques,
                      chequeCount,
                      lastCheckNumber,
                      (error, res) => {
                        if (error) {
                          info("error", `Error Occured`);
                        } else {
                          // console.log(res, "success pdf URl");
                          window.open(res.cheque_url);
                        }
                      }
                    )
                  }
                >
                  <FiPrinter style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Print
                </Button>
            //   </Col>
            // </Row>
            ,
          ]}
          externalscreen={1}
        />
      </Col>
      <Col span={24}>
        <Row type="flex" justify="start" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={8} lg={5}>
            <Row type="flex" gutter={[0, 10]}>
              <Col span={24}>
                <h4>Please select the check format</h4>
              </Col>
              <Col span={24}>
                <Select
                  value={chequeCount}
                  style={{ width: "100%" }}
                  placeholder="One Check"
                  onChange={(e) => setChequeCount(e)}
                >
                  <Options value={1}>One Check</Options>
                  <Options value={3}>Three Check</Options>
                </Select>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={16} lg={10}>
            <Row gutter={[10, 10]}>
              {checques.map((item, indexes) => {
                // console.log(index,"col")
                return (
                  <Col span={24} key={indexes}>
                    <CheckCard
                      smallPadding
                      payor_name={item.payor_name ? item.payor_name : "N/A"}
                      contact_address={
                        item.contact_address ? item.contact_address : "N/A"
                      }
                      amount={
                        item.amount
                          ? AccountingFormat(item.amount, item.currency)
                          : "N/A"
                      }
                      date_of_transaction={item.date_of_transaction}
                      handleDateChange={(e) => handleDateChange(e, indexes)}
                    />{" "}
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  ) : (
    <Redirect to="/" />
  );
};

export default connect(
  (storeState) => ({ transactionsData: storeState.PrintCheck }),
  {
    getCreateCheckRequest,
  }
)(PrintChecksSummary);
