import useFormikWrapper from "../../../../components/formikwrapper/FormikWrapper";
import {
  TrialBalanceSummarySchema
} from "../../../../configurations/Schemas/index";

import moment from "moment";

const useTrialBalanceSummaryForm = props => {

  const formik = useFormikWrapper({
    ...props,
    validationSchema: TrialBalanceSummarySchema
  });

  return formik;
};

export default useTrialBalanceSummaryForm;
