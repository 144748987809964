import React from "react";
import styled from "styled-components";
import Input from "../../../../components/input";
import { Row, Col } from "antd";

const LabelStyle = styled.h4`
  margin-bottom: 0.7rem;
`
const FieldWrapper = styled.div`
  & .inputFieldsWrapper {
    width: 100%;
    margin-bottom: 0;
    //border-radius: 0.25rem;
  }
`
const InputWithTitle = props => {
  console.log("InputWithTitle", props);
  let Field = props.customInput ? props.customInput : Input;
  return (
    <React.Fragment>
      {/* customInput={Select} */}
      <Row type="flex" justify="space-between" align="middle">
        {props.title ? (<Col span={props.span} >
          <LabelStyle className="fontBold" style={props.noHeadingMargin ? { marginBottom: 0 } : undefined}>{props.title}</LabelStyle>
        </Col>) : null}
        <Col span={props.span}>
          <FieldWrapper>
            <Field className="inputFieldsWrapper" {...props} />
          </FieldWrapper>
        </Col>
      </Row>
    </React.Fragment>
  );
};

InputWithTitle.defaultProps = {
  span: 24
};

export default InputWithTitle;
