import React from "react";
import CardWrapper from "../../../components/card";
import Icon from "../../../components/Icon";
import styled from "styled-components";

const TotalCards = styled.div`
  & {
    height: 100%;
  }
  & .ant-card-body {
    padding: 2rem 4rem;
  }
`;
const TransferTotalCard = props => {
  return (
    <React.Fragment>
      <TotalCards>
        <CardWrapper className="textCenter">
          <React.Fragment>
            <h4>{props.heading}</h4>
            {props.label ?(<h4 style={{ fontWeight: 600 }}>{props.label}</h4>):<div style={{marginBottom:"2rem"}}> </div> }
            {props.loading ? <Icon style={{ fontSize: "2rem" }} type="loading" /> : <h2 style={{ fontWeight: 400 }}>{props.amount}</h2>}
          </React.Fragment>
        </CardWrapper>
      </TotalCards>
    </React.Fragment>
  );
};
export default TransferTotalCard;
