import React from "react";
import { withRouter } from "react-router";
import { Row, Col, Layout } from "antd";
import HeaderNav from "../../../components/header/NavigationBar";
import { fullHeaderLogo } from "../../../configurations/Config";
import { HashLink } from "react-router-hash-link";
import Button from "../../../components/button";
import styled from "styled-components";
import useScreenWidth from "../../../components/hooks/useScreenWidth";
const { Header } = Layout;
const HeaderWrap = styled(Header)`
  background: ${props => props.theme[props.theme.mode].common.white};
  padding: 0;
  height: auto;
  line-height: auto;

  & .ant-menu-horizontal > .ant-menu-submenu-selected,
  & .ant-menu-item:hover,
  & .ant-menu-item-active,
  & .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  & .ant-menu-submenu-active,
  & .ant-menu-item > a:hover,
  & .ant-menu-submenu-title:hover {
    color: ${props => props.theme[props.theme.mode].primary.main};
    border-bottom-color: ${props => props.theme[props.theme.mode].primary.main};
  }
`;
const HeaderBar = props => {
  const size = useScreenWidth();
  return (
    <HeaderWrap>
      <Row
        gutter={[40, 0]}
        type="flex"
        align="middle"
        justify="space-between"
        style={{ marginBottom: "32px" }}
      >
        <Col xs={12} sm={12} md={8} lg={6} align="left">
          {size >= 991 ? fullHeaderLogo() : fullHeaderLogo()}
        </Col>
        <Col xs={12} sm={12} md={8} lg={12}>
          <HeaderNav
            menuWrap={{
              mode: "horizontal",
              align: "center",
              background: "transparent",
              className: "mainHeader"
            }}
            menuItem={[
              {
                key: "features",
                attr: { className: "caption landingNav" },
                element: (
                  <HashLink smooth to="#FEATURES">
                    FEATURES
                  </HashLink>
                )
              },
              {
                key: "pricing",
                attr: { className: "caption landingNav" },
                element: (
                  <HashLink smooth to="#PRICING">
                    PRICING
                  </HashLink>
                )
              },
              {
                key: "faq",
                attr: { className: "caption landingNav" },
                element: (
                  <HashLink smooth to="#FAQ">
                    FAQ
                  </HashLink>
                )
              },
              {
                key: "support",
                attr: { className: "caption landingNav" },
                element: (
                  <HashLink smooth to="#SUPPORT">
                    SUPPORT
                  </HashLink>
                )
              }
            ]}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={6}>
          <Row
            gutter={[10, 0]}
            type="flex"
            style={
              size >= 767
                ? { justifyContent: "flex-end" }
                : { justifyContent: "center" }
            }
          >
            <Col span={12} xs={10} sm={10} md={8} lg={8} xl={8}>
              <Button onClick={() => props.history.push("/login")} block>
                Login
              </Button>
            </Col>
            <Col span={12} xs={10} sm={10} md={8} lg={8} xl={8}>
              <Button
                onClick={() => props.history.push("/signup")}
                outlined="true"
                block
              >
                Sign Up
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </HeaderWrap>
  );
};

export default withRouter(HeaderBar);
