import React from "react";
import { Layout, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Card from "../../../components/card/index";
import ContentWrapper from "../../../components/maincontent";
import { StepsWrapper, StepWrapper } from "../../../components/steps";

const AuthCard = styled(Card)`
  box-shadow: 0px 30px 60px #1E1E2208;
  border-radius: 10px;
  opacity: 1;
  & .ant-card-body {
    padding: 6rem 3rem;
  }
`;
const ContentWrap = styled(ContentWrapper)`
  margin: 0;
  padding: 0;
  min-height: auto;
  box-shadow: 0px 30px 60px #1E1E2208;
  border-radius: 10px;
  opacity: 1;
`;
const LayoutFlow = styled(Layout)`
  min-height: 100vh;
  //padding: 40px 25px;
  //justify-content: space-between;
  background-color: #ffffff;
  text-align: center;
  .input {
    background: blue;
  }
  .backBtn {
    cursor: pointer;
    font-size: 1.5em;
  }
  .card-link-out {
    position: absolute;
    bottom: 3.5rem;
    margin: auto;
    text-decoration: underline;

    @media screen and (max-width: 767px) {
      position: static;
      bottom: 0;
    }
  }
`;
const OAuthCard = props => {
  return (
    <ContentWrap>
      <LayoutFlow>
        <Row
          style={{
            padding: "2vh 2vw",
            flexWrap: "nowrap",
            flexDirection: "column",
            minHeight: "100vh"
          }}
          type="flex"
          justify="space-between"
          align="middle"
        >
          <Col
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              marginTop: "6vh",
            }}
            span={24}
          >
            <Row
              style={{ flexDirection: "column", flexGrow: 1 }}
              type="flex"
              justify="space-between"
            >
              <Col span={24}>
                <Row type="flex" justify="center">
                  <Col span={24}>
                    <h2>{props.heading}</h2>
                  </Col>
                  <Col lg={12} md={16} sm={20} xs={24}>
                    <AuthCard {...props} />
                  </Col>
                </Row>
              </Col>

              <Col>
                {props.steps ? (
                  <StepsWrapper progressDot current={props.steps.current - 1}>
                    {[...Array(props.steps.count)].map(() => (
                      <StepWrapper />
                    ))}
                  </StepsWrapper>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col span={24} className="caption">{props.footer}</Col>
        </Row>
      </LayoutFlow>
    </ContentWrap>
  );
};

export default withRouter(OAuthCard);
