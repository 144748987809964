import React from "react";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
import BankAccountLedgerForm from "../forms/BankAccountLedger";

import { openModal, closeAllModal } from "../../../../actions/Modal"


const BankAccountLedger = props => (
  <ReportTypeCard 
  initialValue={props.initialType || "pdf"}
  heading="Bank Account Ledger" 
  onSubmit={format => {
    props.openModal(
      null,
      <BankAccountLedgerForm initialValues={props.initialFormValues} format={format} />,
      null,
      null,
      {
        footer: null
      }
    );
  }} 
  />
)

export default connect(null, { openModal, closeAllModal })(BankAccountLedger);
