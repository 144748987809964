import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { GOOGLE_API_KEY } from "../../../configurations/Constants";
import Input from "../../../components/input/index";
// import DatePicker from "../../../components/datepickers/DatePicker";
import RootForm from "../../../components/form/index";
import { Link } from "react-router-dom";
import AuthContainer from "../../../appcomponents/shares/auth/AuthContainer";
import { signUpValidations } from "../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { registerRequest, signupValidationRquest } from "../../../actions/Auth";
import SwitchWrapper from "../../../components/switch";
import { HeaderLogo } from "../../../configurations/Config";

const TermsAndConditionsSwitch = props => {
  return (
    <Row
      // className="inputField mr-t caption"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <Col>
        <a
          href="https://app.termly.io/document/terms-and-conditions/d24fde6f-9139-47e2-9de7-b587f9e240cb"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms and Conditions
        </a>
      </Col>

      <Col>
        <SwitchWrapper
          defaultChecked={false}
          onChange={props.onChange}
          size="small"
        />
      </Col>
    </Row>
  );
};

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: "",
      email: "",
      password: "",
      confirm_password: "",
      termsAndConditions: false,
      errors: {}
    };
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <AuthContainer>
        <Row type="flex" justify="center" align="middle">
          <Col
            span={10}
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
          >
            {HeaderLogo()}
          </Col>
          <Col span={24} style={{ padding: "3rem 0 0 0" }}>
            <h4>Hi, Let's get you signed up</h4>
          </Col>
          <Col
            xs={24}
            sm={20}
            md={22}
            lg={12}
            xl={14}
            style={{ padding: "2rem 0 0 0" }}
          >
            <RootForm
              schemaName={signUpValidations}
              initialValues={this.state}
              apiCall={registerRequest}
              extras={this.state.ltln}
              cb={this.props.cb}
              //enableReinitialize
              // changeEmail={this.changeEmail}
              //autoWidthSubmit
              loading={this.props.Auth.loading}
              customErrors={this.state.errors}
              //cb={() => this.props.history.push("/login")}
              controls={[
                {
                  span: 24,
                  name: "full_name",
                  value: this.state.full_name,
                  handleChanged: this.handleChanged,
                  placeholder: "Full Name",
                  Type: Input
                },
                {
                  span: 24,
                  name: "email",
                  value: this.state.email,
                  handleChanged: this.handleChanged,
                  placeholder: "Email",
                  onBlur: values => {
                    this.props.dispatch(signupValidationRquest(values));
                  },
                  Type: Input
                },
                {
                  span: 24,
                  name: "password",
                  value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "Password",
                  Type: Input
                },
                {
                  span: 24,
                  name: "confirm_password",
                  value: this.state.confirm_password,
                  handleChanged: this.handleChanged,
                  placeholder: "Confirm Password",
                  Type: Input
                },
                {
                  span: 24,
                  name: "termsAndConditions",
                  value: this.state.termsAndConditions,
                  handleChanged: this.handleChanged,
                  Type: TermsAndConditionsSwitch
                }
              ]}
              submitButton={{
                span: 16,
                buttonText: "Sign Up",
                className: "mr-t-md mr-b-sm"
              }}
            />
          </Col>
          <Link to="/login" className="card-link-out">
            Already have an account?
          </Link>
        </Row>
      </AuthContainer>
    );
  }
}

export default withRouter(
  connect(storeState => ({ Auth: storeState.Auth }))(SignUpForm)
);
