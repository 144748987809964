import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "../../components/button";
import Field from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import { openModal, closeModal } from "../../actions/Modal";
import { getUserSettingRequest } from "../../actions/Settings";
import SecondUseStatementForm from "./SecondUseStatementForm";
import { updateStatementsRequest } from '../../actions/Statements';
const StatementModal = (props) => {
  const [formId, setFormId] = useState(0);
  const mainForm = SecondUseStatementForm({
    initialValues: {
      fileName: props.fileName ? props.fileName : ""
    },
    //enableReinitialize: true,
    //validationSchema: deposit,
    //apiCall: { addTransactionRequest },

    onSubmit: (values, { ...rest }) => {
      props.updateStatementsRequest(values, props.fileID, () => {
        //true
        console.log("IN SUBMIT", values)
        rest.resetForm({ 
          values: {
            fileName: values.fileName ? values.fileName : ""
          }
         });
        setFormId(formId + 1);
        props.closeModal();
        // props.history.push({
        //   pathname: `/bank-account-details/${mainForm.values.bankName}`,
        //   //pathname: `/send-transactionimage`,
        //   //state: props.location.state,
        //   state: {
        //     referrer: props.location.pathname,
        //     // referrerState: {
        //     //   initialValues: values
        //     // },
        //   }
        //   //   image: localStorage.getItem("imageURL")
        //   //   //accname: localStorage.getItem("accname")
        //   // }
        //   //image: localStorage.getItem("imageFile")
        //   //format: "pdf"
        //   })
      }
      );
    }
  });
  useEffect(() => {
    props.getUserSettingRequest();
  }, []);
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={18} className="mr-t">
        <h4>File Name</h4>
        <Field
          key={formId}
            //title="File Name"
            defaultValue={mainForm.values.fileName ? mainForm.values.fileName : props.fileName ? props.fileName : "File Name"}
            name="fileName"
            onChange={e => mainForm.customHandleChange(e, "checkNumber")}
            onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}/>
        {/* {/* <h3>Interest amount owed to {props.values.tenant_name} <br /> for holding {props.settings.currency  ? props.settings.currency : "$"}{props.values.amount} for {getYearsDifference()} { getYearsDifference()==="1.00" ? "Year" : "Years" } = {props.settings.currency ? props.settings.currency : "$"}{getResult()===Infinity ? "0.00" : getResult().toFixed(2)}</h3> */}
      </Col>
      <Col span={8} className="mr-t">
        <Button onClick={mainForm.handleSubmit}>
                Submit
        </Button>
      </Col>
      <Col span={8} className="mr-t">
        <Button outlined onClick={() => props.closeModal()}>Cancel</Button>
      </Col>
    </Row>
  )
}
export default withRouter(connect(state => ({ reports: state.Reports, settings: state.settings.settings }), { closeModal, openModal, updateStatementsRequest, getUserSettingRequest })(StatementModal));

