import React from "react";
import { Route, Switch, Redirect, withRouter  } from "react-router-dom";
import { connect } from "react-redux";

// Register and Login Screen
import TermsAndConditions from "./views/static/TermsAndConditions"
import PrivacyPolicy from "./views/static/PrivacyPolicy"
import LandingPage from "./views/landing/Land"
import SignUp from './views/auth/signup'
import Login from './views/auth/LoginCard'
import ForgotPassword from './views/auth/ForgotPassword'
import ResetScreen from "./views/auth/ResetPassword";
import ChangePassword from './views/auth/ChangePassword'
import Shell from "./views/shell/Shell";
import VerificationCode from "./views/auth/VerificationCode";
// import NotFound from "../NotFound";

class Routes extends React.Component {

 
  render() {
    //console.log("Routes Props", this.props, localStorage.getItem("escrowauth"));
    return (
      <div className="App">
        <Switch>
          {
            !this.props.isAuthenticated ? (
              <React.Fragment>
                <Switch>
                  {/* <Route exact path="/terms-and-conditions" component={TermsAndConditions} /> */}
                  {/* <Route exact path="/privacy" component={PrivacyPolicy} /> */}
                  {/* <Route exact path="/main" component={LandingPage} /> */}
                  <Route exact path="/signup" component={SignUp} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/forgot-password" component={ForgotPassword} />
                  <Route exact path='/verify-code' component={VerificationCode} />
                  <Route exact path='/reset-password' component={ResetScreen} />
                  <Route path='*' render={() => <Redirect to="/login" />} />
                  {/* <Route path='*' render={() => <Redirect to="/main" />} /> */}
                </Switch>
              </React.Fragment>
            )
            :
            (
              <React.Fragment>
                <Switch>
                  <Route exact path="/change-password" component={ChangePassword} />
                  <Route path="/" component={Shell} />
                </Switch>
                {/* <Redirect to="/" /> */}
              </React.Fragment>
            )
          }
        </Switch>
      </div>
    );
  }
}


export default withRouter(connect(storeState => ({ isAuthenticated: storeState.Auth.isAuthenticated }))(Routes));
