import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
import useTransferForm from "../../../appcomponents/EscrowTrakker/forms/useTransferForm";
import BankFieldName from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import JobNameField from "../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import TextArea from "../../../components/input/TextArea";
import Button from "../../../components/button/index";
import Split from "../../../appcomponents/EscrowTrakker/formcontrols/splits";
// import { loginSchema } from "../../../configurations/Schemas/login";
import { openModal, closeAllModal } from "../../../actions/Modal";
import { updateTransferRequest } from "../../../actions/transaction/TransferTransaction";
import { getTransactionRequest } from "../../../actions/transaction/Transaction";
import HeaderWrapper from "../../../components/header/index";
import Job from "../addtranscation/Job";

import AddAccountName from "../addtranscation/AddAccountName";

import TransactionTypeCard from "../../../appcomponents/EscrowTrakker/edittransaction/TransactionTypeCard";
import ClearedCard from "../../../appcomponents/EscrowTrakker/edittransaction/ClearedCard";

const TransferTransaction = props => {
  let { transaction, edit } = props;

  const mainForm = useTransferForm({
    initialValues: {

      bankName: transaction[0].bank_account_id,
      fromLawyerName: transaction[0].lawyer_id,
      fromCustomerName: transaction[0].customer_id,
      fromAccountName: transaction[0].account_head_id,
      fromJobName: transaction[0].job_id,
      fromAccountType: transaction[0].account_head_type,

      amount: transaction[0].amount,

      toLawyerName: transaction[1].lawyer_id,
      toCustomerName: transaction[1].customer_id,
      ...(transaction[0].is_split === "true" ? {
        splits: transaction.slice(1).map(val => ({
          amount: val.amount,
          account_head_id: val.account_head_id,
          job_id: val.job_id
        }))
      } : 
      {
        toAccountName: transaction[1].account_head_id,
        toJobName: transaction[1].job_id
      }),
      //toAccountType: transaction[1].account_head_type,
      //toAccountType: "income",

      dateOfTransaction: transaction[0].date_of_transaction ? moment(transaction[0].date_of_transaction) : "",
      checkNumber: transaction[0].check_number,
      cleared: transaction[0].is_cleared === "true",
      additionalNote: transaction[0].additional_note,
      type: "transfer"
    },
    //validationSchema: deposit,
    onSubmit: values => props.updateTransferRequest(values, props.match.params.id, () => {
        props.history.push({
            pathname: `/view-transaction/${props.match.params.id}`,
            state: props.location.state
        });
        //props.getTransactionRequest(props.match.params.id);
    })
  });

  console.log("FORM", mainForm);
  return (
    <Row type="flex" gutter={[30, 20]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
         rightAlign={[
          <Button
          loading={props.loading}
          onClick={() => {
            if(props.edit)
              mainForm.handleSubmit();
            else {
              props.history.push({
                pathname: `/edit-transaction/${props.match.params.id}`,
                state: props.location.state
              })
            }
          }}
          >
            {props.edit ? "Save Changes" : "Edit Transaction"}
          </Button>
        ]} 
         externalscreen={1} 
         />
      </Col>
      <Col xs={24} md={8}>
        <Row type="flex" gutter={[0, 20]}>
          <Col span={24}>
            <TransactionTypeCard type="Transfer" />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={16} lg={12}>
        <Row type="flex" gutter={[20, 20]}>
        <Col span={24}>
          <Row type="flex" align="middle" gutter={[40, 30]}>
            <Col xs={24} sm={12}>
              <BankFieldName
                title="Bank Account Name"
                placeholder="Select Bank Account"
                addBtnText="Add Bank Account"
                disabled={!edit}
                initialValue={transaction[0].bank_account_name}
                // value={mainForm.values.bankName}
                name="bankName"
                link="/add-bank"
                onChange={(e, name, lawfirmName, defaultContact, defaultJob) => {
                  mainForm.customHandleChange(e, "bankName");
                  // mainForm.customHandleChange(lawfirmName, "bankLawfirmName");
                  mainForm.customHandleChange(defaultContact, "defaultBankContact");
                  mainForm.customHandleChange(defaultJob, "defaultBankJob");
                }}
                onBlur={e => mainForm.customHandleBlur(e, "bankName")}
              />

              <span className="dangerColor caption textCenter">
                {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
              </span>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ paddingBottom: 0 }}>
          <h3>Payor</h3>
        </Col>
        <Col xs={24} sm={12}>
          <AccountNameField
            title="Account Name"
            withoutAddButton
            addBtnText="Add Account Name"
            disabled={!mainForm.values.bankName || !edit}
            bankAccount={mainForm.values.bankName}
            initialValue={[transaction[0].account_head_name, transaction[0].account_head_id]}
            accountType="expense"
            placeholder="Select Account Name"
            onMouseDown={e => e.preventDefault()}
            addBtnClick={() =>
              props.openModal(
                null,
                <AddAccountName accType="income" />,
                null,
                null,
                { footer: null }
              )
            }
            name="fromAccountName"
            filterIds={mainForm.values.is_split ? mainForm.values.splits.map(val => val.account_head_id) : [mainForm.values.toAccountName]}
            withType
            onChange={(e, type) => mainForm.handleSenderAccountChange(e, "fromAccountName", type)}
            onBlur={e => mainForm.customHandleBlur(e, "fromAccountName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromAccountName"] &&
              mainForm.errors["fromAccountName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Customer Name"
            addBtnText="Add Customer"
            disabled={!edit}
            initialValue={transaction[0].customer_name}
            link="/add-contact"
            contactType="customer"
            placeholder="Select Customer"
            name="fromCustomerName"
            onChange={e => mainForm.customHandleChange(e, "fromCustomerName")}
            onBlur={e => mainForm.customHandleBlur(e, "fromCustomerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromCustomerName"] &&
              mainForm.errors["fromCustomerName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <JobNameField
            title="Job Name"
            addBtnText="Add Job Name"
            disabled={!edit}
            initialValue={[transaction[0].job_name, transaction[0].job_id]}
            placeholder="Select Job"
            addBtnClick={() =>
              props.openModal(null, <Job />, null, null, {
                footer: null
              })
            }
            name="fromJobName"
            onChange={e => mainForm.customHandleChange(e, "fromJobName")}
            onBlur={e => mainForm.customHandleBlur(e, "fromJobName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromJobName"] && mainForm.errors["fromJobName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <Field
            name="amount"
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            disabled={!edit}
            value={mainForm.values.amount}
            onChange={mainForm.handleTotalChange}
            onBlur={mainForm.handleBlur}
          />
          <span className="dangerColor caption textCenter">
              {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            name="fromLawyerName"
            disabled={!edit}
            initialValue={transaction[0].lawyer_name}
            link="/add-contact"
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={(e, name) => {
              mainForm.customHandleChange(e, "fromLawyerName");
              mainForm.customHandleChange(e, "toLawyerName");
              mainForm.customHandleChange(name, "lawyer");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "fromLawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["fromLawyerName"] &&
              mainForm.errors["fromLawyerName"]}
          </span>
        </Col>
        {/* To */}
        <Col span={24} style={{ paddingBottom: 0 }}>
          <h3>Payee</h3>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
            title="Customer Name"
            addBtnText="Add Customer"
            disabled={true}
            initialValue={transaction[1].customer_name}
            link="/add-contact"
            contactType="customer"
            placeholder="Select Customer"
            name="toCustomerName"
            getFromBankLawfirm={mainForm.values.defaultBankContact}
            onChange={e => mainForm.customHandleChange(e, "toCustomerName")}
            onBlur={e => mainForm.customHandleBlur(e, "toCustomerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toCustomerName"] &&
              mainForm.errors["toCustomerName"]}
          </span>
        </Col>
        <Col xs={24} sm={12}>
          <OptionsField
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            disabled={true}
            initialValue={transaction[1].lawyer_name}
            lawyerValue={mainForm.values.lawyer}
            name="toLawyerName"
            link="/add-contact"
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={e => mainForm.customHandleChange(e, "toLawyerName")}
            onBlur={e => mainForm.customHandleBlur(e, "toLawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toLawyerName"] &&
              mainForm.errors["toLawyerName"]}
          </span>
        </Col>
        {/* <Col span={24}>
          <Split
            splits={mainForm.values.splits}
            initialValues={transaction[0].is_split === "true" ? 
                transaction.slice(1).map(val => ({
                  account_head_name: val.account_head_name,
                  account_head_id: val.account_head_id,
                  job_name: val.job_name,
                  job_id: val.job_id
                })) : undefined
            }
            amount={mainForm.values.amount}
            remainingAmount={
              (parseFloat(mainForm.values.amount) || 0) - mainForm.splitTotal
            }
            errors={mainForm.errors.splits}
            touched={mainForm.touched.splits}
            onSplitChange={mainForm.handleSplitChange}
            onSplitBlur={mainForm.handleSplitBlur}
            onAddSplit={mainForm.addSplit}
            onClearSplits={mainForm.clearSplits}
            filterIds={[mainForm.values.fromAccountName]}
            accountType={mainForm.values.toAccountType}
            disabled={!mainForm.values.fromAccountName || !edit}
          />
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["noOfSplits"]}
          </div>
          <div className="dangerColor caption textCenter">
            {mainForm.touched["is_split"] && mainForm.errors["remainingAmount"]}
          </div>
        </Col> */}

        {!(mainForm.values.splits && mainForm.values.splits.length) ? (
          <React.Fragment>
            <Col xs={24} sm={12}>
              <AccountNameField
                id="senderAccountField"
                title="Account Name"
                addBtnText="Add Account Name"
                //disabled={!mainForm.values.fromAccountName || !edit}
                disabled={true}
                initialValue={transaction[0].is_split === "true" ? undefined : [transaction[1].account_head_name, transaction[1].account_head_id]}
                //accountType={mainForm.values.toAccountType}
                //bankAccount={mainForm.values.bankName}
                withType
                placeholder="Select Account Name"
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType={mainForm.values.toAccountType} />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                filterIds={[mainForm.values.fromAccountName]}
                name="toAccountName"
                onChange={(e, type) => {
                  mainForm.customHandleChange(e, "toAccountName");
                  mainForm.customHandleChange(type, "toAccountType");
                }}
                onBlur={e => mainForm.customHandleBlur(e, "toAccountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["toAccountName"] &&
                  mainForm.errors["toAccountName"]}
              </span>
            </Col>

            <Col xs={24} sm={12}>
            <JobNameField
            title="Job Name"
            addBtnText="Add Job Name"
            disabled={true}
            initialValue={transaction[0].is_split === "true" ? undefined : [transaction[1].job_name, transaction[1].job_id]}
            placeholder="Select Job"
            getFromBankLawfirm={mainForm.values.defaultBankJob}
            addBtnClick={() =>
              props.openModal(null, <Job />, null, null, {
                footer: null
              })
            }
            name="toJobName"
            onChange={e => mainForm.customHandleChange(e, "toJobName")}
            onBlur={e => mainForm.customHandleBlur(e, "toJobName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["toJobName"] && mainForm.errors["toJobName"]}
          </span>
            </Col>
          </React.Fragment>
        ) : null}

        {/* <Col xs={24} sm={12}>
          <OptionsField
            title="Payee"
            addBtnText="Add Payee"
            contactType=""
            placeholder="Select Payee"
          />
        </Col> */}

        <Col xs={24} sm={12}>
        <Field
            title="Date Of Transaction"
            customInput={DatePicker}
            disabled={!edit}
            value={mainForm.values.dateOfTransaction}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="dateOfTransaction"
            onChange={e => mainForm.customHandleChange(e, "dateOfTransaction")}
            onBlur={e => mainForm.customHandleBlur(e, "dateOfTransaction")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["dateOfTransaction"] &&
              mainForm.errors["dateOfTransaction"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Field title="Check Number" placeholder="Check Number" />
        </Col> */}

        {/* <Col xs={24} sm={12}>
          <Field title="Cleared" span noHeadingMargin customInput={Switch} />
        </Col> */}

        <Col xs={24} sm={12}>
        <Field
            title="Additional Notes"
            //name="additionalNotex"
            placeholder="Note"
            disabled={!edit}
            value={mainForm.values.additionalNote}
            style={{ height: "8.8rem" }}
            customInput={TextArea}
            name="additionalNote"
            onChange={e => mainForm.customHandleChange(e, "additionalNote")}
            onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["additionalNote"] &&
              mainForm.errors["additionalNote"]}
          </span>
        </Col>
        </Row>
      </Col>
    </Row>
  );
};
const mapStateToProps = state => ({loading: state.transaction.updateTransferLoading, settings: state.settings.settings});

export default withRouter(
  connect(mapStateToProps, { updateTransferRequest, getTransactionRequest, openModal, closeAllModal })(TransferTransaction)
);
