import React, { Fragment } from "react";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { resetPassword } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import BackButton from "../../appcomponents/shares/header/BackButton";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../actions/Auth";
import { FiArrowLeft } from "react-icons/fi";
import "./auth.css";

class ResetScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm_password: ""
    };
  }

  componentDidMount() {
    if (this.props.history.location.state != undefined) {
      if (this.props.history.location.state.email != undefined) {
        this.setState({
          email: this.props.history.location.state.email
        });
      } else {
        this.props.history.push({
          pathname: "/login"
        });
      }
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    // let BackButton = FiArrowLeft;
    // console.log("State Reset Password ", this.state);
    // console.log("Props Reset Password ", this.props);
    return (
      <Fragment>
        <Row type="flex" className="setPosition">
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <BackButton
            title="Reset Your Password"
              action={() =>
                this.props.history.push(
                  this.props.backButtonLink
                    ? this.props.backButtonLink
                    : "/verify-code"
                )
              }
            
            />{" "}
           
          </Col>
        </Row>

        <AuthContainer>
          <Row type="flex" justify="center" align="middle">
            <Col xs={24} sm={20} md={22} lg={12} xl={12}>
              <h4 className="mr-b mr-t-md">Please enter your new password</h4>

              <RootForm
                schemaName={resetPassword}
                initialValues={this.state}
                apiCall={changePasswordRequest}
                extras={this.state.email}
                loading={this.props.Auth.loading}
                cb={() => {
                  this.props.history.push({
                    pathname: "/login"
                  });
                }}
                controls={[
                  {
                    span: 24,
                    name: "password",
                    value: this.state.password,
                    handleChanged: this.handleChanged,
                    placeholder: "Password",
                    Type: Input
                  },
                  {
                    span: 24,
                    name: "confirm_password",
                    value: this.state.confirm_password,
                    handleChanged: this.handleChanged,
                    placeholder: "Confirm Password",
                    Type: Input
                  }
                ]}
                submitButton={{
                  span: 16,
                  buttonText: "Save",
                  className: "mr-t-md mr-b-lg"
                }}
              />
            </Col>
          </Row>
        </AuthContainer>
      </Fragment>
    );
  }
}

export default connect(storeState => ({ Auth: storeState.Auth }))(ResetScreen);
