import React, { useState, useRef, useEffect, Fragment } from "react";
import General from "./General";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { FiAlertTriangle } from "react-icons/fi";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getJobsRequest } from "../../../../../actions/FieldOptions";
import { deleteJobRequest } from "../../../../../actions/transaction/Delete";
import PopConfirm from "../../../../../components/pop-confirm";
import DeleteButton from "../../DeleteButton";

const JobName = (props) => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(
    props.initialValue
      ? { value: props.initialValue[0], jobId: props.initialValue[1] }
      : {}
  );
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiCall = useRef();

  useEffect(() => {
    if(props.deletedJob && props.deletedJob === value.jobId) {
      props.onChange();
      setValue({ value: undefined });
    }
  }, [props.deletedJob])

  useEffect(() => {
    if(props.withJobValue) {
      setValue({ value: props.jobValue })
    }
  }, [props.jobValue])

  useEffect(() => {
    apiCall.current = debounce(
      (e) => {
        console.log("api call", e);
        setLoading(!options.length);
        props.getJobsRequest(null, props.id, (error, data) => {
          if (error) {
            setLoading(false);
          } else {
            console.log("DATA IS THIS ", data);
            setLoading(false);
            let viewAll = props.withClearOption ? ["View All"] : [];
            setOptions([...viewAll, ...data]);
          }
        });
      },
      1000,
      true
    );
  }, [options, props.id]);
  useEffect(() => {
    setOptions([]);
    apiCall.current();
  }, [props.id]);
  useEffect(() => {
    if(props.getFromBankLawfirm) {
      setSelectLoading(true);
      props.getJobsRequest(null, undefined, (error, data) => {
        if (error) {
          setSelectLoading(false);
        } else {
          setSelectLoading(false);

          console.log("YERT", data);

          const fromLawfirm = data.find(val => val.job_id == props.getFromBankLawfirm);
          if(fromLawfirm) {
            let id = fromLawfirm.job_id, name = fromLawfirm.job_name;

            props.onChange(id, name);
            setValue({ value: name, jobId: id });
          }
        }
      });
    }
  }, [props.getFromBankLawfirm])

  //   const history = useHistory();
  console.log("getJobsRequest props==>", props);
  //   let { ...props } = props;
  return (
    <div>
      <General
        {...props}
        {...value}
        optionFilterProp="searchValue"
        loading={selectLoading}
        onChange={(e) => {
          if (props.onChange) {
            if (e) {
              props.onChange(e.split("*")[0], e.split("*")[1]);
              setValue({ value: e.split("*")[1], jobId: e.split("*")[0] });
            } else {
              props.onChange(e);
              setValue({ value: e });
            }
          }
        }}
        onSearch={(Search) => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={(open) => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          options.map((item, index) =>
            item === "View All" ? (
              <SelectOptions key={index} value="">
                All jobs
              </SelectOptions>
            ) : (
              <SelectOptions
                searchValue={item.job_name}
                key={index}
                value={`${item.job_id}*${item.job_name}`}
              >
                {!props.hideDeleteBtn ? (
                  <Row
                    type="flex"
                    align="middle"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Col span={21} className="expandMe">
                      <div>{item.job_name}</div>
                    </Col>
                    <Col
                      span={3}
                      className="hideMe"
                      // onMouseDown={e => {
                      //   e.stopPropagation();
                      //   e.preventDefault();
                      // }}
                      onClick={(e) => {
                        e.stopPropagation();
                        //e.preventDefault();
                      }}
                    >
                      <PopConfirm
                        title="Are you sure you want to delete this job?"
                        icon={
                          <FiAlertTriangle
                            style={{
                              position: "absolute",
                              color: "#f44336",
                              left: 0,
                              top: 0,
                              marginTop: 6,
                            }}
                          />
                        }
                        cancelText="No"
                        okText="Yes"
                        onConfirm={function () {
                          props.deleteJobRequest(item.job_id, (res) => {
                            apiCall.current();

                            if (props.onDelete)
                              props.onDelete(item.job_id)

                            // if (item.job_id == value.jobId) {
                            //   props.onChange();
                            //   setValue({ value: undefined });
                            // }
                          });
                        }}
                      >
                        <DeleteButton style={{ minWidth: "auto" }} />
                      </PopConfirm>
                    </Col>
                  </Row>
                ) : (
                  <Row
                    type="flex"
                    align="middle"
                    justify="space-between"
                    style={{ width: "100%" }}
                  >
                    <Col span={24} className="expandMe">
                      <div>{item.job_name}</div>
                    </Col>
                  </Row>
                )}
              </SelectOptions>
            )
          )
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default connect(null, { getJobsRequest, deleteJobRequest })(JobName);
