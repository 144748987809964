import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { loginValidations } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import { Row, Col } from "antd";
import { loginRequest } from "../../actions/Auth";
import Image from "../../components/image";
import { HeaderLogo } from "../../configurations/Config";

class LoginScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  componentWillMount() {
    if (localStorage.getItem("escrowauth") !== null) {
      // let userData = JSON.parse(window.localStorage.getItem("lockeyauth"));
      this.props.history.push("/");
    }
  }

  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  changeEmail = (value)=>{
    this.setState({email:value})
  }
  render() {
    return (
      <AuthContainer>
        <Row type="flex" justify="center" align="middle">
          <Col span={10} align="middle" style={{cursor:"pointer"}} onClick={()=>this.props.history.push('/')}>
            { HeaderLogo()}
          
          </Col>

          <Col span={24} style={{ padding: "3rem 0 0 0" }}  >
            <h4 >
            Welcome, Please Login
            </h4>
          </Col>

        

          {/* </Col> */}
          <Col xs={24} sm={20} md={22} lg={12} xl={14} style={{ padding: "2rem 0 0 0" }}>
            {/* <h4 className="mr-b mr-t-md">Welcome, Please Login</h4> */}
            {/* <p className='mr-b-md'>Please Login to continue</p> */}
            <RootForm
              schemaName={loginValidations}
              initialValues={this.state}
              apiCall={loginRequest}
              changeEmail={this.changeEmail}
              loading={this.props.Auth.loading}
              //cb={() => {this.props.history.push("/")}}
              controls={[
                {
                  span: 24,
                  name: "email",
                  value: this.state.email,
                  handleChanged: this.handleChanged,
                  placeholder: "Email",
                  Type: Input
                },
                {
                  span: 24,
                  name: "password",
                  value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "Password",
                  Type: Input
                }
              ]}
              isLink={{
                span: 14,
                status: "true",
                linktext: "Forgot Password?",
                url: "forgot-password",
                className: "link-color mr-b-sm textCenter",
                align: "center"
              }}
              submitButton={{
                span: 16,
                buttonText: "Login",
                className: "mr-b-sm"
              }}
            />

          </Col>
          <Link
              //onClick={() => this.resendApiCall()}
              to="/signup"
              className="card-link-out"
            >
            Don't have an account?
              </Link>
        </Row>
       
      </AuthContainer>
    );
  }
}

export default connect(storeState => ({ Auth: storeState.Auth }))(LoginScreen);
