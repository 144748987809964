import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "../../../components/button";
import Card from "../../../components/card";
import HeaderWrapper from "../../../components/header/index";
import "../../auth/auth.css";

import { FiPrinter, FiSend, FiDownload } from "react-icons/fi";

let current_date = moment();

const PreviewReport = props => {
  let report = props.location.state && props.location.state.report;
  let url = report && report.report_url;
  let format = props.location.state && props.location.state.format;

  console.log("PREVIEW", report.report_type);

  return props.location.state && url ? (
    <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
      <Col span={24}>
        <HeaderWrapper
          bisect
          rightAlign={[
            <Row type="flex" gutter={{xs: 20, sm: 30}}>
              <Col span={8}>
                <Button
                  disabled={format !== "pdf"}
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={() => window.open(url)}
                >
                  <FiPrinter style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Print
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={() =>
                    props.history.push({
                      pathname: "/reports/send-report",
                      state: {
                        referrer: props.location.pathname,
                        referrerState: props.location.state,
                        reportUrl: url,
                        reportType: report.report_type,
                        reportFormat: format
                      }
                    })
                  }
                >
                  <FiSend style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Send
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={() => window.open(url)}
                >
                  <FiDownload style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Download
                </Button>
              </Col>
            </Row>
          ]}
          externalscreen={1}
        />
      </Col>
      <Col span={24}>
        {format === "pdf" ? <Card className="a4Size noPadding" style={{ margin: "auto" }}>
          <iframe width="100%" height="100%" src={url + "#view=FitH"} />
        </Card> : (
          <h4
          style={{
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            margin: "30vh 0",
            textAlign: "center"
          }}
          className="appColor"
        >
          No Preview Available
        </h4>
        )}
        {/* <CardWrapper> */}
        {/* {this.state.tab === "deposit" ? (
          <Deposit bank={this.state.bank} />
        ) : (
          <Withdraw bank={this.state.bank}/>
        )} */}
        {/* </CardWrapper> */}
      </Col>
    </Row>
  ) : (
    <Redirect to="/" />
  );
};

export default connect(null, null)(PreviewReport);
