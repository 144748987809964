import React from "react";
import MenuWrapper from "../menu";
import { Menu } from "antd";
import Image from "../../components/image";
import { withRouter, Link } from "react-router-dom";
// import HomeIcon from "../../assets/icons/home-line.svg";
// import ProfileIcon from "../../assets/icons/user-4-line.svg";
import {
  FiDollarSign,
  FiPieChart,
  FiPlus,
  FiFileText,
  FiSearch,
  FiUsers,
  FiSettings,
  FiLayers
} from "react-icons/fi";
import styled from "styled-components";

const NavigationWrapper = styled.div`
  & {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3000;
    box-shadow: 0px 5px 20px
      ${props => props.theme[props.theme.mode].textColor.primary}0D;
    background-color: ${props => props.theme[props.theme.mode].common.white};
    .ant-menu {
      justify-content: space-between;
    }
    .newMatchIcon {
      width: 2.313rem;
      min-width: auto;
    }
    .ant-menu-item.sideBarItem {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
`;

const NavigationMenu = props => {
  return (
    <React.Fragment>
      <NavigationWrapper>
        <MenuWrapper
          mode="horizontal"
          defaultSelectedKeys={[props.location.pathname]}
          selectedKeys={[props.location.pathname]}
        >
          <Menu.Item className="sideBarItem" key="/">
            <Link to="/">
              <FiDollarSign />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/charts">
            <Link to="/charts">
              <FiPieChart />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/add-transaction">
            <Link to="/add-transaction">
              <FiPlus />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/reports">
            <Link to="/reports">
              <FiFileText />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/search-transaction">
            <Link to="/search-transaction">
              <FiSearch />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/view-contact">
            <Link to="view-contact">
              <FiUsers />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/settings">
            <Link to="/settings">
              <FiSettings />
            </Link>
          </Menu.Item>
          <Menu.Item className="sideBarItem" key="/subscriptions">
            <Link to="/subscriptions">
              <FiLayers />
            </Link>
          </Menu.Item>
        </MenuWrapper>
      </NavigationWrapper>
    </React.Fragment>
  );
};

export default withRouter(NavigationMenu);
