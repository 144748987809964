import { Col, Row } from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Button from "../../../components/button";
import Select from "../../../components/input/Select";
import Option from "../../../components/input/SelectOptions";
import Loader from "../../../components/loading";
import Card from "../../../components/card";
import HeaderWrapper from "../../../components/header/index";
import { chartReportRequest } from "../../../actions/Reports";
import "../../auth/auth.css";

import { FiPrinter, FiSend, FiDownload } from "react-icons/fi";

let current_date = moment();

const PreviewChartReport = props => {
  const [type, setType] = useState((props.location.state && props.location.state.type) || "pdf");
  const [report, setReport] = useState((props.location.state && props.location.state.report) || undefined);

  let params = props.location.state && props.location.state.params;
  //let url = report && report.report_url;
  //let format = props.location.state && props.location.state.format;

  console.log("PREVIEW", props);

  // const handleTypeChange = type => {
  //   setType(type);
  //   setReport(undefined)
  //   if(type) {
  //     //Make api call
  //     props.chartReportRequest({ ...params, format: type }, report => {
  //       setReport(report);
  //     })
  //   }
  //   //else {
  //     //Wipe report
  //     //setReport(undefined)
  //   //}
  // }

  useEffect(() => {
    if(type) {
      setReport(undefined);
      if(params["date_type"]==="alldata")
      {
        params["date_type"]="custom";
        params["year"]="";
        params["month"]="";
      }
      props.chartReportRequest({ ...params, format: type }, report => {
        setReport(report);
      });
    }
  }, [type])

  return (props.location.state && props.location.state.params) ? (
    <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
      <Col span={24}>
        <HeaderWrapper
          bisect
          leftAlign={
            <Select
              placeholder="Charts Type"
              onChange={setType}
              value={type}
              style={{ width: "100%" }}
            >
              <Option value="csv">CSV</Option>
              <Option value="html">HTML</Option>
              <Option value="pdf">PDF</Option>
            </Select>
          }
          rightAlign={[
            <Row type="flex" gutter={{ xs: 20, sm: 30 }}>
              <Col span={8}>
                <Button
                  disabled={!type || (type !== "pdf" || !report) }
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={report ? () => window.open(report.report_url) : undefined}
                >
                  <FiPrinter style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Print
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  block
                  disabled={!type || !report}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={report ? () =>
                    props.history.push({
                      pathname: "/reports/send-report",
                      state: {
                        referrer: props.location.pathname,
                        referrerState: {
                          ...props.location.state,
                          type,
                          report
                        },
                        reportUrl: report.report_url,
                        reportType: report.report_type,
                        reportFormat: type
                      }
                    }) : undefined
                  }
                >
                  <FiSend style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Send
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  disabled={!type || !report}
                  block
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  onClick={report ? () => window.open(report.report_url) : undefined}
                >
                  <FiDownload style={{ fontSize: "1rem" }} />
                  &nbsp;&nbsp;Download
                </Button>
              </Col>
            </Row>
          ]}
          externalscreen={1}
        />
      </Col>
      <Col span={24}>
        {type ? type === "pdf" && report ? (
          <Card className="a4Size noPadding" style={{ margin: "auto" }}>
            <iframe width="100%" height="100%" src={report.report_url + "#view=FitH"} />
          </Card>
        ) : props.reports.chartReportLoading ? (
          <Loader />
        ) : (
          <h4
            style={{
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              margin: "30vh 0",
              textAlign: "center"
            }}
            className="appColor"
          >
            No Preview Available
          </h4>
        ) : (
          <h4
            style={{
              // position: "absolute",
              // top: "50%",
              // left: "50%",
              // transform: "translate(-50%, -50%)",
              margin: "30vh 0",
              textAlign: "center"
            }}
            className="appColor"
          >
            Please selct a type
          </h4>
        )}
        {/* <CardWrapper> */}
        {/* {this.state.tab === "deposit" ? (
          <Deposit bank={this.state.bank} />
        ) : (
          <Withdraw bank={this.state.bank}/>
        )} */}
        {/* </CardWrapper> */}
      </Col>
    </Row>
  ) : (
    <Redirect to="/" />
  );
};

export default connect(state => ({ reports: state.Reports }), { chartReportRequest })(PreviewChartReport);
