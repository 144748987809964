import { put, takeLatest } from "redux-saga/effects";
import {
  ADD_BANK_ACCOUNT_REQUEST,
  GET_BANK_ACCOUNTS_REQUEST,
  DELETE_BANK_ACCOUNT_REQUEST,
  UPDATE_BANK_ACCOUNT_REQUEST,
  GET_ONE_BANK_REQUEST
} from "../configurations/Types";
import { BANK_ACCOUNT_ENDPOINT } from "../configurations/Constants";
import {
  addBankAccountSuccess,
  getBankAccountsSuccess,
  deleteBankAccountSuccess,
  getBankAccountsRequest,
  updateBankAccountSuccess,
  updateBankAccountError,
  getSingleBankSuccess,
  addBankAccountError,
  getSingleBankError,
  deleteBankAccountError,
  getBankAccountsError,
  recordEntry
} from "../actions/BankAccount";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

/* Add All Bank Account */
const addBankApi = body => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  return privateAgent.post(`${BANK_ACCOUNT_ENDPOINT}`, {
    ...body,
    current_time
  });
};

function* addBankAccount(action) {
  let res;
  try {
    let { open_date, ...properBody } = action.body;
    let formatedDate = moment(open_date).format("YYYY-MM-DD");

    let bankAccountDetails = {
      ...properBody,
      open_date: formatedDate
    };

    res = yield addBankApi(bankAccountDetails);
    info("success", `${res.data.data.message}`);
    yield put(addBankAccountSuccess());
    
    yield put(recordEntry(action.body.law_firm_name, action.body.bank_account_name, action.body.bank_name));

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(addBankAccountError());
  }
}

/* Get All Bank Account */
const getAllBankApi = (limit, offset, like) => {
  return privateAgent.get(`${BANK_ACCOUNT_ENDPOINT}`, {
    params: {
      limit,
      offset,
      like
    }
  });
};

function* getBankAccount(action) {
  let res;

  try {
    res = yield getAllBankApi(action.limit, action.offSet, action.like);
    yield put(getBankAccountsSuccess(res.data.data));
    if (action.cb) {
      action.cb(null, res.data.data);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    if (action.cb) {
      action.cb(e);
    }
    yield put(getBankAccountsError());
  }
}

/* Delete bank Account */
const deleteBankApi = id => {
  return privateAgent.delete(`${BANK_ACCOUNT_ENDPOINT}/${id}`);
};

function* deleteBankAccount(action) {
  let res;
  try {
    res = yield deleteBankApi(action.id);
    yield put(deleteBankAccountSuccess());
    yield put(getBankAccountsRequest(9, 0));
    info("success", `${res.data.data.message}`);
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(deleteBankAccountError(e));
  }
}
/* Get single Bank Account */
const getSingleBankApi = id => {
  return privateAgent.get(`${BANK_ACCOUNT_ENDPOINT}/${id}`);
};

function* getSingleBank(action) {
  let res;
  try {
    res = yield getSingleBankApi(action.id);
    yield put(getSingleBankSuccess(res.data.data));
    console.log("CALLBACK : ", res.data.data);  
    if (action.cb) {
      action.cb(null, res.data.data)
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getSingleBankError(e));
  }
}

// update bank account
const updateBankApi = body => {
  // console.log("body updateBankApi", body);

  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  // console.log("current_time", current_time);
  return privateAgent.put(`${BANK_ACCOUNT_ENDPOINT}/${body.id}`, {
    ...body,
    open_date: moment(body.open_date).format("YYYY-MM-DD"),
    current_time
  });
};

function* updateBankAccount(action) {
  let res;
  try {
    // action.body.open_date = moment(action.body.open_date).format("YYYY-MM-DD");
    res = yield updateBankApi(action.body);
    // console.log("updateBankApi",res)
    info("success", `${res.data.data.message}`);
    yield put(updateBankAccountSuccess());
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(updateBankAccountError());
  }
}

// Our watcher Saga: spawn a new Bank Account task on each ADD_BANK_ACCOUNT_REQUEST
export function* watchBankAccount() {
  yield takeLatest(ADD_BANK_ACCOUNT_REQUEST, addBankAccount);
  yield takeLatest(GET_BANK_ACCOUNTS_REQUEST, getBankAccount);
  yield takeLatest(DELETE_BANK_ACCOUNT_REQUEST, deleteBankAccount);
  yield takeLatest(UPDATE_BANK_ACCOUNT_REQUEST, updateBankAccount);
  yield takeLatest(GET_ONE_BANK_REQUEST, getSingleBank);
}
