import {
  SEND_REPORT_REQUEST,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  CHART_OF_ACCOUNTS_REQUEST,
  CHART_OF_ACCOUNTS_SUCCESS,
  CHART_OF_ACCOUNTS_ERROR,
  BANK_ACCOUNT_LEDGER_REQUEST,
  BANK_ACCOUNT_LEDGER_SUCCESS,
  BANK_ACCOUNT_LEDGER_ERROR,
  TRIAL_BALANCE_SUMMARY_REQUEST,
  TRIAL_BALANCE_SUMMARY_SUCCESS,
  TRIAL_BALANCE_SUMMARY_ERROR,
  PROFIT_AND_LOSS_REQUEST,
  PROFIT_AND_LOSS_SUCCESS,
  PROFIT_AND_LOSS_ERROR,
  TRANSFER_JOURNAL_LEDGER_REQUEST,
  TRANSFER_JOURNAL_LEDGER_SUCCESS,
  TRANSFER_JOURNAL_LEDGER_ERROR,
  RECONCILIATION_FOR_LAWYERS_REQUEST,
  RECONCILIATION_FOR_LAWYERS_SUCCESS,
  RECONCILIATION_FOR_LAWYERS_ERROR,
  CHART_REPORT_REQUEST,
  CHART_REPORT_SUCCESS,
  CHART_REPORT_ERROR,
  ESCROW_DISCLOSURE_STATEMENT_REQUEST,
  ESCROW_DISCLOSURE_STATEMENT_SUCCESS,
  ESCROW_DISCLOSURE_STATEMENT_ERROR,
} from "../configurations/Types";

//Send report
export const sendReportRequest = (body, cb) => {
  return {
    type: SEND_REPORT_REQUEST,
    body,
    cb
  };
};

export const sendReportSuccess = payload => {
  return {
    type: SEND_REPORT_SUCCESS,
    payload
  };
};

export const sendReportError = error => {
  return {
    type: SEND_REPORT_ERROR,
    error
  };
};

//Get chart of accounts report
export const chartOfAccountsRequest = (params, cb) => {
  return {
    type: CHART_OF_ACCOUNTS_REQUEST,
    params,
    cb
  };
};

export const chartOfAccountsSuccess = payload => {
  return {
    type: CHART_OF_ACCOUNTS_SUCCESS,
    payload
  };
};

export const chartOfAccountsError = error => {
  return {
    type: CHART_OF_ACCOUNTS_ERROR,
    error
  };
};

//Get bank account ledger report
export const bankAccountLedgerRequest = (params, cb) => {
  return {
    type: BANK_ACCOUNT_LEDGER_REQUEST,
    params,
    cb
  };
};

export const bankAccountLedgerSuccess = payload => {
  return {
    type: BANK_ACCOUNT_LEDGER_SUCCESS,
    payload
  };
};

export const bankAccountLedgerError = error => {
  return {
    type: BANK_ACCOUNT_LEDGER_ERROR,
    error
  };
};
//Get bank escrow disclosure statement report
export const escrowDisclosureStatementRequest = (params, cb) => {
  let month,year;
  ({  month,year, ...params } = params);
  return {
    type: ESCROW_DISCLOSURE_STATEMENT_REQUEST,
    params,
    cb
  };
};

export const escrowDisclosureStatementSuccess = payload => {
  return {
    type: ESCROW_DISCLOSURE_STATEMENT_SUCCESS,
    payload
  };
};

export const escrowDisclosureStatementError = error => {
  return {
    type: ESCROW_DISCLOSURE_STATEMENT_ERROR,
    error
  };
};

//Get trial balance summary report
export const trialBalanceSummaryRequest = (params, cb) => {
  console.log("jjjj", params)
  let month;
  ({  month, ...params } = params);
  return {
    type: TRIAL_BALANCE_SUMMARY_REQUEST,
    params,
    cb
  };
};

export const trialBalanceSummarySuccess = payload => {
  return {
    type: TRIAL_BALANCE_SUMMARY_SUCCESS,
    payload
  };
};

export const trialBalanceSummaryError = error => {
  return {
    type: TRIAL_BALANCE_SUMMARY_ERROR,
    error
  };
};

//Get profit and loss report
export const profitAndLossRequest = (params, cb) => {
  return {
    type: PROFIT_AND_LOSS_REQUEST,
    params,
    cb
  };
};

export const profitAndLossSuccess = payload => {
  return {
    type: PROFIT_AND_LOSS_SUCCESS,
    payload
  };
};

export const profitAndLossError = error => {
  return {
    type: PROFIT_AND_LOSS_ERROR,
    error
  };
};

//Get transfer journal ledger report
export const transferJournalLedgerRequest = (params, cb) => {
  return {
    type: TRANSFER_JOURNAL_LEDGER_REQUEST,
    params,
    cb
  };
};

export const transferJournalLedgerSuccess = payload => {
  return {
    type: TRANSFER_JOURNAL_LEDGER_SUCCESS,
    payload
  };
};

export const transferJournalLedgerError = error => {
  return {
    type: TRANSFER_JOURNAL_LEDGER_ERROR,
    error
  };
};

//Get reconiciliation for lawyers report
export const reconciliationForLawyersRequest = (params, cb) => {
  return {
    type: RECONCILIATION_FOR_LAWYERS_REQUEST,
    params,
    cb
  };
};

export const reconciliationForLawyersSuccess = payload => {
  return {
    type: RECONCILIATION_FOR_LAWYERS_SUCCESS,
    payload
  };
};

export const reconciliationForLawyersError = error => {
  return {
    type: RECONCILIATION_FOR_LAWYERS_ERROR,
    error
  };
};

//Get chart report
export const chartReportRequest = (params, cb) => {
  return {
    type: CHART_REPORT_REQUEST,
    params,
    cb
  };
};

export const chartReportSuccess = payload => {
  return {
    type: CHART_REPORT_SUCCESS,
    payload
  };
};

export const chartReportError = error => {
  return {
    type: CHART_REPORT_ERROR,
    error
  };
};
