import { useState, useEffect } from "react";
import useFormikWrapper from "../../../components/formikwrapper/FormikWrapper";
import {
  recurringWithdrawal
} from "../../../configurations/Schemas/index";

const useRecurringWithdrawForm = props => {

  const[initialValues, setInitialValues] = useState(props.initialValues);

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      cleared: props.initialValues.cleared
    })
  }, [props.initialValues.cleared]);

  const formik = useFormikWrapper({
    ...props,
    initialValues,
    validationSchema: recurringWithdrawal,
    enableReinitialize: true
  });

  return formik;
};

export default useRecurringWithdrawForm;
