import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { updateProfiles } from "../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { openModal, closeAllModel } from "../../actions/Modal";
import { profileUpdateRequest } from "../../actions/UpdateProfile";
import HeaderWrapper from "../../components/header/index";
import CardWrapper from "../../components/card";
import { FiArrowLeft } from "react-icons/fi";
import "../auth/auth.css";
import ChangePasswordScreen from "./ChangePassword";

class UpdateProfile extends React.Component {
  constructor(props) {
    let { full_name, email } = props.Auth.profileDetails;
    super(props);
    this.state = {
      full_name,
      email
    };
  }

  //   componentDidMount() {
  //     if (this.props.history.location.state != undefined) {
  //   console.log("State location history", this.props.history.location.state);
  //       if (this.props.history.location.state.email != undefined) {
  //         console.log("Email Not Undefined");
  //         this.setState({
  //           email: this.props.history.location.state.email
  //         });
  //       } else {
  //         this.props.history.push({
  //           pathname: "/login"
  //         });
  //       }
  //     } else {
  //       this.props.history.push({
  //         pathname: "/login"
  //       });
  //     }
  //   }

  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    // console.log("isAuthenticated", this.props.Auth);
    let BackButton = FiArrowLeft;
    // console.log("State", this.state);
    // console.log("Props Reset Password ", this.props);

    return (
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col>
        <Col xs={24} sm={20} md={22} lg={12}>
          <CardWrapper>
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="textCenter"
            >
              <Col xs={24} sm={20} md={22} lg={14}>
                <h4 className="mr-b mr-t-md">My Profile</h4>

                <RootForm
                  schemaName={updateProfiles}
                  initialValues={this.state}
                  apiCall={profileUpdateRequest}
                  // extras={this.state.email}
                  loading={this.props.UpdateProfile.loading}
                  cb={() => this.props.history.push("/")}
                  controls={[
                    {
                      span: 24,
                      name: "full_name",
                      value: this.state.full_name,
                      handleChanged: this.handleChanged,

                      Type: Input
                    },
                    {
                      span: 24,
                      name: "email",
                      value: this.state.email,
                      handleChanged: this.handleChanged,
                      disabled: true,
                      Type: Input
                    }
                  ]}
                  submitButton={{
                    span: 16,
                    buttonText: "Save Changes",
                    className: "mr-t-md mr-b-lg"
                  }}
                />

                <Link
                  onClick={() => {
                    this.props.openModal(
                      null,
                      <div>
                        <ChangePasswordScreen />
                      </div>,
                      null,
                      null,
                      {
                        footer: null
                      }
                    );
                  }}
                  //className="card-link-out"
                >
                  Change Password
                </Link>
              </Col>
            </Row>
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(
    storeState => ({
      Auth: storeState.Auth,
      UpdateProfile: storeState.UpdateProfile
    }),
    { openModal }
  )(UpdateProfile)
);
