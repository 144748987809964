import React, { Fragment, forwardRef } from "react";
import Card from "../../../../components/card/index";
import Tag from "../../../../components/tag";
import Image from "../../../../components/image";
import Button from "../../../../components/button";
import check from "../../../../assets/icons/check.svg";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

const plans = {
  basic: {
    price: "$9.99/month",
    features: [
      "2 Bank Accounts",
      "15 Customer Accounts",
      "Multi-Platform",
      "Multi-Device Login",
      "15 Custom Accounts",
    ],
  },
  professional: {
    price: "$24.99/month",
    features: [
      "20 Bank Accounts",
      "100 Customer Accounts",
      "Multi-Platform",
      "Multi-Device Login",
      "50 Custom Accounts",
    ],
  },
  enterprise: {
    price: "$64.99/month",
    features: [
      "200 Bank Accounts",
      // "1000 Customer Accounts",
      // "Multi-Platform",
      // "Multi-Device Login",
      // "100 Custom Accounts"
    ],
  },
  "professional annum": {
    price: "$24.99/year",
    features: [
      "20 bank accounts",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  },
  "enterprise annum": {
    price: "$64.99/year",
    features: [
      "200 bank accounts",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  },
};

const UpgradePlans = (props, ref) => {
  return (
    <div ref={ref}>
      <Card smallPadding className="secondaryCard">
        <Row type="flex" gutter={[0, 20]}>
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <h3 className="no-mr" style={{ color: "#FFFF" }}>
                  {props.plan
                    ? props.plan
                        .split(" ")
                        .map(
                          (word) => word[0].toUpperCase() + word.substring(1)
                        )
                        .join(" ")
                    : ""}
                </h3>
              </Col>
              <Col>
                {/* <Tag style={{ width: "10rem" }}>{plans[props.plan].price}/month</Tag> */}
                <Tag style={{ width: "10rem" }}>
                  {plans[props.plan].price}
                </Tag>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {plans[props.plan].features.map((item) => (
              <Fragment>
                <Row
                  type="flex"
                  justify="start"
                  style={{ flexWrap: "nowrap" }}
                  align="middle"
                  gutter={[5, 30]}
                >
                  <Col>
                    <div
                      style={{
                        backgroundColor: "#58ba27",
                        height: "1.2rem",
                        width: "1.2rem",
                        display: "flex",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        style={{ height: "0.7rem", width: "0.7rem" }}
                        src={check}
                        alt="Check"
                      />
                    </div>
                  </Col>
                  <Col style={{ flexGrow: 1 }}>
                    <p
                      style={{
                        color: "#FFFF",
                        paddingLeft: "0.45rem",
                        fontWeight: 500,
                      }}
                    >
                      {item}
                    </p>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </Col>

          <Col span={24}>
            <Row type="flex" justify="center" align="middle">
              <Col>
                {/* <Link
                style={{
                  backgroundColor: "#58ba27",
                  borderRadius: 2,
                  padding: "10px 45px",
                  textDecoration: "none",
                  color: "#FFFF"
                }}
                className="fontBold"
                //   to="/signup"
              >
                Subscribe
              </Link> */}
                <Button onClick={props.onSelect} disabled={props.disabled}>
                  Subscribe
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default forwardRef(UpgradePlans);
