import React from "react";
import styled from "styled-components";
import { Tag } from "antd";
const StyledTag = styled(Tag)`
  text-align: center;
  background-color: ${(props) =>
    props.type
      ? props.theme[props.theme.mode].tag.colors[props.type]
      : props.theme[props.theme.mode].primary.main};
  color: ${(props) => props.theme["light"].textColor.inverted};
  border: none;
  margin-right: 0;
  /* padding:0.2vw; */
  padding: 0.5vw 0.2vw;
  width:50% ;
  border-radius: 25px;
  font-size: inherit;
  font-weight: normal;
  &:hover {
    opacity: 1;
  }
  & .ant-tag{}
  .tagWidth {
    width: 7rem
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;
const TagWrapper = (props) => {
  console.log("props.type", props.type);
  return <StyledTag {...props}></StyledTag>;
};
export default TagWrapper;
