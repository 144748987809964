import React from "react";
import Input from "../../components/input/index";
import { connect } from "react-redux";
import RootForm from "../../components/form/index";
import { changePassword } from "../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../actions/App";
import {closeAllModal } from "../../actions/Modal";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      new_password: "",
      confirm_password: ""
    };
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  render() {
    return (
      <Row
        type="flex"
        type="flex"
        justify="center"
        align="middle"
        className="textCenter"
      >
        <h3 className="mr-b mr-t-md">Please enter your current password</h3>
        <p className="mr-b-md">
          Choose a strong new password. Make sure it's unique.
        </p>

        <Col xs={24} sm={20} md={22} lg={16}>
          <RootForm
            schemaName={changePassword}
            initialValues={this.state}
            apiCall={changePasswordRequest}
            closeAllModel
            // extras={this.props.userEmail}
            colSpan={18}
            loading={this.props.App.loading}
            cb={this.props.closeAllModal}
            //autoWidthSubmit
            controls={[
              {
                span: 24,
                name: "password",
                value: this.state.password,
                handleChanged: this.handleChanged,
                placeholder: "Password",
                Type: Input
              },
              {
                span: 24,
                name: "new_password",
                value: this.state.new_password,
                handleChanged: this.handleChanged,
                placeholder: "New Password",
                Type: Input
              },
              {
                span: 24,
                name: "confirm_password",
                value: this.state.confirm_password,
                handleChanged: this.handleChanged,
                placeholder: "Confirm Password",
                Type: Input
              }
            ]}
            submitButton={{
              span: 18,
              buttonText: "Change Password",
              className: "mr-t-md mr-b-lg"
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default connect(storeState => ({ App: storeState.App }),{closeAllModal})(ChangePassword);
