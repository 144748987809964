import React from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';
import { changePassword } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { changePasswordRequest } from "../../actions/App";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      password: "",
      confirmPassword: '',
    };
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  render() {
    return (
      <AuthContainer 
     // heading="Change Your Password"
      backButtonLink="/profile"
      >
        <Row type="flex" justify="center" align="middle">

        <Col span={24} style={{ padding: "3rem 0 0 0" }}  >
            <h4 >
           Please enter your current password
            </h4>
          </Col>


          <Col xs={24} sm={20}>
            <RootForm
              // schemaName={changePassword}
              initialValues={this.state}
              // apiCall={changePasswordRequest}
              // extras={this.props.Auth.profileDetails.id}
              loading={this.props.App.loading}
              resetAfterSubmit
              cb={() => {}}
              autoWidthSubmit
              controls={[
                {
                  span: 24,
                  name: "currentPassword",
                  value: this.state.currentPassword,
                  handleChanged: this.handleChanged,
                  placeholder: "Current Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "password",
                  value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "New Password",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "confirmPassword",
                  value: this.state.confirmPassword,
                  handleChanged: this.handleChanged,
                  placeholder: "Confirm Password",
                  Type: Input,
                }
              ]}
              submitButton={{
                span: 24,
                buttonText: "Change Password",
                className: 'mr-t-md',
              }}
            />
            {/* <Link to='login' className='card-link-out'>Back to Login</Link> */}
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default connect(storeState => ({ App: storeState.App, Auth: storeState.Auth }))(ChangePassword);
