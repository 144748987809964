import React from "react";
import { Layout } from "antd";
import styled, { withTheme } from "styled-components";
import { LeftSideBarMenu } from "../sidebarMenus/index";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  min-width: unset !important;
  position: fixed;
  height: 100vh;
  z-index: 2000;
  background: ${props => props.theme[props.theme.mode].background.secondary};
  color: ${props => props.theme[props.theme.mode].textColor.primary}; 
  box-shadow: 0px 5px 20px #1E1E2214;

  transition: width 0.2s, min-width 0.2s, max-width 0.2s, flex 0.2s, background 0s;

  /* & * {
    transition: width 0.2s, min-width 0.2s, max-width 0.2s, flex 0.2s,  background 0s;
  } */
  .ant-layout-sider-children {
    position: relative;
    .bankMenu {
      position: absolute;
      bottom: 2rem;
      color: ${props => props.theme[props.theme.mode].common.white};
      font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        color: ${props => props.theme[props.theme.mode].common.white};
      }
      svg {
        background: ${props => props.theme[props.theme.mode].primary.main};
        padding: 0.4rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin: 0 0.4rem;
      }
    }
  }
//   font-weight: bold;
//   box-shadow: 0 0 0 2px rgba(0, 0, 0, 1);
`;

const LeftSideMenuWrapper = props => {
  return (
    <StyledSider
    width={props.theme[props.theme.mode].spacing.sidebar}
    //collapsedWidth={0}
    //collapsed={props.state.collapsedLeft}
    //{...props}
  >
    <LeftSideBarMenu />
    
  </StyledSider>
  );
};

export default withTheme(LeftSideMenuWrapper);
