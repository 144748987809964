import React from "react";
import { Row, Col } from "antd";
import Button from "../../../components/button";
import { FiPlus } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ButtonIcon = styled.div`
  /* width: 100%; */
  & .ant-btn {
    /* width: 100%; */
    border: none;
    box-shadow: none;
    color: ${(props) => props.theme[props.theme.mode].textColor.primary};
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.links};
    display: flex;
    align-items: center;
  }
  & svg {
    color: ${(props) => props.theme[props.theme.mode].common.white};
    background: ${(props) => props.theme[props.theme.mode].primary.main};
    padding: 0.4rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    margin: 0 0.4rem;
  }
`;
const TransactionLinks = (props) => {
  console.log(props, "TransactionLinks");
  let history = useHistory();
  return (
    <Row
      gutter={[30, 20]}
      className="no-mr"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <Col span={12}>
        <ButtonIcon>
          <Button
            outlined
            onClick={() =>
              history.push({
                pathname: "/add-transaction",
                state: { bank: props.bank },
              })
            }
          >
            <FiPlus className="iconBtn" /> Add Transaction
          </Button>
        </ButtonIcon>
      </Col>
      <Col span={12}>
        <Button
          style={{ width: "100%" }}
          outlined
          onClick={() =>
            history.push({
              pathname: "/print-cheques",
              state: {
                id: props.bank,
                referrer: `/bank-account-details/${props.bank.bankId}`,
              },
            })
          }
        >
          Print Checks
        </Button>
      </Col>
    </Row>
  );
};

export default TransactionLinks;
