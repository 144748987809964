import React from "react";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
import TrialBalanceSummaryForm from "../forms/TrialBalanceSummary";

import { openModal, closeAllModal } from "../../../../actions/Modal"


const TrialBalanceSummary = props => (
  <ReportTypeCard
  initialValue={props.initialType || "pdf"}
  heading="Trial Balance Summary" 
  onSubmit={format => {
    props.openModal(
      null,
      <TrialBalanceSummaryForm initialValues={props.initialFormValues} format={format} />,
      null,
      null,
      {
        footer: null
      }
    );
  }} 
  />
)

export default connect(null, { openModal, closeAllModal })(TrialBalanceSummary);
