import React from "react";
import InputWrapper from "../../../components/input";
import { FiSearch } from "react-icons/fi";
import Image from "../../../components/image";
import filterImg from "../../../assets/icons/filter-3-fill.svg";

const SearchWithFilter = props => {
  console.log("SearchWithFilter", props);
  let valProp = props.value || props.value === "" ? { value: props.value } : {};
  return (
    <InputWrapper
      //style={{paddingLeft: "1.5rem"}}
      {...valProp}
      onChange={e => props.onChange(e.target.value)}
      className="no-mr"
      prefix={<FiSearch style={{ fontSize: "0.91rem" }} />}
      // suffix={
      //   <Image
      //     onClick={e => props.filterClick(e)}
      //     style={{ width: "1.087rem", cursor: "pointer", zIndex: 1 }}
      //     src={filterImg}
      //   />
      // }
      placeholder={props.istransact ? (props.placeholder || "Search Transactions") : (props.placeholder || "Search Bank Accounts")}
    />
  );
};

export default SearchWithFilter;
