import * as Yup from "yup";

export const ProfitAndLossSchema = Yup.object().shape({
  bank_account_id: Yup.string().when(
    'report_type',
    (report_type, schema) => (report_type === "detail" ? schema.required("Please provide your bank account name") : schema.nullable()),
  ),
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your bank account name"),
    //.nullable(),

  report_type: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide a report type")
  .nullable(),

  lawyer_id: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  //.required("Please provide your lawyer name"),
  .nullable(),

  job_id: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  //.required("Please provide your lawyer name"),
  .nullable(),

  date_type: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide a date type")
  .nullable(),

  month: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "monthly" ? schema.required("Please provide month") : schema),
  )
  .nullable(),


  year: Yup.string().when(
    'date_type',
    (date_type, schema) => ((date_type === "yearly" || date_type === "monthly") ? schema.required("Please provide year") : schema),
  )
  .nullable(),
});
