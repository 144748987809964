import * as Yup from "yup";

export const BankAccountLedgerSchema = Yup.object().shape({
  bank_account_id: Yup.string()
    // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
    // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your bank account name"),
    .nullable(),

  lawyer_id: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your lawyer name"),
    .nullable(),

    job_id: Yup.string()
    // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
    // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
    // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
    //.required("Please provide your lawyer name"),
    .nullable(),

  date_type: Yup.string()
  // .min(2, "lawyerName   is Too Short! it must be between 2 to 50 characters")
  // .max(50, "lawyerName   is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide a date type")
  .nullable(),

  month: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "monthly" ? schema.required("Please provide month") : schema),
  )
  .nullable(),


  year: Yup.string().when(
    'date_type',
    (date_type, schema) => ((date_type === "yearly" || date_type === "monthly" || date_type === "quaterly") ? schema.required("Please provide year") : schema),
  )
  .nullable(),


  start_day: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide starting date") : schema),
  )
  .nullable(),

  start_month: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide starting date") : schema),
  )
  .nullable(),

  start_year: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide starting date") : schema),
  )
  .nullable(),

  end_day: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide ending date") : schema),
  )
  .nullable(),

  end_month: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide ending date") : schema),
  )
  .nullable(),

  end_year: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" || date_type === "custom" ? schema.required("Please provide ending date") : schema),
  )
  .nullable(),


  quarter: Yup.string().when(
    'date_type',
    (date_type, schema) => (date_type === "quaterly" ? schema.required("Please provide quarter") : schema),
  )
  .nullable(),

  start_date: Yup.date().when(
    'date_type',
    (date_type, schema) => (date_type === "custom" ? schema.required("Please provide starting date") : schema),
  )
  .nullable(),

  end_date: Yup.date().when(
    ['date_type', 'start_date'],
    (date_type, start_date, schema) => (date_type === "custom" ? start_date ? schema.min(start_date, "Ending date must be after starting date").required("Please provide ending date") : schema.required("Please provide ending date") : schema),
  )
  .nullable(),
});
