import React from "react";
import { Avatar } from "antd";
import { FiUser } from 'react-icons/fi';
import Styled from 'styled-components';

const AvatarWrap = Styled(Avatar)`
  /* width: 2.5vw;
  height: 2.5vw; */
  max-width: 100%;
  max-height: 100%;

 & i.anticon {
   /* margin: 0; */
   font-size: 1rem;
 }
  /* @media screen and (max-width: 991px) {
    width: 32px;
    height: 32px;
  } */


`
const AvatarWrapper = props => {
  return <AvatarWrap {...props} />;
};

AvatarWrapper.defaultProps = {
  src: <FiUser />
}

export default AvatarWrapper;
