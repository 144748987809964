import React from "react";
import { Row, Col } from "antd";
import RadioGroup from "../../components/radio/RadioGroup";
import Radiobutton from "../../components/radio/RadioButton";
import styled, { css } from "styled-components";

export const RadioGroupStyles = css`
  & {
    width: 100%;
  }

  .ant-radio-button-wrapper {
    width: 100%;
    height: 100%;
    line-height: 1;
    padding: 1rem 1rem;
    font-weight: 600;
    ${(props) =>
      props.noBorder
        ? `box-shadow: 0 5px 20px ${
            props.theme[props.theme.mode].textColor.primary
          }0D;
            border: none;`
        : `border: 2px solid ${(props) =>
            props.theme[props.theme.mode].primary.main}`}
  }

  .ant-radio-button-wrapper-checked {
    color: ${(props) => props.theme[props.theme.mode].textColor.inverted};
    background-color: ${(props) => props.theme[props.theme.mode].primary.main};
    border: 2px solid ${(props) => props.theme[props.theme.mode].primary.main} !important;
  }

  .ant-radio-button-wrapper:hover {
    color: ${(props) => props.theme[props.theme.mode].primary.main};
  }

  .ant-radio-button-wrapper-checked:hover {
    color: ${(props) => props.theme[props.theme.mode].textColor.inverted};
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  ${RadioGroupStyles}
`;

const RadioGrid = (props) => {
  console.log("RadioGrid", props);
  return (
    <StyledRadioGroup
      onChange={props.onChange}
      value={props.value}
      noBorder={props.noBorder}
      {...props.attr}
    >
      <Row
        type={props.centered ? "flex" : undefined}
        justify={props.centered ? "center" : undefined}
        gutter={[10, 10]}
        className="textCenter"
      >
        {props.options
          ? props.options.map((val, index) => (
              <Col key={index} span={props.span || 12}>
                {/* <div className="textCenter"> */}
                  <Radiobutton
                    value={val.value}
                  >
                    {val.label}
                  </Radiobutton>
                {/* </div> */}
              </Col>
            ))
          : null}
      </Row>
    </StyledRadioGroup>
  );
};

export default RadioGrid;
