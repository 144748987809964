import {
  GET_STATEMENTS_REQUEST,
  GET_STATEMENTS_SUCCESS,
  GET_STATEMENTS_ERROR,
  DELETE_STATEMENTS_REQUEST,
  DELETE_STATEMENTS_SUCCESS,
  DELETE_STATEMENTS_ERROR,
  ADD_STATEMENTS_REQUEST,
  ADD_STATEMENTS_SUCCESS,
  ADD_STATEMENTS_ERROR
} from "../configurations/Types";

const INITIALS_STATE = {
  // for single bank trasaction
  
  // for All banks transaction
  statements: [],
  addStatementLoading: false,
};

export default (state = INITIALS_STATE, action) => {
  switch (action.type) {
    // ADD NEW BANK
    // case GET_STATEMENTS_REQUEST: {
    //   return Object.assign({}, state, { loading: true });
    // }
    // case GET_STATEMENTS_SUCCESS: {
    //   return Object.assign({}, state, { loading: false });
    // }
    // case GET_STATEMENTS_ERROR: {
    //   return Object.assign({}, state, { loading: false });
    // }

    // GET ALL BANKS
    case GET_STATEMENTS_REQUEST: {
      return Object.assign({}, state, {
        statements: action.flag ? [] : state.statements,
        addStatementLoading: true
        // bankLists:
        //   state.bankLists && action.flag
        //     ? { ...state.bankLists, bank_accounts: [], total_count: 0 }
        //     : state.bankLists
      });
    }
    case GET_STATEMENTS_SUCCESS: {
      let statements = [];
      console.log("THis is success, ", action);
      if (action.body) {
        statements = action.body.statements;
        console.log("THis is success state, ", statements);
        //console.log("REDUCER SUCCESS", action.payload, total_balance);

        // totalBalance = total_balance;
        // totalCleared = total_cleared_balance;
        // newCurrency = currency;
        // totalCount = total_count;
        // bankAccounts = [...state.bankAccounts, ...bank_accounts];
      }
      console.log("THis is success state, ", statements);
      return Object.assign({}, state, {
        // allLoading: false,
        // // bankLists: action.payload,

        // bankStatsLoading: false,
        // bankAccountsLoading: false,
        statements,
        addStatementLoading: false
        // totalBalance,
        // totalCleared,
        // currency: newCurrency,
        // totalCount,
        // bankAccounts

        // bankLists: state.bankLists
        //   ? {
        //       ...state.bankLists,
        //       bank_accounts: [
        //         ...state.bankLists.bank_accounts,
        //         ...action.payload.bank_accounts
        //       ]
        //     }
        //   : action.payload
      });
    }
    case GET_STATEMENTS_ERROR: {
      return Object.assign({}, state, {
        addStatementLoading: false
      });
    }

    default: {
      return state;
    }
  }
};
