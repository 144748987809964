import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../../components/input/index";
import RootForm from "../../../components/form/index";
import { jobSchema } from "../../../configurations/Schemas/index";
import CardWrapper from "../../../components/card";
import HeaderWrapper from "../../../components/header/index";
import { Row, Col } from "antd";

import { addJobsRequest } from "../../../actions/FieldOptions";
import { closeAllModal } from "../../../actions/Modal";

class Job extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job_name: ""
    };
  }

  handleChanged = e => {
    const { name, value } = e.target;
    console.log("value", value);
    this.setState({
      [name]: value
    });
  };

  render() {
    console.log("storeState", this.props);
    return (
      <Row type="flex" justify="center" align="middle">
        <Col xs={24} sm={20} md={22} lg={18}>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="textCenter"
          >
            <Col xs={24} sm={20} md={22} lg={24}>
              <h4 className="mr-b mr-t-md">Add new job</h4>
              <RootForm
                schemaName={jobSchema}
                initialValues={this.state}
                apiCall={addJobsRequest}
                loading={this.props.Jobs.jobLoading}
                resetAfterSubmit
                cb={this.props.closeAllModal}
                //   cb={() =>
                //     this.props.history.push(
                //       this.props.location.state
                //         ? this.props.location.state.referrer
                //         : "/"
                //     )
                //   }
                controls={[
                  {
                    span: 24,
                    name: "job_name",
                    value: this.state.job_name,
                    handleChanged: this.handleChanged,
                    placeholder: "Job Name",
                    Type: Input
                  }
                ]}
                submitButton={{
                  span: 12,
                  buttonText: "Save"
                  // className: "mr-t-md"
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

// export default withRouter(
//   connect(
//     storeState => {
//       Job: storeState.FieldOption;
//     },
//     { addJobsRequest }
//   )
// );

export default withRouter(
  connect(storeState => ({ Jobs: storeState.FieldOption }), {
    addJobsRequest,
    closeAllModal
  })(Job)
);
