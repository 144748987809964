import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PopconfirmWrapper from "../../components/pop-confirm";
import ButtonWrapper from "../../components/button";
// import { MdDelete } from "react-icons/md";
import TableWrapper from "../../components/react-table";
import TagWrapper from "../../components/tag";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  getUserContactsRequset,
  deleteContactRequest,
} from "../../actions/Contact";
import SearchWithFilter from "../../appcomponents/general/ContentHeaderComponents/SearchWithFilter";
import Select from "../../components/input/Select";
import Options from "../../components/input/SelectOptions";
import { FiEdit, FiMoreHorizontal, FiAlertTriangle } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Divider } from "antd";
import PopOver from "../../components/popover";
import useScreenWidth from "../../components/hooks/useScreenWidth";

let map = {
  LAWYER: "fourth",
  VENDOR: "fifth",
  CUSTOMER: "sixth",
  PERSONAL: "seventh",
};

const ContactDetails = (props) => {
  const size = useScreenWidth();
  const [tableChanged, setTableChanged] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    contact_type: props.location.state
      ? props.location.state.contact_type
      : undefined,
    like: props.location.state ? props.location.state.like : "",
  });
  const [state] = useState({
    apiCall: (...params) => props.getUserContactsRequset(...params),
  });
  const handleContactType = (e) => {
    setFilters({ ...filters, contact_type: e });
  };

  const searchChange = (e) => {
    // console.log("serachChange", e);
    setSearch(e);
    setFilters({ ...filters, like: e });
  };

  console.log("ContactDetailsProps", props.contactsData);
  return (
    <Row>
      <Col span={24}>
        <Row type="flex" justify="start" align="middle" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={6} lg={5} xl={5}>
            <SearchWithFilter
              onChange={(e) => searchChange(e)}
              placeholder="Search Contacts"
              value={filters.like}
            />
          </Col>

          <Col xs={24} sm={24} md={6} lg={5} xl={5}>
            <Select
              value={filters.contact_type}
              style={{ width: "100%", boxShadow: "none" }}
              onChange={(e) => handleContactType(e)}
              placeholder="Contact Type"
            >
              <Options value="">View All</Options>
              <Options value="lawyer">Lawyer</Options>
              <Options value="personal">Personal</Options>
              <Options value="vendor">Vendor</Options>
              <Options value="customer">Customer</Options>
              <Options value="other">Other</Options>
            </Select>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={12}
            lg={14}
            xl={14}
            type="flex"
            justify="center"
          >
            <Row
              type="flex"
              justify={size >= 991 ? "end" : "center"}
              align="middle"
            >
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <ButtonWrapper
                  style={{ minWidth: "12rem", width: "100%" }}
                  onClick={() =>
                    props.history.push({
                      pathname: `/add-contact`,
                      state: {
                        referrer: "/view-contact",
                        referrerState: {
                          contact_type: filters.contact_type,
                        },
                      },
                    })
                  }
                >
                  Add Contact
                </ButtonWrapper>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Col span={12}>
          <Row type="flex" justify="end" align="middle"> */}

        {/* </Row>
        </Col> */}
      </Col>

      <Col span={24} style={{ margin: "40px 0px 50px 0px" }}>
        <TableWrapper
          tableData={props.contactsData.userContacts}
          getData={state.apiCall}
          search={search}
          filters={filters}
          pageSize={10}
          dataCount={parseInt(props.contactsData.count)}
          getTrProps={(state, rowInfo, column) => ({
            onClick: () =>
              props.history.push({
                pathname: `/edit-contact/${rowInfo.original.contact_id}`,
                state: {
                  referrer: "/view-contact",
                  contactDetails: rowInfo.original,
                  referrerState: {
                    contact_type: filters.contact_type,
                    like: filters.like,
                  },
                },
              }),
            style: { cursor: "pointer" },
          })}
          // loading={props.transactionsData.tableLoading}
          //enableReInit={true}
          tableChanged={tableChanged}
          content={[
            {
              name: "Full Name",
              id: "1",

              render: (r) => (
                <span>
                  {r.original.full_name !== "" ? r.original.full_name : "-"}
                </span>
              ),
            },
            {
              name: "Company Name",
              id: "2",
              render: (r) => (
                console.log("r.original check kro", r.original),
                (
                  <span>
                    {r.original.company_name !== ""
                      ? r.original.company_name
                      : "-"}
                  </span>
                )
              ),
            },
            {
              name: "Phone",
              id: "3",
              render: (r) => (
                console.log("r.original check kro", r.original),
                (
                  <span>
                    {r.original.phone !== "" ? r.original.phone : "-"}
                  </span>
                )
              ),
            },
            {
              name: "Email",
              id: "4",
              render: (r) => (
                <span>{r.original.email !== "" ? r.original.email : "-"}</span>
              ),
            },

            {
              name: "Address",
              id: "5",
              render: (r) => (
                <span>
                  {r.original.address !== "" ? r.original.address : "-"}
                </span>
              ),
            },

            {
              name: "Type",
              id: "6",
              large: true,
              render: (r) => (
                <React.Fragment>
                  <TagWrapper
                    style={{ width: "100%", textTransform: "capitalize" }}
                    className="small"
                    type={map[r.original.contact_type.toUpperCase()]}
                  >
                    {r.original.contact_type !== ""
                      ? r.original.contact_type
                      : "-"}
                  </TagWrapper>
                  <span style={{ marginLeft: "1rem" }}>
                    <PopOver
                      // placement="left"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => e.stopPropagation()}
                      content={
                        <div onClick={(e) => e.stopPropagation()}>
                          <div
                            style={{ padding: "8px" }}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() =>
                              props.history.push({
                                pathname: `/edit-contact/${r.original.contact_id}`,
                                state: {
                                  referrer: "/view-contact",
                                  contactDetails: r.original,
                                  referrerState: {
                                    contact_type: filters.contact_type,
                                    like: filters.like,
                                  },
                                },
                              })
                            }
                          >
                            <FiEdit style={{ marginRight: 5 }} />
                            <span>Edit</span>
                          </div>

                          <Divider style={{ margin: 0 }} />

                          {/* poprConfirm */}

                          <PopconfirmWrapper
                            onClick={(e) => e.stopPropagation()}
                            title={
                              <h4>
                                Are you sure you want to delete this contact?
                              </h4>
                            }
                            icon={
                              <FiAlertTriangle
                                style={{
                                  position: "absolute",
                                  color: "#f44336",
                                  left: 0,
                                  top: 0,
                                  marginTop: 6,
                                }}
                              />
                            }
                            cancelText="No"
                            okText="Yes"
                            onConfirm={() =>
                              props.deleteContactRequest(
                                r.original.contact_id,
                                () => setTableChanged(!tableChanged)
                              )
                            }
                          >
                            {/* <ButtonWrapper className="deleteBtn"> */}
                            <div
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <AiOutlineDelete
                                className="deleteIcon"
                                style={{ color: "#FF494C", marginRight: 5 }}
                              />
                              <span>Delete</span>
                            </div>
                            {/* </ButtonWrapper> */}
                          </PopconfirmWrapper>
                        </div>
                      }
                    >
                      <FiMoreHorizontal style={{ color: "#154D94" }} />
                    </PopOver>
                  </span>
                </React.Fragment>
              ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};
export default withRouter(
  connect(
    (storeState) => ({
      contactsData: storeState.Contact,
    }),
    {
      getUserContactsRequset,
      deleteContactRequest,
    }
  )(ContactDetails)
);
