import React from "react";
import { connect } from "react-redux";

import { Row, Col } from "antd";
import { emailValidations } from "../../configurations/Schemas/index";
import AuthContainer from "../../appcomponents/shares/auth/AuthContainer";
import BackButton from "../../appcomponents/shares/header/BackButton";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { Link } from "react-router-dom";
import HeaderWrapper from "../../components/header/index";
import { passwordResetRequest } from "../../actions/Auth";
import { FiArrowLeft, FiArrowUp } from "react-icons/fi";
import "./auth.css";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };
  changeEmail = value => {
    
    this.setState({ email: value });
  };
  render() {
    //let BackButton = this.props.linked ? FiArrowLeft : FiArrowLeft;
    return (
      <div>
        <Row type="flex" className="setPosition">
        {/* <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col> */}
          <Col span={24} style={{ display: "flex", alignItems: "center" }}>
            <BackButton
             title="Reset Your Password"
              action={() =>
                this.props.history.push(
                  this.props.backButtonLink
                    ? this.props.backButtonLink
                    : "/login"
                )
              }
              // className="appColor backBtn"
            />{" "}
            {/* <span className="fontSemiBold">Reset Your Password</span> */}
          </Col>
        </Row>

        <AuthContainer>
          <Row type="flex" justify="center" align="middle">
            <Col xs={24} sm={20} md={22} lg={12} xl={11}>
              <Row type="flex" justify="center">
                <Col xs={24} sm={20} md={18} lg={20} >
                  <h4 className="mr-b mr-t-md">
                    Enter your email so we can send a 6 digit code
                  </h4>
                </Col>

                <Col xs={24} sm={20} md={22} lg={24} xl={24}>
                  <RootForm
                    schemaName={emailValidations}
                    initialValues={this.state}
                    changeEmail={this.changeEmail}
                    apiCall={passwordResetRequest}
                    loading={this.props.Auth.loading}
                    cb={() => {
                      this.props.history.push({
                        pathname: "/verify-code",
                        state: {
                          email: this.state.email
                        }
                      });
                    }}
                    controls={[
                      {
                        span: 24,
                        name: "email",
                        value: this.state.email,
                        handleChanged: this.handleChanged,
                        placeholder: "Email",
                        Type: Input
                      }
                    ]}
                    submitButton={{
                      span: 16,
                      buttonText: "Continue",
                      className: "mr-t-md mr-b-lg",
                      align: "center"
                      //onClick :()=>this.props.history.push('/verify-code')
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </AuthContainer>
      </div>
    );
  }
}

export default connect(storeState => ({ Auth: storeState.Auth }))(
  ForgotPassword
);
