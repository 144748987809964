import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Form from "../../../components/form";
import Input from "../../../components/input";
import { addAccount } from "../../../configurations/Schemas";
import { addAccountRequest, editAccountRequest } from "../../../actions/FieldOptions";
import { closeAllModal } from "../../../actions/Modal";

const AddAccountName = props => {
  const [state, setState] = useState({
    account_name: props.initialValues ? props.initialValues.name : "",
    account_type: props.accType,
    user_type: "user"
    //edit: props.initialValues
  });
  const handleChanged = e => {
    const { name, value } = e.target;
    setState({
      [name]: value
    });
  };
  return (
    <Row type="flex" justify="center" align="middle">
      <Col xs={24} sm={20} md={22} lg={18}>
        <Row type="flex" justify="center" align="middle" className="textCenter">
          <Col xs={24} sm={20} md={22} lg={24}>
            <h4 className="mr-b mr-t-md">{props.initialValues ? "Edit account" : "Add new account"}</h4>
            <Form
              schemaName={addAccount}
              initialValues={state}
              apiCall={props.initialValues ? props.editAccountRequest : props.addAccountRequest}
              loading={props.initialValues ? props.FieldOption.editAccNameLoading : props.FieldOption.addAccNameLoading}
              resetAfterSubmit
              cb={props.closeAllModal}
              extras={props.initialValues ? props.initialValues.id : null}
              controls={[
                {
                  span: 24,
                  name: "account_name",
                  value: state.account_name,
                  handleChanged: handleChanged,
                  placeholder: "Account Name",
                  Type: Input
                }
              ]}
              submitButton={{
                span: 12,
                buttonText: "Save",
                //className: "mr-t-md mr-b-lg",
                align: "center"
                //onClick :()=>this.props.history.push('/verify-code')
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
const mapStateToProps = storeState => ({
  FieldOption: storeState.FieldOption
});
export default connect(mapStateToProps, { addAccountRequest, editAccountRequest, closeAllModal })(AddAccountName);
