import React from "react";
import styled, { css } from "styled-components";
import { Select } from "antd";

const InputStyles = css`
//margin-bottom: 2.5%;
.ant-select-selection {
  color: ${props => props.theme[props.theme.mode].textColor.primary};
  background-color: ${props =>
    props.theme[props.theme.mode].background.primary};
  border: 1px solid #58ba2766;
  ${props =>
    props.noBorder
      ? `
      border: none;
    box-shadow: 0 0.3rem 1rem
      ${props.theme[props.theme.mode].textColor.hint}0C;
    border-radius: 0.25rem;
        padding: calc(0.7vw
           + 1px
           ) 0.8vw calc(0.7vw
             + 1px
             ) 0.8vw;`
      : `
      //box-shadow: 0 0 0 0.15rem inset ${props.theme[props.theme.mode].primary}; 
      //box-shadow: none;
        border-radius: 5px; 
        padding: calc(0.7vw) 0.8vw;`}
  height: auto;
  /* border-bottom: 2px solid ${props =>
    props.theme[props.theme.mode].primary.main}66; */
}
.ant-select-selection:focus, .ant-select-selection:hover, .ant-select-selection:active {
  ${props =>
    props.noBorder
      ? 
      //`box-shadow: none; border-bottom: 0.15rem solid ${
          //props.theme[props.theme.mode].secondary.main
        //};`
        ``
      : `border-color: ${props.theme[props.theme.mode].primary.main} !important;
      //box-shadow: 0 0 0 2px ${props.theme[props.theme.mode].primary.main}66;
      //box-shadow: none;
      `
      //`box-shadow: 0 0 0 0.15rem inset ${
          //props.theme[props.theme.mode].secondary.main};`
        }
  
}

&.ant-select-open .ant-select-selection {
  ${props =>
    props.noBorder
      ? 
      //`box-shadow: none; border-bottom: 0.15rem solid ${
          //props.theme[props.theme.mode].secondary.main
        //};`
        ``
      : `
        border-color: ${props.theme[props.theme.mode].primary.main} !important;
        box-shadow: 0 0 0 2px ${props.theme[props.theme.mode].primary.main}66;
      `
      //`box-shadow: 0 0 0 0.15rem inset ${
          //props.theme[props.theme.mode].secondary.main};`
        }
}
//${props => (props.appStyles ? props.appStyles[1] : null)};
.ant-select-selection__rendered {
  margin-left: 0;
  height: 100%;
  display: flex;
  align-items: center;
}
.ant-select-selection-selected-value .hideMe {
  display: none;
}
.ant-select-selection-selected-value .expandMe {
  width: 100%;
}
.ant-select-arrow {
  margin-top: 0;
  color: ${props => props.theme[props.theme.mode].primary.main};
  svg {
    transform: translateY(-50%);
  }}
.ant-select-dropdown-menu {
  background-color: ${props =>
  props.theme[props.theme.mode].background.primary};
  padding: 0;
}
.ant-select-dropdown-menu-item {
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.captions};
  display: flex;
  align-items: center;
  color: ${props => props.theme[props.theme.mode].textColor.primary};

  font-weight: 500;
}
& .loadingOption.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled {
  cursor: progress;
}
& .ant-empty {
  margin: auto;
}
& li {
  white-space: normal;
  word-break: break-word;
}
& li:not(:last-child) {
  border-bottom: 1px solid ${props => props.theme[props.theme.mode].border};
}
@media screen and (max-width: 991px) {
  .ant-select-dropdown-menu-item {
    font-size: calc(${props =>
      props.theme[props.theme.mode].textSizes.relative.captions} - 4px);
  }
  ${props =>
    props.noBorder
      ? 
      `
      //padding: calc(0.7vw + 2px) 0;
      & .ant-select-selection {
        padding: calc(.5rem + 1px);
      }
      `
      : `
      && .ant-select-selection {
        padding: .5rem;
      }`
      //`padding: calc(0.7vw + 2px) 0.8vw;`
    }
}
.ant-select-dropdown-menu-item:hover, .ant-select-dropdown-menu-item:hover,
 .ant-select-dropdown-menu-item-active {
  background-color: ${props => props.theme[props.theme.mode].primary.light};
}
@media screen and (max-width: 767px){
  .ant-select-dropdown-menu-item {
    font-size: ${props => props.theme[props.theme.mode].textSizes.actual.body};
  }
}
`;

const SelectWrapper = props => {
  return (
    <Select
      className={props.className}
      dropdownClassName={props.className}
      {...props}
    >
      {props.children}
    </Select>
  );
};

export default styled(SelectWrapper).attrs({ className: "selectField" })`
  ${InputStyles}
`;
