import React from "react";
import Logo from "../assets/images/Logo-Escrow-Trakker.svg";
import MaleAvatar from "../assets/images/maleavatar.png";
import FemaleAvatar from "../assets/images/femaleavatar.svg";
import Image from "../components/image/index";
// const HeaderLogo = () => {
//   return <img src={Logo} style={{ width: "128px", height: "64px" }}></img>;
// };

//For Now Both logo are same it may designer give fullLogo sepeartly
const HeaderLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};
const fullHeaderLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};
const fullFooterLogo = () => {
  return <Image src={Logo} alt="EscrowTrakker" />;
};

const numWithCommas = num =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 const AccountingFormat = (amount, currency = "") => {
   console.log("AMOUNT IS: ", amount);
   console.log("RESULT IS: ", `${currency}(${numWithCommas(
    Number(Math.abs(amount))
      .toFixed(2)
      .replace("-", ""))})`);
  if (amount < 0)
    return `${currency}(${numWithCommas(
      Number(Math.abs(amount))
        .toFixed(2)
        .replace("-", "")
    )})`;
  else
    return `${currency}${numWithCommas(
      Number(amount)
        .toFixed(2)
        .replace("-", "")
    )}`;
};

const menuShow = {
  rightMenu: false,
  leftMenu: true
};
const avatarIcons = {
  male: MaleAvatar,
  female: FemaleAvatar
};
export {
  HeaderLogo,
  menuShow,
  avatarIcons,
  fullHeaderLogo,
  fullFooterLogo,
  AccountingFormat,
};
