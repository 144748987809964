import React from "react";
import Button from "../../../components/button";
import styled from "styled-components";
import { FiEdit3 } from "react-icons/fi";

const IconButtonWrap = styled.div`
    & .ant-btn {
    border: none;
    box-shadow: none;
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.links};
    display: flex;
    align-items: center;
    min-width: auto;
  }
  & svg {
    color: ${props => props.theme[props.theme.mode].common.white};
    background: ${props => props.theme[props.theme.mode].primary.main};
    padding: 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 20%;
    margin: 0 0.4rem;
  }
`;

const EditButton = props => {
    return (
        <IconButtonWrap>
            <Button  outlined {...props}><FiEdit3 />{props.children}</Button>
        </IconButtonWrap>
    )
};

export default EditButton
