import React from "react";
import { Menu } from "antd";
import styled, { css } from "styled-components";

const StyledMenu = styled(Menu)`
  display: flex;
  background: ${props => props.theme[props.theme.mode].background.primary};
  border: none;
  font-weight: 600;
  color: ${props => props.theme[props.theme.mode].textColor.primary};
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
  letter-spacing: 0.021vw;
  &.sidebarMenu {
    background: ${props => props.theme[props.theme.mode].background.secondary};
    color:  ${props => props.theme[props.theme.mode].common.white};
    a, li {
      color:  ${props => props.theme[props.theme.mode].common.white};
    }
  }
  &.notificationsMenu {
    width: 30vw;
  }
  &.notificationsMenu .ant-row-flex {
    flex-wrap: nowrap;
  }
  .ant-menu-item-divider {
    margin: 0;
  }
  & svg {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.pageHeading};
    margin: 0 0.4rem;
  }
  & .navLinks {
    box-sizing: content-box;
    width: 100%;
  }
  & a, & li {
    color:  ${props => props.theme[props.theme.mode].textColor.primary};
    z-index: 100;
    vertical-align: middle;
    line-height: 36px;
  }
  & .ant-menu-item a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0 2vw;
    font-weight: 600;
  }
  & .ant-menu-item .font500 {
    font-weight: 500;
  }
  & .ant-menu-item.ant-menu-item-selected a {
    font-weight: 600;
  }
  & .ant-menu-item.landingNav a {
    padding: 0 1.2vw;
  }
  & .ant-menu-item.sideBarItem a {
    padding: 0;
  }
  & .ant-menu-item {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
    top: 0 !important;
    border: none;
    border-radius: 0;
    padding: 0;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.notificationsMenu .ant-menu-item {
    line-height: 12px;
    border-radius: 0;
    margin: 0;
    white-space: normal;
    padding: 0.6vw;
    height: unset;
    display: block;
  }

  & .ant-menu-item.sideBarItem {
    margin: 1rem 0;
    height: 2.5rem;
    line-height: normal;
  }
  & .ant-menu-item.sideBarItem a img {
    width: 1.5rem;
    
  }
  & .ant-menu-item:hover {
    border: none;
    background-color: ${props => props.theme[props.theme.mode].primary.main} !important;
    color: initial;
  }
  .ant-menu-item-selected {
    color: ${props => props.theme[props.theme.mode].primary.main} !important;
    background-color: ${props => props.theme[props.theme.mode].primary.main};
  }
  .ant-menu-item-selected a {
    color: ${props => props.theme[props.theme.mode].common.white} !important;
    :after {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${props => props.theme[props.theme.mode].common.white};
      border-radius: 50%;
      position: absolute;
      right: 0.8rem;
    }
  }
  .ant-menu-item:not(.ant-menu-item-selected) a:hover {
    color: ${props => props.theme[props.theme.mode].common.white} !important;
  }
  
  ${props => props.mode === "horizontal" ? null : css`
    & {
      display: block;
    }
    & .ant-menu-item-selected {
      background-color: ${props => props.theme[props.theme.mode].primary.main} !important;
      color: ${props => props.theme[props.theme.mode].textColor.primary};
    }
    ${props => props.theme.mode === "light" ? null : css`
      .ant-menu-item:active {
        background-color: ${props => props.theme[props.theme.mode].primary.light};
      }`
    }
    & .ant-menu-item {
      margin-right: 0;
    }`
  }

  ${props => props.align === "right" ? css`
    justify-content: flex-end;
  `: null}
  ${props => props.align === "center" ? css`
    justify-content: center;
  `: null}
  ${props => props.align === "left" ? css`
    justify-content: flex-start;
  `: null}
  ${props => props.background === "transparent" ? css`
  & .ant-menu-item-selected, & .ant-menu-item, & .ant-menu-item:hover, & .ant-menu-item:focus,
  & .ant-menu-item:active {
    background-color: transparent !important;
  }
    
  `: null}
  @media screen and (max-width: 991px) {
    font-size: calc(${props => props.theme[props.theme.mode].textSizes.relative.links} - 4px);
    letter-spacing: 0.45px;
    & svg {
      font-size: calc(${props => props.theme[props.theme.mode].textSizes.relative.pageHeading} - 4px);
    }
    .ant-menu-item.sideBarItem a {
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .ant-menu-item-selected a {
      :after {
        width: 0.3rem;
        height: 0.3rem;
        right: 0;
        left: 0;
        margin: auto;
        top: 0.2rem;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size:${props => props.theme[props.theme.mode].textSizes.actual.links};
    & svg {
    font-size: ${props => props.theme[props.theme.mode].textSizes.actual.pageHeading};
  }
  }

  .flexFill {
    flex-grow: 1;
  }
  .zeroLineHeight{
    line-height: 0;
  }

  .small {
    font-size: ${props => props.theme[props.theme.mode].textSizes.relative.links};
    line-height: 1.46;
    letter-spacing: 0.0185vw;
  }
`;

const MenuWrapper = props => {
  return (
    <StyledMenu {...props}></StyledMenu>
  );
};

export default MenuWrapper;