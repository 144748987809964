import React from "react";
import { Row, Col } from "antd";
import Select from "../../../components/input/Select";
import Option from "../../../components/input/SelectOptions";
import styled from "styled-components";

const SelectWrapper = styled(Select)`
  width: 100%;
  & .ant-select-selection,
  & .ant-select-selection:hover,
  & .ant-select-selection:active,
  & .ant-select-selection:focus {
    /* border: none;
    box-shadow: 0 0.3rem 1rem
      ${props => props.theme[props.theme.mode].textColor.hint}0C;
    border-radius: 0.25rem; */
  }
`;
const frequencies = [
  { key: "", value: "All" },
  { key: 1, value: "Daily" },
  { key: 7, value: "Weekly" },
  { key: 14, value: "Every 2 Weeks" },
  { key: 28, value: "Every 4 Weeks" },
  { key: 15, value: "Semi-Monthly" },
  { key: 30, value: "Monthly" },
  { key: 60, value: "Every 2 Months" },
  { key: 90, value: "Every 3 Months" },
  { key: 180, value: "Every 6 Months" },
  { key: 365, value: "Every Year" }
 
];


const FilterRecurring = props => {

  return (
    // <Row gutter={[30, 20]} className="no-mr" type="flex" justify="space-between" align="middle">
    //   <Col span={10}>
        <SelectWrapper noBorder defaultValue={frequencies[0].key} {...props.frequencies} {...props}>
          {frequencies.map(val => (
            <Option key={val.key ? val.key : 0} value={val.key}>{val.value}</Option>
          ))}
        </SelectWrapper>
    //   </Col>
    //   <Col span={10}>
        
    //   </Col>
    // </Row>
  );
};

export default FilterRecurring;
