import React from 'react';
import { connect } from "react-redux";
import { injectStripe } from 'react-stripe-elements';
import info from "../../../components/message";
import Input from "../../../components/input/index";
import CardNumberWrapper from "../../../components/stripeelements/CardNumberWrapper";
import CardExpiryWrapper from "../../../components/stripeelements/CardExpiryWrapper";
import CardCVCWrapper from '../../../components/stripeelements/CardCVCWrapper';
import RootForm from "../../../components/form/index";
import { Link } from "react-router-dom";
import AuthContainer from "../../../appcomponents/shares/auth/AuthContainer";
import { addCardValidations } from "../../../configurations/Schemas/index";
import { Row, Col } from 'antd';
import { registerRequest } from "../../../actions/Auth";
import { publicAgent } from "../../../configurations/AxiosAgent"

class AddCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      cardNumber: false,
      cardExpiry: false,
      cardCvc: false,
      errors: {
        
      }
    };
  }
  componentDidMount(){
    setTimeout(() => {
      if(!this.props.stripe) {
        info("error", "The Stripe payment processing library has failed to load, try refreshing the page.");
      }
    }, 2000);
  }
  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleStripeChange = (e, formikChange) => {
    let name = e.elementType;
    if(e.error)
      this.setState({ errors: { ...this.state.errors, [name]: e.error.message }  })
    else if(e.empty)
      this.setState({ errors: { ...this.state.errors, [name]: "Card info required" }  })
    else {
      formikChange({ target: { name, value: true } });
      let { [name]: errName, ...rest } = this.state.errors;
      this.setState({ errors: { ...rest }  });
    }
  }

  stripeNumWrapper = (props) => <CardNumberWrapper {...props} value="" onChange={(e) => this.handleStripeChange(e, props.onChange)} />
  stripeExpiryWrapper = (props) => <CardExpiryWrapper {...props} value="" onChange={(e) => this.handleStripeChange(e, props.onChange)} />
  stripeCVCWrapper = (props) => <CardCVCWrapper {...props} value="" onChange={(e) => this.handleStripeChange(e, props.onChange)} />

  render() {

    return (
      <AuthContainer 
      heading="Let's Create An Account" 
      footer={<p>Already have an account? <Link to="/login">Sign In</Link></p>}
      steps={this.props.steps}
      backButtonLink="/main"
      >
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <p className="mr-b-md fontLight hintColor">
              ADD CREDIT/DEBIT CARD INFORMATION
            </p>
          </Col>
          {/* <Input id="DummyInput" style={{ display: "none" }} /> */}
          {/* <Col span={24}>
            <form>
            <label>
            <CardNumberElement />
            </label>
            </form>
          </Col> */}
          <Col xs={24} sm={20}>
            <RootForm
              schemaName={addCardValidations}
              initialValues={this.state}
              apiCall={registerRequest}
              extras={this.state.ltln}
              cb={this.props.cb}
              customSubmit={async (values) => {
                try {
                  let tokenRes = await this.props.stripe.createToken({type: 'card', name: values.name});
                  //let res = await publicAgent.post("/add-card", { token: tokenRes.token, id: 72, email: "lightscribe93@gmail.com" })
                  //console.log("RESULT", values, tokenRes, res);
                }
                catch (e) {

                }
              }}
              //enableReinitialize
              // changeEmail={this.changeEmail}
              autoWidthSubmit
              loading={this.props.Auth.loading}
              customErrors={this.state.errors}
              //cb={() => {this.props.history.push("/")}}
              controls={[
                {
                  span: 24,
                  name: "name",
                  value: this.state.email,
                  handleChanged: this.handleChanged,
                  placeholder: "Card Holder Name",
                  Type: Input,
                },
                {
                  span: 24,
                  name: "cardNumber",
                  //value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "Card Number",
                  Type: this.stripeNumWrapper,
                },
                {
                  span: 24,
                  name: "cardExpiry",
                  //value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "Expiry Date",
                  Type: this.stripeExpiryWrapper,
                },
                {
                  span: 24,
                  name: "cardCvc",
                  //value: this.state.password,
                  handleChanged: this.handleChanged,
                  placeholder: "CVC",
                  Type: this.stripeCVCWrapper,
                },
              ]}
              // isLink={{
              //   span: 14,
              //   status: "true",
              //   linktext: "Forgot Password?",
              //   url: "forgot-password",
              //   className: 'fontMedium link-color mr-b-md mr-t textLeft',
              //   align: 'start',
              // }}
              submitButton={{
                span: 24,
                buttonText: "Next",
                className: 'mr-t-md mr-b-md',
              }}
            />
          </Col>
          <Col className="mr-b-lg" span={24}>
            <Link to="/login">Skip for now</Link>
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default injectStripe(connect(storeState => ({ Auth: storeState.Auth }))(AddCardForm));