import React from "react";
import { withRouter } from "react-router-dom";
import { Menu } from "antd";
import { fullHeaderLogo } from "../../configurations/Config";
import { connect } from "react-redux";
import { Row, Col } from "antd";
// import { protocol_head } from "../../configurations/Constants";
import { FiUser, FiLogOut } from "react-icons/fi";
//"../../assets/images/Ovunder-Avatar.png";
import DropdownWrapper from "../dropdown";
import MenuWrapper from "../menu";
import Badge from "../badge/index";
import Avatar from "../avatar/index";
import Icon from "../Icon/index";
import { logout } from "../../utils/index";

class HeaderContent extends React.Component {
  state = {
    userData: { email: "", popoverOpen: false }
  };
  componentWillMount() {
    if (localStorage.getItem("escrowauth")) {
      let userData = JSON.parse(window.localStorage.getItem("escrowauth"));
      this.setState({ userData: userData });
    }
  }

  messages = () => {
    return (
      <React.Fragment>
        <p>Messages Loading...</p>
      </React.Fragment>
    );
  };
  render() {
    return (
      <Row
        style={{ height: "100%" }}
        type="flex"
        align="middle"
        justify="space-between"
      >
        <Col xs={8} md={5} style={{display:"flex",alignItems:"center"}}>{fullHeaderLogo()}</Col>
        <Col xs={12} md={4} >
          <Row
            className="zeroLineHeight"
            type="flex"
            align="middle"
            justify="end"
            gutter={24}
          >
            <DropdownWrapper
              fixed
              className="profileMenu"
              style={{justifyContent: "center",
                paddingRight: "1rem"}}
              overlay={
                <MenuWrapper>
                  <Menu.Item
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/update-profile",
                        state: {
                          externalscreen: true
                        }
                      })
                    }
                  >
                    <Row type="flex" align="middle">
                      <Col className="zeroLineHeight">
                        <FiUser />
                      </Col>
                      <Col className="flexFill fontMedium">
                        Profile
                      </Col>
                    </Row>
                  </Menu.Item>

                  <Menu.Divider />

                  <Menu.Item onClick={logout}>
                    <Row type="flex" align="middle">
                      <Col className="zeroLineHeight">
                        <FiLogOut
                        //className="logout"
                        />
                      </Col>
                      <Col className="flexFill fontMedium">
                        Logout
                      </Col>
                    </Row>
                  </Menu.Item>
                </MenuWrapper>
              }
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "baseline"
                }}
                title={this.state.userData.full_name}
              >
                <Col
                  xs={22}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Badge style={{ color: "white" }}>
                    <Avatar
                      onClick={() => this.props.history.push("/update-profile")}
                      shape="square"
                      size="small"
                      icon="user"
                      style={{
                        backgroundColor: "#58BA27",
                        color: "white",
                        cursor: "pointer",
                        width: "1.9025rem",
                        height: "1.9025rem"
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                  </Badge>
                  <p
                    style={{
                      display: "inline",
                      fontWeight: 600,
                      textTransform: "capitalize",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "10vw",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {
                      // this.state.userData.full_name.length >= 8
                      // ? this.state.userData.full_name.slice(0, 8) + "..."
                      // : 
                      this.state.userData.full_name}
                  </p>
                </Col>

                <Col xs={3} md={4} className="textRight">
                  <Icon style={{ fontSize: ".8rem" }} type="down" />
                </Col>
              </div>
            </DropdownWrapper>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect(storeState => ({ Auth: storeState.Auth, App: storeState.App }))(
    HeaderContent
  )
);
