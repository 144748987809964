import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import Tabs from "../../../components/tabs";
import HeaderWrapper from "../../../components/header/index";
import { Row, Col } from "antd";
import CurrentChecks from "./CurrentChecks";
import PastChecks from "./PastChecks";
import Button from "../../../components/button";
import withScreenWidth from "../../../components/hocs/withScreenWidth";
import info from "../../../components/message/index";
import { connect } from "react-redux";
class PrintChecks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab:
        props.location.state && props.location.state.tab
          ? props.location.state.tab
          : "currentCheck",
      bank:
        props.location.state && props.location.state.bank
          ? props.location.state.bank
          : null,
      printData: {},
      lastCheckNumber: props.transactionsData.lastCheckNumber
    };
  }

  // componentDidMount() { 
  //   window.addEventListener("resize", this.handleWidthChange);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleWidthChange);
  // }

  // handleWidthChange = () => {
  //   this.setState({ size: window.innerWidth });
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tab !== this.state.tab) {
      console.log("pokemons state has changed.");
      this.setState({ printData: {} });
    }

    if (
      prevProps.transactionsData.lastCheckNumber !==
      this.props.transactionsData.lastCheckNumber
    ) {
      this.setState({
        lastCheckNumber: this.props.transactionsData.lastCheckNumber
      });
    }
  }

  handleChecksData = (e, rowInfo) => {
    console.log("handleChecksData", e);
    let { printData } = this.state;
    if (e && e.target && e.target.checked) {
      let data = { ...printData, [rowInfo.transaction_source_id]: rowInfo };
      // rowInfo.transaction_source_id = rowInfo;
      console.log("data", data);
      this.setState({ printData: data });
      // setPrintData([...printData, rowInfo]);
    } else {
      let data = { ...printData };
      delete data[rowInfo.transaction_source_id];
      this.setState({ printData: data });
    }
  };

  handleLastCheckNumber = e => {
    console.log("handleLastCheckNumber", e.target.value);
    this.setState({ lastCheckNumber: e.target.value });
  };

  render() {
    // console.log(this.state.printData, "this.state.tab");
    //console.log(this.state.lastCheckNumber, this.props.screenWidth, "Current Chaaak");
    return (
      <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
        <Col span={24}>
          <HeaderWrapper
            maintainSpans
            bisect={this.props.screenWidth < 992}
            middleAlign={this.props.screenWidth < 992 ? null : [
              <Tabs
                tabWrap={{
                  onChange: e => this.setState({ tab: e }),
                  activeKey: this.state.tab
                }}
                tabItem={[
                  {
                    tab: "Current Checks",
                    key: "currentCheck"
                  },
                  {
                    tab: "Past Checks",
                    key: "pastCheck"
                    // attr: {
                    //   className: "secondary"
                    // }
                  }
                ]}
              />
            ]}
            rightAlign={[
              // <div style={{ width: "100%", textAlign: "right" }}>
                <Button
                  // bankDetail={state}
                  //style={{ padding: "0.596vw 0.8vw" }}
                  //block
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/print-cheques/details`,
                      state: {
                        referrer: "/print-cheques",
                        printData: Object.values(this.state.printData),
                        lastCheckNumber: Number(this.state.lastCheckNumber),
                        referrerState: {
                          referrer:
                            this.props.location.state &&
                            this.props.location.state.referrer
                              ? this.props.location.state.referrer
                              : null,
                          tab: this.state.tab,
                          id:
                            this.props.location.state &&
                            this.props.location.state.id
                              ? this.props.location.state.id
                              : null
                        }
                      }
                    })
                  }
                  disabled={
                    Object.keys(this.state.printData).length > 0 &&
                    this.state.lastCheckNumber !== ""
                      ? false
                      : true
                  }
                >
                  Continue
                </Button>
              // </div>
            ]}
            externalscreen={1}
          />
        </Col>
        {this.props.screenWidth < 992 && 
        <Col xs={24} sm={12}>
          <Tabs
            tabWrap={{
              onChange: e => this.setState({ tab: e }),
              activeKey: this.state.tab
            }}
            tabItem={[
              {
                tab: "Current Checks",
                key: "currentCheck"
              },
              {
                tab: "Past Checks",
                key: "pastCheck"
                // attr: {
                //   className: "secondary"
                // }
              }
            ]}
          />
        </Col>}
        <Col span={24}>
          {/* <CardWrapper> */}
          {this.state.tab === "currentCheck" ? (
            <CurrentChecks
              bank={this.state.bank}
              printData={this.state.printData}
              handleChecksData={this.handleChecksData}
              handleLastCheckNumber={this.handleLastCheckNumber}
              lastCheckNumber={this.state.lastCheckNumber}
            />
          ) : (
            <PastChecks
              bank={this.state.bank}
              printData={this.state.printData}
              handleChecksData={this.handleChecksData}
              handleLastCheckNumber={this.handleLastCheckNumber}
              lastCheckNumber={this.state.lastCheckNumber}
            />
          )}
          {/* </CardWrapper> */}
        </Col>
      </Row>
    );
  }
}

export default withScreenWidth(withRouter(
  connect(
    storeState => ({ transactionsData: storeState.PrintCheck }),
    null
  )(PrintChecks)
));
