import { all } from "redux-saga/effects";
import { watchAppActions } from "./App";
import { watchAuthActions } from "./Auth";
import { watchProfileChanages } from "./updateProfile";
import { watchBankAccount } from "./BankAccount";
import { watchFieldOptions } from "./FieldOptions";
import { watchContacts } from "./Contact";
import { watchTransactions } from "./Transactions";
import { watchReports } from "./Reports";
import { watchSearchTransactions } from "./SerachTransactions";
import { watchTransferDetails } from "./TransferDetails";
import { watchCharts } from "./Charts";
import { watchSetings } from "./Settings";
import { watchPrintTransactions } from "./PrintChecks";
import { watchSubscription } from "./Subscription";
import { watchStatement } from "./Statements";

export default function* rootSaga() {
  yield all([
    watchAppActions(),
    watchAuthActions(),
    watchProfileChanages(),
    watchBankAccount(),
    watchFieldOptions(),
    watchContacts(),
    watchTransactions(),
    watchReports(),
    watchSearchTransactions(),
    watchTransferDetails(),
    watchCharts(),
    watchSetings(),
    watchPrintTransactions(),
    watchSubscription(),
    watchStatement()
  ]);
}
