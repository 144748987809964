import React from "react";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";
import SignUp from "./SignUpForm";
import Verification from "./VerificationForm";
import AddCard from "./AddCardForm";
import DespositCash from "./DespositCashForm";

const WrappedAddCard = props => (
  <Elements
    fonts={[
      {
        // integrate your font into stripe
        cssSrc:
          "https://fonts.googleapis.com/css?family=Exo+2:400i,500i,600i,700i,800&display=swap"
      }
    ]}
  >
    <AddCard {...props} />
  </Elements>
);

class SignUpScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authSteps: [SignUp, Verification, WrappedAddCard, DespositCash],
      step: 1,
      email: null
    };
  }
  componentDidMount() {

    try {
      if (this.props.location.state.fromVerify) {
        this.setState({ step: 2, email: this.props.location.state.email });
      }
    } catch (e) {}
  }
  // changeEmail = (value)=>{
  //   this.setState({email:value})
  // }
  render() {
    let CurrentScreen = this.state.authSteps[this.state.step - 1];
    // console.log("Props Login", this.props);
    return (
      <CurrentScreen
        email={this.state.email}
        steps={{ count: 4, current: this.state.step }}
        fromVerify={
          this.props.location.state
            ? this.props.location.state.fromVerify
            : false
        }
        cb={values => {
          //console.log("IN CALLBACK", values);
          if (this.state.step === 1) {
            this.props.history.push({
              pathname: "/login"
            });
            return;
          }
          this.setState({
            step: this.state.step + 1,
            email: this.state.email ? this.state.email : values.email
          });
        }}
      />
    );
  }
}

export default connect(storeState => ({ Auth: storeState.Auth }))(SignUpScreen);
