import React from 'react';
import styled from "styled-components";
import { Steps } from 'antd';

const { Step } = Steps;

const StyledStep = styled(Step)`
  
`;

const StepWrapper = (props) => {
  return (
    <StyledStep {...props}></StyledStep>
  );
}

const StyledSteps = styled(Steps)`
&.ant-steps {
  align-items: center;
  justify-content: center;
}


.ant-steps-item {
  flex: unset;
}

.ant-steps-item:last-child .ant-steps-item-icon {
  margin-right: 0 !important;
}
  
.ant-steps-item-tail {
  display: none;
}

.ant-steps-item-icon {
  margin-left: 0;
  margin-right: 4px;
  width: 14px !important;
  height: 2px !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: ${props => props.theme[props.theme.mode].primary.main};
}

.ant-steps-item:not(.ant-steps-item-process) .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: #072C6419 ;
}

.ant-steps-icon-dot {
  top: unset !important;
  left: unset !important;
}

.ant-steps-item-content {
  width: unset;
}
`;

const StepsWrapper = (props) => {
  return (
    <StyledSteps {...props}></StyledSteps>
  );
}

export { StepsWrapper, StepWrapper };