import useFormikWrapper from "../../../../components/formikwrapper/FormikWrapper";
import {
  buySubscriptionSchema
} from "../../../../configurations/Schemas/index";

const useBuySubscriptionForm = props => {

  const formik = useFormikWrapper({
    ...props,
    validationSchema: buySubscriptionSchema
  });

  return formik;
};

export default useBuySubscriptionForm;
