import React from 'react'
import { Row, Col } from 'antd'
import ContactForm from '../../appcomponents/shares/landing/ContactForm';

const ContactUs = props => {
  return (
    <Row type="flex" justify="center" >
      <Col span={24} style={{  textAlign: "center" }}>
      <h2>Get In Touch With Us </h2>
      </Col>
      <Col span={8}>
        <ContactForm />
      </Col>
    </Row>
  )
}

export default ContactUs
