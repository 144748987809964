import * as Yup from "yup";

export const StatementSchema = Yup.object().shape({
  //fileName: Yup.string()
  fileName: Yup.string()
  // .min(2, "Bank  Name is Too Short! it must be between 2 to 50 characters")
  // .max(50, "Bank  Name is Too Long! it must be between 2 to 50 characters")
  // .matches(/^[a-z\s]+$/i, "Only alphabets allowed")
  .required("Please provide Tenant name"),
});
