import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useTransferJournalLedgerForm from "../../forms/reports/useTransferJournalLedgerForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { transferJournalLedgerRequest } from "../../../../actions/Reports";
import { closeAllModal } from "../../../../actions/Modal";

import moment from "moment";

const TransferJournalLedger = props => {
  const [yearPickerOpen, setYearPickerOpen] = useState(false);

  const mainForm = useTransferJournalLedgerForm({
    initialValues: props.initialValues ? {
      ...props.initialValues
    } : {
      bank_account_id: "",
      date_type: "monthly",
      month: moment().month() + 1,
      year: moment().year()
    },

    onSubmit: values => props.transferJournalLedgerRequest({ ...values, format: props.format }, report => {
      props.closeAllModal();
      props.history.push({ pathname: "/reports/preview-journal-transfer-ledger", state: { 
          referrer: "/reports",
          referrerState: {
            ...props.location.state,
            transferJournalLedgerValues: {
              formValues: values,
              format: props.format
            }
          }, 
          report, 
          format: props.format 
        } 
      })
    })
  })

  console.log("FORM", mainForm)

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
          Journal/Transfer Ledger
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "left" }}>
            <BankName
            className="inputField"
            name="bank_account_id"
            placeholder="All Bank Accounts"
            withoutAddButton
            hideDeleteBtn
            withAllOption
            initialValue={mainForm.values.bank_name}
            onChange={(id, name) => {
              mainForm.customHandleChange(id, "bank_account_id");
              mainForm.customHandleChange(name, "bank_name");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "bank_account_id")}
            />
            {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"]}
            </div> : null}
          </Col>

          <Col span={24} style={{ textAlign: "left" }}>
            <Select
            className="inputField"
            name="date_type"
            placeholder="Date Type"
            value={mainForm.values.date_type}
            onChange={e => {mainForm.onChangeDateType(e, "date_type");
            e==="alldata" ? mainForm.setFieldValue("year", "") : console.log("yes it will work");
            e==="alldata" ? mainForm.setFieldValue("start_date", "") : console.log("yes date1");
            e==="alldata" ? mainForm.setFieldValue("start_day", new Date().getDate().toString()) : console.log("yes date1");
            e==="alldata" ? mainForm.setFieldValue("start_month", ((new Date().getMonth()+1).toString())) : console.log("yes date1");
            e==="alldata" ? mainForm.setFieldValue("start_year", (((new Date().getFullYear())-50).toString())) : console.log("yes date1");
            e==="alldata" ? mainForm.setFieldValue("end_date", "") : console.log("yes date2");
            e==="alldata" ? mainForm.setFieldValue("end_day", new Date().getDate().toString()) : console.log("yes date2");
            e==="alldata" ? mainForm.setFieldValue("end_month", ((new Date().getMonth()+1).toString())) : console.log("yes date2");
            e==="alldata" ? mainForm.setFieldValue("end_year", (((new Date().getFullYear())+50).toString())) : console.log("yes date2");
            }}
            onBlur={e => mainForm.customHandleBlur(e, "date_type")}
            style={{ width: "100%" }}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
              <Option value="alldata">All Data</Option>
            </Select>
            {mainForm.touched["date_type"] && mainForm.errors["date_type"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["date_type"] && mainForm.errors["date_type"]}
            </div> : null}
          </Col>

          {mainForm.values.date_type === "monthly" && 
          <Col span={24} style={{ textAlign: "left" }}>
            <MonthPicker
            singleMonth
            outlined
            className="inputField"
            name="month"
            value={mainForm.values.month}
            placeholder="Month"
            onChange={e => mainForm.customHandleChange(e, "month")}
            onBlur={e => mainForm.customHandleBlur(e, "month")}
            />
            {mainForm.touched["month"] && mainForm.errors["month"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["month"] && mainForm.errors["month"]}
            </div> : null}
          </Col>}

          {(mainForm.values.date_type === "yearly" || mainForm.values.date_type === "monthly") &&
          <Col span={24} style={{ textAlign: "left" }}>
            <DatePicker
              allowClear={false}
              open={yearPickerOpen}
              onOpenChange={(open) => setYearPickerOpen(open)}
              modalField
              className="inputField"
              name="year"
              value={
                mainForm.values.year
                  ? moment().year(mainForm.values.year)
                  : undefined
              }
              // onChange={e => mainForm.customHandleChange(e, "year")}
              onFocus={() => setYearPickerOpen(true)}
              onBlur={(e) => {
                mainForm.customHandleBlur(e, "year");
                // setYearPickerOpen(false);
              }}
              style={{ width: "100%" }}
              //picker="year"
              format="YYYY"
              mode="year"
              placeholder="Year"
              onPanelChange={(e) => {
                // console.log('eveennt==>',e)
                
                mainForm.customHandleChange(e.year(), "year");
                setYearPickerOpen(false);
              }}
            />
            {mainForm.touched["year"] && mainForm.errors["year"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["year"] && mainForm.errors["year"]}
            </div> : null}
          </Col>}

          <Col span={16} className="mr-t-md">
            <Button loading={props.reports.transferJournalLedgerLoading} block onClick={mainForm.handleSubmit}>Preview Report</Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>Cancel</Button>
          </Col>
          
        </Row>
      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports }), { transferJournalLedgerRequest, closeAllModal })(TransferJournalLedger));
