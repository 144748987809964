import { put, takeLatest } from "redux-saga/effects";
import {
  BUY_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_REQUEST,
} from "../configurations/Types";
import {
  BUY_SUBSCRIPTION_ENDPOINT,
  CANCEL_SUBSCRIPTION_ENDPOINT,
  SUBSCRIPTION_ENDPOINT,
  //   CANCEL_SUBSCRIPTION,
} from "../configurations/Constants";

import {
  buySubscriptionSuccess,
  buySubscriptionError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  getSubscriptionStatus,
  getSubscriptionStatusSuccess,
  getSubscriptionStatusError,
  setSubscriptionStatus,
} from "../actions/Subscription";
import moment from "moment";
import info from "../components/message/index";
import { privateAgent } from "../configurations/AxiosAgent";

// buy subscription
const buySubscriptionApi = (paymentMethod, params) =>
  privateAgent.post(`${BUY_SUBSCRIPTION_ENDPOINT}`, {
    ...params,
    receipt_data: paymentMethod,
    platform: "stripe",
    current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
  });
function* buySubscription(action) {
  let res;
  try {
    let { paymentMethod, error } = yield action.generatePaymentMethod();

    if (error) throw error;

    res = yield buySubscriptionApi(paymentMethod, { ...action.params, country: paymentMethod.card.country });
    info("success", `Purchase successful`);
    //yield put(setSubscriptionStatus(res.data.data));
    yield put(getSubscriptionStatus());
    yield put(buySubscriptionSuccess());

    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(buySubscriptionError());
  }
}

// cancel subscription
const cancelSubscriptionApi = (params) =>
  privateAgent.post(
    `${BUY_SUBSCRIPTION_ENDPOINT}${CANCEL_SUBSCRIPTION_ENDPOINT}`,
    {
      ...params,
      status: true,
      current_time: moment().format("YYYY-MM-DD HH:mm:ss"),
    }
  );
function* cancelSubscription(action) {
  let res;
  try {
    res = yield cancelSubscriptionApi(action.params);
    info("success", `Your subscription has been cancelled`);

    yield put(setSubscriptionStatus({ plan: "none" }));
    //Set new subscription in localStorage
    // let newStorage = JSON.parse(localStorage.getItem("escrowauth"));
    // newStorage.subscription = { plan: "none" };

    // localStorage.setItem("escrowauth", JSON.stringify(newStorage));

    yield put(cancelSubscriptionSuccess());
    yield put(getSubscriptionStatus());
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(cancelSubscriptionError());
  }
}

// get subscriptions
const getSubscriptionApi = () => privateAgent.get(`${SUBSCRIPTION_ENDPOINT}`);
function* getSubscription() {
  let res;
  try {
    res = yield getSubscriptionApi();
    console.log(res, "getSubscriptionApi", res);
    // info("success", `${res.data.data.message}`);

    yield put(setSubscriptionStatus(res.data.data.subscription));
    //Set new subscription in localStorage
    let newStorage = JSON.parse(localStorage.getItem("escrowauth"));
    newStorage.subscription = res.data.data.subscription;

    localStorage.setItem("escrowauth", JSON.stringify(newStorage));

    yield put(getSubscriptionStatusSuccess());
    // if (action.cb) {
    //   action.cb();
    // }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }

    yield put(getSubscriptionStatusError());
  }
}
// get subscription
export function* watchSubscription() {
  yield takeLatest(BUY_SUBSCRIPTION_REQUEST, buySubscription);
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
  yield takeLatest(GET_SUBSCRIPTION_REQUEST, getSubscription);
}
