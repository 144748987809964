import React, { Fragment, useState } from "react";
import { Row, Col } from "antd";
import Card from ".././../../components/card";
import DatePicker from "../../../components/datepickers/DatePicker";
import moment from "moment";
import { AccountingFormat } from "../../../configurations/Config";
// let current_date = moment();
const ChecksCard = (props) => {
  const [selectedDate, setSelectedDate] = useState(
    props.date_of_transaction ? moment(props.date_of_transaction) : null
  );

  console.log("index", props.index);

  function handleDates(val, key) {
    console.log(val, key, "Hello Dates");
    setSelectedDate({ [key]: val.format("MM-DD-YYYY") });
  }
  return (
    <Card smallPadding>
      <Row type="flex" gutter={[0, 20]} justify="space-between" align="stretch">
        <Col span={12}>
          {/* {props.label} */}
          <h5>Payee Name</h5>
        </Col>

        <Col span={12} className="textRight">
          <h5 style={{ opacity: "0.5" }}>{props.payor_name}</h5>
        </Col>
      </Row>
      <Row type="flex" gutter={[0, 20]} justify="space-between">
        <Col span={12}>
          {/* {props.label} */}
          <h5>Address of Payee</h5>
        </Col>

        <Col span={12} className="textRight">
          <h5 style={{ opacity: "0.5" }}>{props.contact_address}</h5>
        </Col>
      </Row>
      <Row type="flex" gutter={[0, 20]} justify="space-between">
        <Col span={12}>
          {/* {props.label} */}
          <h5>Payee Amount</h5>
        </Col>

        <Col span={12} className="textRight">
          <h5 style={{ opacity: "0.5" }}>{props.amount}</h5>
        </Col>
      </Row>
      <Row type="flex" gutter={[0, 20]} justify="space-between">
        <Col span={12}>
          {/* {props.label} */}
          <h5>Date of Check Writing</h5>
        </Col>

        <Col xs={6} sm={6} md={6} lg={6} style={{ padding: 0 }}>
            <DatePicker
              style={{ width: "100%" }}
              name="date"
              noShadow
              value={
                props.date_of_transaction
                  ? moment(props.date_of_transaction)
                  : null
              }
              onChange={(e) => props.handleDateChange(e)}
              noBorder
              format="MM-DD-YYYY"
              allowClear={false}
            />
        </Col>
      </Row>
    </Card>
  );
};

export default ChecksCard;
