import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PopconfirmWrapper from "../../../components/pop-confirm";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  deleteTransactionRequest,
  ClearedTransactionsRequest
} from "../../../actions/SearchTransactions";
import { getRecurringRequest } from "../../../actions/Transaction";
import { FiEdit, FiMoreHorizontal, FiAlertTriangle } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Divider } from "antd";
import { current_sprint } from "../../../configurations/Constants";
import PopOver from "../../../components/popover";
import {AccountingFormat} from "../../../configurations/Config"

let map = {
  DEPOSIT: "primary",
  WITHDRAWAL: "secondary",
  RECURRING: "third"
  //Banned: "fourth
};

// For Checking Frequency
let isFrequency = {
  "1": "Daily",
  "7": "Weekly",
  "14": "Every 2 Weeks",
  "28": "Every 4 Weeks",
  "15": "Semi-Monthly",
  "30": "Monthly",
  "60": "Every 2 Months",
  "90": "Every 3 Months",
  "180": "Every 6 Months",
  "365": "Every Year"
};
// For Checking Frequency
const Recurring = props => {
  const [tableChanged, setTableChanged] = useState(false);
  const [filters, setFilters] = useState({
    frequency: props.frequencies
  });

  const [state] = useState({
    apiCall: (...params) => {
      props.getRecurringRequest(...params);
      console.log("API PARAMS", params);
    }
  });

  useEffect(() => {
    setFilters({ frequency: props.frequencies });
  }, [props.frequencies]);
  console.log("Recuuring View props", props.frequencies);
  return (
    <Row>
      {current_sprint > 1 ? (
        <Col span={24} style={{ margin: "40px 0px 50px 0px" }}>
          <TableWrapper
            tableData={props.recurringTransactions.allRecurrings}
            getData={state.apiCall}
            filters={filters}
            pageSize={10}
            dataCount={parseInt(
              props.recurringTransactions.recurringTotalCount
            )}
            getTrProps={(state, rowInfo, column) => ({
              onClick: () =>
                props.history.push({
                  pathname: `/view-transaction/${rowInfo.original.transaction_source_id}`,
                  state: {
                    referrer: "/add-transaction",
                    referrerState: {
                      tab: "recurring",
                      bank: props.bank,
                      frequency: props.frequencies
                    }
                  }
                }),
              style: { cursor: "pointer" }
            })}
            // loading={props.transactionsData.tableLoading}
            //enableReInit={true}
            tableChanged={tableChanged}
            content={[
              {
                name: "Account Name",
                id: "account_name",
                style: { color: "red" },
                render: r => (
                  <span
                    className={
                      r.original.transaction_type === "deposit"
                        ? "appColor"
                        : "secondaryAppColor"
                    }
                  >
                    {r.original.account_name !== ""
                      ? r.original.account_name
                      : "-"}
                  </span>
                )
              },
              {
                name: "Customer Name",
                id: "customer_name"
              },
              {
                name: "Job Name",
                id: "job_name"
              },
              {
                name: "Date",
                id: "date_of_transaction",
                render: r => (
                  <span>
                    {r.original.date_of_transaction
                      ? moment(r.original.date_of_transaction).format(
                          "MM/DD/YYYY"
                        )
                      : "-"}
                  </span>
                )
              },

              {
                name: "Amount",
                id: "amount",
                render: r => (
                  <span
                    className={
                      r.original.transaction_type === "deposit"
                        ? "appColor"
                        : "secondaryAppColor"
                    }
                  >
                    {r.original.is_split === "true"
                      ? "Splits"
                      : AccountingFormat(r.original.amount, r.original.currency)}
                  </span>
                )
              },

              {
                name: "Recurring",
                id: "transaction_type",
                large: true,
                render: r => (
                  <React.Fragment>
                    <TagWrapper
                      style={{ width: "100%", backgroundColor: "#154D94" }}
                      className="small"
                      // type={map[r.original.transaction_type.toUpperCase()]}
                    >
                      {r.original.frequency
                        ? isFrequency[r.original.frequency]
                        : "-"}
                    </TagWrapper>
                    <span style={{ marginLeft: "1rem" }}>
                      <PopOver
                        // placement="left"
                        onClick={e => e.stopPropagation()}
                        style={{ cursor: "pointer", backgroundColor: "black" }}
                        content={
                          <div onClick={e => e.stopPropagation()}>
                            <div
                              style={{ padding: "8px", cursor: "pointer", display: "flex", alignItems: "center" }}
                              onClick={() =>
                                props.history.push({
                                  pathname: `/view-transaction/${r.original.transaction_source_id}`,
                                  state: {
                                    referrer: "/add-transaction",
                                    referrerState: {
                                      tab: "recurring",
                                      bank: props.bank,
                                      frequency: props.frequencies
                                    }
                                    // tab:"recurring"
                                  }
                                })
                              }
                            >
                              <FiEdit style={{ marginRight: 5 }} />
                              <span>Edit</span>
                            </div>

                            <Divider style={{ margin: 0 }} />

                            {/* poprConfirm */}

                            <PopconfirmWrapper
                              onClick={e => e.stopPropagation()}
                              title={
                                <h4>
                                  Are you sure you want to delete this
                                  transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.deleteTransactionRequest(
                                  r.original.transaction_source_id,
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              {/* <ButtonWrapper className="deleteBtn"> */}
                              <div
                                style={{ padding: "8px", cursor: "pointer", display: "flex", alignItems: "center" }}
                              >
                                <AiOutlineDelete className="deleteIcon" style={{ color: "#FF494C", marginRight: 5 }} />
                                <span>Delete</span>
                              </div>
                              {/* </ButtonWrapper> */}
                            </PopconfirmWrapper>

                            {/* <Divider style={{ margin: 0 }} />
                            <PopconfirmWrapper
                              title={
                                <h4>
                                  Are you sure you want to Clear this
                                  transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.ClearedTransactionsRequest(
                                  r.original.transaction_source_id,
                                  r.original.cleared === "true"
                                    ? "false"
                                    : "true",
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              <div style={{ padding: "8px" }}>
                                <Switch
                                  checked={
                                    r.original.cleared === "true" ? true : false
                                  }
                                  size="small"
                                />{" "}
                                <span>Cleared</span>
                              </div>
                            </PopconfirmWrapper> */}
                          </div>
                        }
                      >
                        <FiMoreHorizontal style={{ color: "#154D94" }} />
                      </PopOver>
                    </span>
                  </React.Fragment>
                )
              }
            ]}
          />
        </Col>
      ) : null}
    </Row>
  );
};
export default withRouter(
  connect(storeState => ({ recurringTransactions: storeState.transaction }), {
    getRecurringRequest,
    deleteTransactionRequest,
    ClearedTransactionsRequest
  })(Recurring)
);
