import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import TableWrapper from "../../../components/react-table";
import TagWrapper from "../../../components/tag";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { getPrintChecksTransactionsRequest } from "../../../actions/PrintChecks";
import { current_sprint } from "../../../configurations/Constants";
import Input from "../../../components/input/index";
import { AccountingFormat } from "../../../configurations/Config";
import CheckBox from "../../../components/checkbox";
import { checkNumberSchema } from "../../../configurations/Schemas/index";
import RootForm from "../../../components/form/index";
let map = {
  DEPOSIT: "primary",
  WITHDRAWAL: "secondary",
  RECURRING: "third",
  //Banned: "fourth
};

const CurrentChecks = (props) => {
  console.log(props, "props.location.state");
  const [tableChanged, setTableChanged] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    like: "",
    cheque_type: "current",
    bankId: props.location.state ? props.location.state.id : null,
  });
  const [state] = useState({
    apiCall: (...params) => {
      props.getPrintChecksTransactionsRequest(...params);
      console.log("SERACH API", params);
    },
  });

  console.log(props.transactionsData, "abc");
  return (
    <React.Fragment>
      <Row gutter={[0, 20]}>
        <Col xs={24} sm={12} md={9}>
          {/* <Col xs={24} sm={24} md={9} lg={9} xl={9}>
            <Row type="flex" justify="start" align="middle" gutter={[20, 20]}>
              <Col xs={24} sm={24} lg={24}> */}
          <Input
            placeholder="Starting Check Number"
            onChange={(e) => props.handleLastCheckNumber(e)}
            type="number"
            value={props.lastCheckNumber}
            name="checkNumber"
          />
          {props.lastCheckNumber === "" ? (
            <span className="dangerColor caption textLeft">
              {/* <h5 className="dangerColor caption textCenter"> */}
              Please provide starting check number
              {/* </h5> */}
            </span>
          ) : null}
          {/* </Col>
            </Row>
          </Col> */}

          {/* //2nd Col */}
          {/* <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            <Row type="flex" justify="end" align="middle" gutter={[40, 20]}>
              <Col>
                {props.transactionsData.transactions.length ? (
                  <h4>Please select minimum 1 and maximum 3 to proceed</h4>
                ) : null}
              </Col>
            </Row>
          </Col> */}
        </Col>

        {current_sprint > 1 ? (
          <Col span={24}>
            <TableWrapper
              tableData={props.transactionsData.transactions}
              getData={state.apiCall}
              search={search}
              filters={filters}
              pageSize={10}
              dataCount={parseInt(props.transactionsData.totalCount)}
              //   getTrProps={(state, rowInfo, column) => ({
              //     onClick: () =>
              //       props.history.push({
              //         pathname: `/view-transaction/${rowInfo.original.transaction_source_id}`,
              //         state: {
              //           referrer: "/search-transaction",
              //           referrerState: {
              //             transaction_type: filters.transaction_type,
              //             month: filters.month,
              //           },
              //         },
              //       }),
              //     style: { cursor: "pointer" },
              //   })}
              // loading={props.transactionsData.tableLoading}
              //enableReInit={true}
              tableChanged={tableChanged}
              content={[
                {
                  name: "Account Name",
                  id: "1",
                  style: { color: "red" },
                  render: (r) => (
                    <span
                      className={
                        r.original.is_split === "true"
                          ? null
                          : "secondaryAppColor"
                      }
                      style={{
                        fontStyle:
                          r.original.is_split === "true" ? "italic" : "",
                      }}
                    >
                      {r.original.is_split === "true"
                        ? "Splits"
                        : r.original.account_name !== ""
                        ? r.original.account_name
                        : "-"}
                    </span>
                  ),
                },
                {
                  name: "Payee Name",
                  id: "payor_name",
                },
                {
                  name: "Job Name",
                  id: "job_name",
                },
                {
                  name: "Date",
                  id: "date_of_transaction",
                  render: (r) => (
                    <span>
                      {r.original.date_of_transaction
                        ? moment(r.original.date_of_transaction).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </span>
                  ),
                },

                {
                  name: "Amount",
                  id: "amount",
                  render: (r) => (
                    <span
                      // style={{
                      //   fontStyle:
                      //     r.original.is_split === "true" ? "italic" : "",
                      // }}
                      className="secondaryAppColor"
                    >
                      {r.original.is_split === "true"
                        ? AccountingFormat(
                            r.original.amount,
                            r.original.currency
                          )
                        : AccountingFormat(
                            r.original.amount,
                            r.original.currency
                          )}
                    </span>
                  ),
                },

                {
                  name: "Transaction",
                  id: "transaction_type",
                  large: true,
                  render: (r) => (
                    <React.Fragment>
                      <TagWrapper
                        style={{
                          width: "100%",
                          //fontStyle:"italic"
                        }}
                        className="small"
                        type={
                          map[
                            r.original.is_recurring === "true"
                              ? "RECURRING"
                              : "WITHDRAWAL"
                          ]
                        }
                      >
                        {r.original.is_recurring === "true"
                          ? "Recurring"
                          : r.original.transaction_type
                          ? r.original.transaction_type
                              .charAt(0)
                              .toUpperCase() +
                            r.original.transaction_type.slice(1)
                          : "-"}
                      </TagWrapper>
                      <span style={{ marginLeft: "1rem" }}>
                        <CheckBox
                          onChange={(e) =>
                            props.handleChecksData(e, r.original)
                          }
                          checked={
                            props.printData &&
                            props.printData[r.original.transaction_source_id]
                          }
                        />
                      </span>
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};
export default withRouter(
  connect((storeState) => ({ transactionsData: storeState.PrintCheck }), {
    getPrintChecksTransactionsRequest,
  })(CurrentChecks)
);
