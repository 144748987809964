import React from "react";
import { Row, Col } from "antd";
import Select from "../../../../EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/General";
import Option from "../../../../../components/input/SelectOptions";
import styled from "styled-components";

const months = [
  { key: 1, value: "Daily" },
  { key: 7, value: "Weekly" },
  { key: 14, value: "Every 2 Weeks" },
  { key: 28, value: "Every 4 Weeks" },
  { key: 15, value: "Semi-Monthly" },
  { key: 30, value: "Monthly" },
  { key: 60, value: "Every 2 Months" },
  { key: 90, value: "Every 3 Months" },
  { key: 180, value: "Every 6 Months" },
  { key: 365, value: "Every Year" },
];
const FilterTransaction = props => {
  return (
        <Select {...props}>
          {months.map(val => (
            <Option key={val.key ? val.key : 0} value={val.key}>{val.value}</Option>
          ))}
        </Select>
  );
};

export default FilterTransaction;
