import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid!")
    .matches(
      /^([0-9a-zA-Z]+[.-_]{0,1})+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,}$/i,
      "Email is not valid!"
    )
    .required("Email Required"),

  password: Yup.string()
    .min(6, "Password is Too Short! it must be between 6 and 16 characters")
    .max(16, "Password is Too Long! it must be between 6 and 16 characters")
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9\W\S]{8,16}$/, 'Must contain one uppercase and one lowercase.')
    .matches(/^[a-zA-Z0-9\W\S]{6,16}$/, "atleast contain six charachters.")
    .required("Password Required")
});
