import React from "react";
import { Dropdown } from 'antd';
import styled, { css } from "styled-components";

const DropdownStyle = css`


&.profileMenu{
  z-index:3002;
  justify-content:center;
  padding-right:1rem;
}
&.ant-dropdown-placement-bottomRight {
    padding-top: 10px;
}
&.ant-dropdown {
  ${props => props.fixed ? `position: fixed` : null}

  width: 13vw;
}
&.ant-dropdown .flexFill {
  flex-grow: 1;
}
&.ant-dropdown .zeroLineHeight {
  line-height: 0;
}
.ant-dropdown-menu {
  overflow: hidden;
  padding: 0;
}
.ant-dropdown-menu .ant-dropdown-menu-item {
  font-size: ${props => props.theme[props.theme.mode].textSizes.relative.btn};
  line-height: 12px;
  white-space: normal;
  padding: 0.75rem 0.6vw;
  font-weight: 500;
  /* padding-top: 0.6vw;
  padding-bottom: 0.6vw; */
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}
.ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin: 0;
}
& .logout {
    color: ${props => props.theme[props.theme.mode].error.main};
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-item:hover, .ant-dropdown-menu-item-active {
  background-color: ${props => props.theme[props.theme.mode].primary.light};
}

.profileMenu .ant-row-flex {
  flex-wrap: nowrap;
}

`
const DropdownWrapper = (props) => {
  // console.log(props);
  return (
    <Dropdown overlayClassName={props.className} placement="bottomRight" {...props} trigger="click" />
  );
};
export default styled(DropdownWrapper)`${DropdownStyle}`;
