import React from "react";
import { Row, Col } from "antd";
import Select from "../../../components/input/Select";
import Option from "../../../components/input/SelectOptions";
import styled, { css } from "styled-components";

const SelectWrapper = styled(Select)`
  width: 100%;
  & .ant-select-selection
  ${props => props.outlined ? `` : css`
  ,& .ant-select-selection:hover,
  & .ant-select-selection:active,
  & .ant-select-selection:focus 
  `
  }
  {
    ${props => props.outlined ? null : `
    border: none;
    box-shadow: 0 0.3rem 1rem
      ${props.theme[props.theme.mode].textColor.hint}0C;
    `}
    border-radius: 0.25rem;
  }
`;
const months = [
  { key: "", value: "All Months" },
  { key: 1, value: "January" },
  { key: 2, value: "Feburary" },
  { key: 3, value: "March" },
  { key: 4, value: "April" },
  { key: 5, value: "May" },
  { key: 6, value: "June" },
  { key: 7, value: "July" },
  { key: 8, value: "August" },
  { key: 9, value: "September" },
  { key: 10, value: "October" },
  { key: 11, value: "November" },
  { key: 12, value: "December" }
];
const FilterTransaction = props => {

  return (
    // <Row gutter={[30, 20]} className="no-mr" type="flex" justify="space-between" align="middle">
    //   <Col span={10}>
        <SelectWrapper noBorder={!props.outlined} defaultValue={months[props.singleMonth ? 1 : 0].key} {...props.months} {...props}>
          {months.slice(props.singleMonth ? 1 : 0).map(val => (
            <Option key={val.key ? val.key : 0} value={val.key}>{val.value}</Option>
          ))}
        </SelectWrapper>
    //   </Col>
    //   <Col span={10}>
        
    //   </Col>
    // </Row>
  );
};

export default FilterTransaction;
