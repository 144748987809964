import React from "react";
import { Row, Col } from "antd";

import styled from "styled-components";

const LegendBox = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5px;
  background-color: ${props => props.theme[props.theme.mode].primary.light};
`;

const Legend = props => {
  return (
    <Row type="flex" align="middle">
      <Col>
        <LegendBox />
      </Col>
      <Col>
        <p>
          &nbsp;-&nbsp;{props.label}
        </p>
      </Col>
    </Row>
  );
};

export default Legend;
