import React, { useEffect } from "react";
import { Row, Col } from "antd";
import Button from "../../components/button/index";
import { closeAllModal } from "../../actions/Modal";
import { getBankAccountSummaryRequest } from "../../actions/Transaction";
import { connect } from "react-redux";
import moment from "moment";
import Icon from "../../components/Icon";
import {AccountingFormat} from "../../configurations/Config"

const BankAccountSummary = props => {
  console.log("BankAccountSummaryProps", props.banAccount);
  console.log(moment(props.banAccount.date_of_transaction).format("MMMM"));
  useEffect(() => {
    let month = moment(props.banAccount.date_of_transaction).month()+1;
    let year = moment(props.banAccount.date_of_transaction).format("YYYY");
    let { bank_account_id } = props.banAccount;
    props.getBankAccountSummaryRequest(bank_account_id, month, year);
  }, []);

  let { currency } = props.banAccount;
  let {
    balance_of_last_month,
    deposit_of_this_month,
    withdrawal_of_this_month,
    balance_of_this_month
  } = props.bankSummary.bankSummaryDetails;
  console.log("balance_of_last_month", props.banAccount);
  console.log("porps.bankSummary", props.bankSummary.bankSummaryDetails);
  return (
    <React.Fragment>
      {props.bankSummary.modalLoader ? (
        <div className="textCenter">
          {" "}
          <Icon
            type="loading"
            style={{ fontSize: "3rem", margin: "4rem 0" }}
          />{" "}
        </div>
      ) : (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="textCenter"
          gutter={[0, 30]}
          style={{ margin: "2rem" }}
        >
          <Col xs={24} sm={24} md={20} lg={18}>
            <h4 className="fontBold">
              Balance Of{" "}
              {moment(props.banAccount.date_of_transaction)
                .subtract(1, "month")
                .format("MMMM")}
            </h4>

            <h1 className="fontBold">
              {balance_of_last_month
                ? AccountingFormat(balance_of_last_month, currency)
                : `${currency}0.00`}
            </h1>
            <h4 className="fontBold">
              Deposit Of {moment(props.banAccount.date_of_transaction).format("MMMM")}
            </h4>

            <h1 className="fontBold">
              {deposit_of_this_month
                ? AccountingFormat(deposit_of_this_month, currency)
                : `${currency}0.00`}
            </h1>

            <h4 className="fontBold">
              Withdrawal Of {moment(props.banAccount.date_of_transaction).format("MMMM")}
            </h4>

            <h1 className="fontBold">
              {withdrawal_of_this_month
                ? AccountingFormat(withdrawal_of_this_month, currency)
                : `${currency}0.00`}
            </h1>

            <h4 className="fontBold">
              Balance Of {moment(props.banAccount.date_of_transaction).format("MMMM")}
            </h4>

            <h1 className="fontBold">
              {/* {currency} */}
              {balance_of_this_month
                ? AccountingFormat(balance_of_this_month, currency)
                : `${currency}0.00`}
            </h1>
          </Col>
          <Col span={12} xs={20} sm={20} lg={12} xl={12}>
            <Button block onClick={() => props.closeAllModal()}>
              Close
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default connect(
  storeState => ({ bankSummary: storeState.transaction }),
  {
    closeAllModal,
    getBankAccountSummaryRequest
  }
)(BankAccountSummary);
