import useFormikWrapper from "../../../../components/formikwrapper/FormikWrapper";
import {
  ProfitAndLossSchema
} from "../../../../configurations/Schemas/index";

import moment from "moment";

const useProfitAndLossForm = props => {

  const formik = useFormikWrapper({
    ...props,
    validationSchema: ProfitAndLossSchema
  });

  //Reset all date values when date type changes
  formik.onChangeDateType = (e) => {
    let date_type = e.target ? e.target.value : e;

    formik.setValues({
      ...formik.values,
      date_type,
      month : date_type === "monthly" ? moment().month() + 1 : "",
      //year: "",
    });

    formik.setTouched({
      ...formik.touched,
      month : undefined,
      //year: undefined,
    });
  }

  return formik;
};

export default useProfitAndLossForm;
