import React, {
  // useState,

  useEffect,
} from "react";
import useScreenWidth from "../../components/hooks/useScreenWidth";
import { connect } from "react-redux";
import { Row, Col, Empty } from "antd";
import Icon from "../../components/Icon";
import SwitchButtonCard from "../../appcomponents/EscrowTrakker/edittransaction/ClearedCard";
import DocumentCard from "../../appcomponents/EscrowTrakker/setting/DownloadSettingFileCard";
import CardWrapper from "../../components/card";
import RadioGrid from "../../appcomponents/general/RadioGrid";
import Button from "../../components/button";
// import USDFLAG from "../../assets/images/united-states.svg";
// import EUR from "../../assets/images/european-union.svg";
// import JPY from "../../assets/images/japan.svg";
// import GBP from "../../assets/images/united-kingdom.svg";
// import AUD from "../../assets/images/australia.svg";
// import CAD from "../../assets/images/canada.svg";
// import CNY from "../../assets/images/china.svg";
// import KRW from "../../assets/images/south-korea.svg";
// import IND from "../../assets/images/india.svg";
// import TRY from "../../assets/images/turkey.svg";
// import PKR from "../../assets/images/pakistan.svg";
// import ZAR from "../../assets/images/south-africa.svg";
import {
  getUserSettingRequest,
  updateUsersSettingsRequest,
} from "../../actions/Settings";
import options from "../../appcomponents/EscrowTrakker/setting/Currenices.json";
import styled from "styled-components";

const CurrencyContainer = styled(Col)`
  max-height: 33rem;
  overflow-y: auto;
  overflow-x: hidden;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

// let options = [
//   {
//     value: "$",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={USDFLAG} alt="USD" />
//         <span style={{ marginLeft: "10px" }}> USD ($) </span>
//       </span>
//     ),
//   },
//   {
//     value: "€",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={EUR} alt="EUR" />
//         <span style={{ marginLeft: "10px" }}> EUR (€) </span>
//       </span>
//     ),
//   },
//   {
//     value: "¥",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={JPY} alt="JPY" />
//         <span style={{ marginLeft: "10px" }}> JPY (¥) </span>
//       </span>
//     ),
//   },
//   {
//     value: "£",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={GBP} alt="GBP" />
//         <span style={{ marginLeft: "10px" }}> GBP (£)</span>
//       </span>
//     ),
//   },
//   {
//     value: "A$",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={AUD} alt="AUD" />
//         <span style={{ marginLeft: "10px" }}> AUD (A$) </span>
//       </span>
//     ),
//   },
//   {
//     value: "C$",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={CAD} alt="CAD" />
//         <span style={{ marginLeft: "10px" }}> CAD (C$) </span>
//       </span>
//     ),
//   },
//   {
//     value: "元",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={CNY} alt="CNY" />
//         <span style={{ marginLeft: "10px" }}> CNY (元)</span>
//       </span>
//     ),
//   },
//   {
//     value: "₩",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={KRW} alt="KRW" />
//         <span style={{ marginLeft: "10px" }}>KRW (₩)</span>
//       </span>
//     ),
//   },

//   {
//     value: "₹",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={IND} alt="IND" />
//         <span style={{ marginLeft: "10px" }}> INR (₹) </span>
//       </span>
//     ),
//   },
//   {
//     value: "₺",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={TRY} alt="TRY" />
//         <span style={{ marginLeft: "10px" }}> TRY (₺)</span>
//       </span>
//     ),
//   },
//   {
//     value: "RS",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={PKR} alt="PKR" />
//         <span style={{ marginLeft: "10px" }}>PKR (RS)</span>
//       </span>
//     ),
//   },
//   {
//     value: "R",
//     label: (
//       <span>
//         <img style={{ width: "3rem" }} src={ZAR} alt="ZAR" />
//         <span style={{ marginLeft: "10px" }}>ZAR (R) </span>
//       </span>
//     ),
//   },
// ];
const Settings = (props) => {
  const size = useScreenWidth();
  // const [ClearedDefault, setClearedDefault] = useState(false);
  useEffect(() => {
    props.getUserSettingRequest();
    localStorage.removeItem("isCleared");
  }, []);
  console.log("settingsData", props.settingsData);

  function handleSettingsChange(e, name) {
    if (e && e.target && e.target.value) {
      props.updateUsersSettingsRequest({
        // is_show_date: date,
        // is_show_time: time,
        [name]: String(JSON.parse(e.target?.value)?.currency),
        [name + "_code"]: String(JSON.parse(e.target?.value)?.text)?.split(" ")[0]
      })
      // window.location.reload()
      // props.updateSettingsRequest({ [name]: e.target.value });
    }
    else {
      props.updateUsersSettingsRequest({ [name]: e.toString() });
    }
  }

  return (
    <React.Fragment>
      {props.settingsData && !props.settingsData.loading ? (
        props.settingsData.empty ? (
          <div className="textCenter" style={{ margin: "10rem 0" }}>
            <Empty description="Couldn't retrive settings" />
          </div>
        ) : (
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SwitchButtonCard
                    checked={
                      props.settingsData &&
                      props.settingsData.settings &&
                      props.settingsData.settings.is_cleared_default === "true"
                    }
                    onChange={(e) =>
                      handleSettingsChange(e, "is_cleared_default")
                    }
                    smallPadding
                    heading="Cleared By Default"
                  />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SwitchButtonCard
                    checked={
                      props.settingsData &&
                      props.settingsData.settings &&
                      props.settingsData.settings.is_reconciled === "true"
                    }
                    onChange={(e) => handleSettingsChange(e, "is_reconciled")}
                    smallPadding
                    heading="Hide Reconciled"
                  />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SwitchButtonCard
                    onChange={(e) => handleSettingsChange(e, "sort_order")}
                    sort
                    sort_order={
                      props.settingsData &&
                      props.settingsData.settings &&
                      props.settingsData.settings.sort_order
                        ? props.settingsData.settings.sort_order
                        : null
                    }
                    smallPadding
                    heading="Sort By"
                  />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <DocumentCard heading="Instructions" />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SwitchButtonCard
                    onChange={(e) => handleSettingsChange(e, "sort_order")}
                    sort
                    sort_order={
                      props.settingsData &&
                      props.settingsData.settings &&
                      props.settingsData.settings.sort_order
                        ? props.settingsData.settings.sort_order
                        : null
                    }
                    smallPadding
                    isHide
                    isUpload
                    heading="Upload Bank Statement"
                  />
                </Col>
              </Row>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <SwitchButtonCard
                    onChange={(e) => handleSettingsChange(e, "sort_order")}
                    sort
                    sort_order={
                      props.settingsData &&
                      props.settingsData.settings &&
                      props.settingsData.settings.sort_order
                        ? props.settingsData.settings.sort_order
                        : null
                    }
                    smallPadding
                    isHide
                    isReconcile
                    heading="Reconcile"
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <CardWrapper smallPadding>
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  gutter={[10, 60]}
                  style={{ marginBottom: 0 }}
                >
                  <Col>
                    <h3 className="no-mr">Currency</h3>
                  </Col>
                  <CurrencyContainer
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    style={{
                      maxHeight: "33rem",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <RadioGrid
                      span={size < 992 ? 24 : 12}
                      noBorder
                      centered
                      checked={true}
                      value={
                        props.settingsData &&
                        props.settingsData.settings &&
                        props.settingsData.settings.currency_code
                          ?   JSON.stringify(options.find((v) => v.text.split(" ")[0] === props.settingsData.settings.currency_code))
                          : null
                      }
                      onChange={(e) => handleSettingsChange(e, "currency")}
                      options={options.map((item) => ({
                        value: JSON.stringify(item),
                        label: (
                          <span>
                            <img
                              style={{ width: "2.2rem" }}
                              src={`images/flags/${item.image}`}
                              alt="flag"
                            />
                            <span style={{ marginLeft: "10px" }}>
                              {item.text}
                            </span>
                          </span>
                        ),
                      }))}
                    />
                  </CurrencyContainer>
                </Row>
              </CardWrapper>
            </Col>
          </Row>
        )
      ) : (
        <div className="textCenter" style={{ margin: "15rem 0" }}>
          <Icon type="loading" style={{ fontSize: "3rem" }} />
        </div>
      )}
    </React.Fragment>
  );
};

export default connect(
  (storeState) => ({
    settingsData: storeState.settings,
  }),
  { getUserSettingRequest, updateUsersSettingsRequest }
)(Settings);
