import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import BankName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import JobName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import MonthPicker from "../../../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import DatePicker from "../../../../components/datepickers/DatePicker";
import Info from "../../../../components/message/index";
import { getFieldOptionsRequest } from "../../../../actions/FieldOptions";
//import Select from "../../../../components/input/Select";
//import Option from "../../../../components/input/SelectOptions";
import useBankAccountLedgerForm from "../../forms/reports/useBankAccountLedgerForm";
import { connect } from "react-redux";
import RootForm from "../../../../components/form/index";
import Button from "../../../../components/button";
import { changePassword } from "../../../../configurations/Schemas/index";
import { Row, Col } from "antd";
import { changePasswordRequest } from "../../../../actions/App";
import { bankAccountLedgerRequest,escrowDisclosureStatementRequest } from "../../../../actions/Reports";
import { closeAllModal } from "../../../../actions/Modal";

import moment from "moment";
import { FiInfo } from "react-icons/fi";
import info from "../../../../components/message/index";

const EscrowDisclosureStatement = props => {
  const [yearPickerOpen, setYearPickerOpen] = useState(false);
  // const [yearPickerOpen, setYearPickerOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment());
  const [dateType, setDateType] = useState("All Data");
  const [jobValue, setJobValue] = useState(props.initialValues ? props.initialValues.job_name : undefined);
  const [startDate, setStart_date] = useState("");
  const [endDate, setEnd_date] = useState("");

  const [selectedMonth, setSelectedMonth] = useState("");

  const mainForm = useBankAccountLedgerForm({
    initialValues:
    // props.initialValues ? {
    // ...props.initialValues,
    // ...(props.initialValues.date_type === "custom" && {
    //   start_date: moment().date(props.initialValues.start_day).month(props.initialValues.start_month - 1).year(props.initialValues.start_year),
    //   end_date: moment().date(props.initialValues.end_day).month(props.initialValues.end_month - 1).year(props.initialValues.end_year)
    // })
    // } :
    {
      // bank_account_id: "",
      // customer_id: "",
      // job_id: "",
      date_type: "all",
      start_date: "1971-01-01",
      end_date: "2071-12-31",
    },


    onSubmit: (values) => {
    
      if (mainForm.values.bank_account_id) {
        if (mainForm.values.job_id) {
          if (dateType === "all") {

            // case "All data": {
            let start_day = "01"
            let start_month = "01"
            let start_year = new Date(new Date().setFullYear(new Date().getFullYear() - 50)).getFullYear()
            let end_day = new Date(new Date().setMonth(new Date().getMonth(11) + 1, 0)).getDate()
            let end_month = "12"
            let end_year = new Date(new Date().setFullYear(new Date().getFullYear() + 50)).getFullYear()

            mainForm.values.start_date = `${start_year}-${start_month}-${start_day}`
            mainForm.values.end_date = `${end_year}-${end_month}-${end_day}`
            mainForm.values.date_type = "all"

          }
          // break;
          if (dateType === "12-months") {


            let startDateObj = new Date(new Date().setMonth(new Date().getMonth() - 12, new Date().getDate()))
            let start_day = "01"
            let start_month = startDateObj.getMonth() + 1
            let start_year = startDateObj.getFullYear()
            let end_day = new Date().getDate()
            let end_month = new Date().getMonth() + 1
            let end_year = new Date().getFullYear()

            if (start_month < 10) start_month = `0${start_month}`
            if (end_day < 10) end_day = `0${end_day}`
            if (end_month < 10) end_month = `0${end_month}`

            mainForm.values.start_date = `${start_year}-${start_month}-${start_day}`
            mainForm.values.end_date = `${end_year}-${end_month}-${end_day}`
            mainForm.values.date_type = "12-months"
          }

          if (dateType === "yearly") {
            let start_day = "01"
            let start_month = "01"
            let start_year = new Date().getFullYear()
            let end_day = new Date().getDate()
            let end_month = new Date().getMonth() + 1
            let end_year = new Date().getFullYear()

            if (end_day < 10) end_day = `0${end_day}`
            if (end_month < 10) end_month = `0${end_month}`

            mainForm.values.start_date = `${start_year}-${start_month}-${start_day}`
            mainForm.values.end_date = `${end_year}-${end_month}-${end_day}`
            mainForm.values.date_type = "yearly"
          }

          if (dateType === "monthly") {
            var currentMonth = new Date().getMonth() + 1

            let monthNum = selectedMonth;

            let start_year1 = new Date(selectedYear).getFullYear()
            let end_year1 = new Date(selectedYear).getFullYear()
            // let end_day = new Date().getDate()
            let start_day = "01"
            let end_day = new Date(new Date().setMonth(monthNum, 0)).getDate()

            if (monthNum < 10) monthNum = `0${monthNum}`

            mainForm.values.start_date = `${start_year1}-${monthNum === "0" ? new Date().getMonth() + 1 : monthNum}-${start_day}`
            mainForm.values.end_date = `${end_year1}-${monthNum === "0" ? new Date().getMonth() + 1 : monthNum}-${end_day}`
            mainForm.values.date_type = "monthly"

          }
          return (
            props.escrowDisclosureStatementRequest({ ...values, format: props.format }, report => {
              props.closeAllModal();
              let { start_date, end_date, ...formValues } = values;
              console.log("URL PUSH: ", report);
              console.log(props.history, "lkdlas")
              props.history.push({
                pathname: "/reports/disclosure-statement", state: {
                  referrer: "/reports",
                  referrerState: {
                    ...props.location.state,
                    bankAccountLedgerValues: {
                      formValues,
                      //: values,
                      format: props.format
                    }
                  },
                  report,
                  format: props.format
                }
              })
            })
          )
          

        }
        else {
          info("warning","please select any job")    
        }
      }
     
      else {
        info("warning","please select any bank account")
      }
     

     
    }

   
  })

  console.log("FORM", mainForm)
 
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      className="textCenter"
      gutter={[0, 30]}
    >
      <Col span={24} style={{ padding: 0 }}>
        <h4 className="mr-b mr-t-md" style={{ marginBottom: "2.5rem" }}>
          Escrow Disclosure Statement
        </h4>
      </Col>

      <Col xs={24} sm={20} md={22} lg={16}>
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "left" }}>
            <BankName
              className="inputField"
              name="bank_account_id"
              placeholder="Select Bank Account"
              withoutAddButton
              hideDeleteBtn
              // withAllOption
              initialValue={mainForm.values.bank_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "bank_account_id");
              mainForm.customHandleChange(name, "bank_name");
              // mainForm.setFieldValue("customer_name", "All Customer");
              mainForm.setFieldValue("job_name", "Select Any Job");
              setJobValue("Select Any Job");
              }}
              onBlur={e => mainForm.customHandleBlur(e, "bank_account_id")}
            />
            {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["bank_account_id"] && mainForm.errors["bank_account_id"]}
            </div> : null}
          </Col>

          {/* <Col span={24} style={{ textAlign: "left" }}>
            <OptionsField
              className="inputField"
              //title="Lawyer Name"
              //addBtnText="Add Lawyer"
              name="lawyer_customer_id"
              //link="/add-contact"
              contactType="customer"
              placeholder="All Customer"
              withoutAddButton
              disabled={mainForm.values.bank_account_id ? false : true}
              withClearOption
              id={mainForm.values.bank_account_id}
              isDisplay={!!mainForm.values.bank_account_id}
              initialValue={mainForm.values.customer_name}
              lawyerValue={mainForm.values.customer_name}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "customer_id");
                mainForm.customHandleChange(name, "customer_name");
              }}
              onBlur={e => mainForm.customHandleBlur(e, "lawyer_id")}
            />
            {mainForm.touched["customer_id"] && mainForm.errors["customer_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["customer_id"] && mainForm.errors["customer_id"]}
            </div> : null}
          </Col> */}

          <Col span={24} style={{ textAlign: "left" }}>
            <JobName
              className="inputField"
              name="job_id"
              jobValue={jobValue}
              withJobValue
              placeholder="Select Any Job"
              withoutAddButton
              hideDeleteBtn
              disabled={mainForm.values.bank_account_id ? false : true}
              id={mainForm.values.bank_account_id}
              isDisplay={!!mainForm.values.bank_account_id}
              //withAllOption={mainForm.values.report_type !== "detail"}
              onChange={(id, name) => {
                mainForm.customHandleChange(id, "job_id");
                mainForm.customHandleChange(name, "job_name");
                setJobValue(name || id);
              }}
              onBlur={e => mainForm.customHandleBlur(e, "job_id")}
            />
            {mainForm.touched["job_id"] && mainForm.errors["job_id"] ? <div className="dangerColor caption mr-b">
              {mainForm.touched["job_id"] && mainForm.errors["job_id"]}
            </div> : null}
          </Col>



          {mainForm.values.date_type === "custom" && (
            <React.Fragment>
              <Col span={24} style={{ textAlign: "left" }}>
                <DatePicker
                  allowClear={false}
                  //open={yearPickerOpen}
                  //onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="inputField"
                  name="start_date"
                  value={mainForm.values.start_date}
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  //onFocus={() => setYearPickerOpen(true)}
                  onBlur={e => {
                    mainForm.customHandleBlur(e, "start_date");
                    //setYearPickerOpen(false);
                  }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="MM-DD-YYYY"
                  //mode="year"
                  placeholder="Start Date"
                  onChange={e => {
                    // if(!mainForm.values.date_type === "quaterly") 
                    //   mainForm.customHandleChange(e.year(), "year")
                    // else
                    //   mainForm.onChangeQuarterYear(e, "year")
                    mainForm.onStartDateChange(e, "start_date")

                    //setYearPickerOpen(false);
                  }}
                />
                {mainForm.touched["start_date"] && mainForm.errors["start_date"] ? <div className="dangerColor caption mr-b">
                  {mainForm.touched["start_date"] && mainForm.errors["start_date"]}
                </div> : null}
              </Col>

              <Col span={24} style={{ textAlign: "left" }}>
                <DatePicker
                  allowClear={false}
                  //open={yearPickerOpen}
                  //onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="inputField"
                  name="end_date"
                  value={mainForm.values.end_date}
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  //onFocus={() => setYearPickerOpen(true)}
                  onBlur={e => {
                    mainForm.customHandleBlur(e, "end_date");
                    //setYearPickerOpen(false);
                  }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="MM-DD-YYYY"
                  //mode="year"
                  placeholder="End Date"
                  onChange={e => {
                    // if(!mainForm.values.date_type === "quaterly") 
                    //   mainForm.customHandleChange(e.year(), "year")
                    // else
                    //   mainForm.onChangeQuarterYear(e, "year")
                    mainForm.onEndDateChange(e, "end_date")

                    //setYearPickerOpen(false);
                  }}
                />
                {mainForm.touched["end_date"] && mainForm.errors["end_date"] ? <div className="dangerColor caption mr-b">
                  {mainForm.touched["end_date"] && mainForm.errors["end_date"]}
                </div> : null}
              </Col>
            </React.Fragment>
          )
          }
          <Col xs={24} lg={24}>
            <Select
              //className="inputField"
              name="date_type"
              placeholder="Date Type"
              value={dateType}
              onChange={(dateType) => {
                console.log(dateType, "sakdjask")
                setDateType(dateType)

              }


              }

              style={{ width: "100%" }}
            // noBorder
            >
              <Option value="monthly">Monthly</Option>
              <Option value="12-months">12 Months</Option>
              <Option value="yearly">Current Year</Option>
              <Option value="all">All Data</Option>
            </Select>
          </Col>
          {dateType === 'monthly' ? (
            <>
              <Col xs={24} lg={24} md={6}>
                <MonthPicker
                  value={selectedMonth === "" ? new Date().getMonth() + 1 : selectedMonth}
                  // onChange={(val) => setSelectedMonth(val)}
                  onChange={(e) => {
                    return (
                      setSelectedMonth(e),
                      mainForm.values.month = selectedMonth
                    )
                  }
                  }
                />
              </Col>
              <Col span={24} style={{ textAlign: "left" }}>
                <DatePicker
                  allowClear={false}
                  open={yearPickerOpen}
                  onOpenChange={open => setYearPickerOpen(open)}
                  modalField
                  className="inputField"
                  name="year"
                  value={selectedYear === "" ? new Date().getYear() : selectedYear}
                  placeholder="Month"
                  //onChange={e => mainForm.customHandleChange(e, "year")}
                  onFocus={() => setYearPickerOpen(true)}
                  onBlur={e => {
                    mainForm.customHandleBlur(e, "year");
                    setYearPickerOpen(false);
                  }}
                  style={{ width: "100%" }}
                  //picker="year"
                  format="YYYY"
                  mode="year"
                  placeholder="Year"
                  onPanelChange={(e) => {
                    return (
                      mainForm.values.year=setSelectedYear(e),
                      // mainForm.values.year = selectedYear,
                      setYearPickerOpen(false)
                    
                    )
                  }}
                />
                {mainForm.touched["year"] && mainForm.errors["year"] ? <div className="dangerColor caption mr-b">
                  {mainForm.touched["year"] && mainForm.errors["year"]}
                </div> : null}
              </Col>

            </>
          ) : null}

          <Col span={16} className="mr-t-md">
            <Button loading={props.reports.disclosureStatementLoading} block onClick={mainForm.handleSubmit}>Preview Report</Button>
          </Col>

          <Col span={16} className="mr-t">
            <Button block outlined onClick={props.closeAllModal}>Cancel</Button>
          </Col>

        </Row>

      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports }), { escrowDisclosureStatementRequest, closeAllModal, getFieldOptionsRequest })(EscrowDisclosureStatement));
