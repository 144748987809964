import React from "react";
import { Row, Col } from "antd";
import Card from ".././../../components/card";
import Switch from ".././../../components/switch";
import { FaSort } from "react-icons/fa";
import SortingIcon from "../../../assets/icons/sorting1.svg"
import { FiArrowRight } from "react-icons/fi";
import { MdKeyboardArrowRight } from "react-icons/md";
//import { withRouter } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import Lander from "../../../assets/images/lander.png";
import Lander2 from "../../../assets/images/lander2.png";
import Image from ".././../../components/image";
const ClearedCard = (props) => {
  console.log("sort_order", props);
  
  const location = useLocation();
  const history = useHistory();
  return (
    <Card smallPadding={props.smallPadding}>
      <Row type="flex" gutter={[0, 35]}>
        <Col span={14} >
          <h3 style={{fontSize:"13px"}}>{props.heading ? props.heading : "Cleared"}</h3>
        </Col>
        <Col span={10} style={{ textAlign: "center" }}>
          {props.sort && !props.isHide ? (
            <React.Fragment>
              {props.sort_order === "desc" ? (
                <span style={{ opacity: "0.5" }}>Descending</span>
              ) : (
                <span style={{ opacity: "0.5" }}>Ascending</span>
              )}
              <img 
              src ={SortingIcon}
              alt="Sorting Icon Not Found"
                style={{ cursor: "pointer" ,marginLeft:"5px"}}
                onClick={() =>
                  props.onChange(props.sort_order === "desc" ? "asc" : "desc")
                }
                className="appColor"
              />{" "}
            </React.Fragment>
          ) : !props.isHide ? (
            <Switch {...props} />
          ) : props.isUpload ? <Image src={Lander} style={{cursor:"pointer"}} onClick={() => {
            history.push({pathname: "/upload-bank-statement",
            //pathname: `/send-transactionimage`,
            //state: props.location.state,
            state: {
              referrer: location.pathname,
              // referrerState: {
              //   initialValues: values
              // },
            }
          });
          }}>

          </Image> :
          <Image src={Lander2} style={{cursor:"pointer"}} onClick={() => {
            history.push({pathname: "/reconcile",
            //pathname: `/send-transactionimage`,
            //state: props.location.state,
            state: {
              referrer: location.pathname,
              // referrerState: {
              //   initialValues: values
              // },
            }
          });
          }}></Image>}
        </Col>
      </Row>
    </Card>
  );
};

export default ClearedCard;
