import React, { useState, useRef, useEffect } from "react";
import General from "./General";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getFieldOptionsRequest } from "../../../../../actions/FieldOptions";

const OptionsFields = props => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(props.initialValue ? { value: props.initialValue } : {});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const apiCall = useRef();

  useEffect(() => {
    if(props.customerValue) {
      setValue({ value: props.customerValue })
    }
  }, [props.customerValue])

  useEffect(() => {
    if(props.lawyerValue) {
      setValue({ value: props.lawyerValue })
    }
  }, [props.lawyerValue])

  useEffect(() => {
    apiCall.current = 
    debounce(
      e => {
        console.log("api call", e);
        setLoading(!options.length);
        props.getFieldOptionsRequest(props.contactType, props.id, (error, data) => {
          if (error) {
            setLoading(false);
          } else {
            console.log("lawyer name data", data);
            setLoading(false);
            let viewAll = props.withClearOption ? ["View All"] : [];
            setOptions([...viewAll, ...data]);
          }
        });
      },
      1000,
      true
    )
  }, [options, props.id])

  useEffect(() => {
    setOptions([]);
    apiCall.current();
  }, [props.id]);

  useEffect(() => {
    if(props.getFromBankLawfirm) {
      setSelectLoading(true);
      props.getFieldOptionsRequest(undefined, undefined, (error, data) => {
        if (error) {
          setSelectLoading(false);
        } else {
          setSelectLoading(false);

          const fromLawfirm = data.find(val => val.contact_id == props.getFromBankLawfirm);
          if(fromLawfirm) {
            let id = fromLawfirm.contact_id, name = fromLawfirm.full_name;

            props.onChange(id, name);
            setValue({ value: name });
          }
        }
      });
    }
  }, [props.getFromBankLawfirm])

  //   const history = useHistory();
  console.log("Lawer props==>", props);
  //   let { ...props } = props;

  //
  return (
    <div>
      <General
        {...props}
        {...value}  
        optionFilterProp={"searchValue"}
        loading={selectLoading}
        onChange={e => {
          if (props.onChange) {
            if (e) {
              props.onChange(e.split("*")[0], e.split("*")[1]);
            } else {
              props.onChange(e);
            }
            setValue({ value: e })
          }
        }}
        addBtnClick={() =>
          props.history.push({
            pathname: props.link,
            state: { referrer: "/add-transaction",
            contactType:props.contactType === "payor" ? "other" : props.contactType
           }
          })
        }
        onSearch={Search => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={open => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          options.map((item, index) => (
            item === "View All" ? (
              <SelectOptions key={index} value="">
                All {props.contactType}s
              </SelectOptions>
            ) : (
            <SelectOptions
              searchValue={[item.lawyer_name, item.customer_name, item.full_name, item.lawyer_email, item.customer_email, item.email, item.contact_type]}
              key={props.contactType==="lawyer" && props.isDisplay ? item.lawyer_id : props.contactType==="customer" && props.isDisplay ? item.customer_id : item.contact_id}
              value={`${props.contactType==="lawyer" && props.isDisplay ? item.lawyer_id : props.contactType==="customer" && props.isDisplay ? item.customer_id : item.contact_id}*${props.contactType==="lawyer" && props.isDisplay ? item.lawyer_name : props.contactType==="customer" && props.isDisplay ? item.customer_name : item.full_name}`}
            >
              <Row
                  type="flex"
                  style={{ width: "100%" }}
              >
                <Col span={24}>
                  <div>
                    {props.contactType==="lawyer" && props.isDisplay ? item.lawyer_name : props.contactType==="customer" && props.isDisplay ? item.customer_name : item.full_name}&nbsp;<span className="hideMe" style={{ textTransform: "capitalize" }}>{!props.isDisplay ? (item.contact_type) : null}</span>
                  </div>
                  <div className="hideMe">
                    {props.contactType==="lawyer" && props.isDisplay ? item.lawyer_email : props.contactType==="customer" && props.isDisplay ? item.customer_email : item.email}
                  </div>
                </Col>
              </Row>
            </SelectOptions>
            )
          ))
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default withRouter(
  connect(null, { getFieldOptionsRequest })(OptionsFields)
);
