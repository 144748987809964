import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
// import ScreenWidth from "../../components/hooks/useScreenWidth";
import Button from "../../components/button";
import TransferTotalCard from "../../appcomponents/EscrowTrakker/dashboard/TransferTotalCard";
import BankAccountsCard from "../../appcomponents/EscrowTrakker/dashboard/BankAccountsCard";
import { getBankAccountsRequest } from "../../actions/BankAccount";
import Icon from "../../components/Icon";
import useScroll from "../../components/hooks/useScrollToBottom";
import { FiAlertOctagon, FiPlus } from "react-icons/fi";
import useScreenWidth from "../../components/hooks/useScreenWidth";
import SearchWithFilter from "../../appcomponents/general/ContentHeaderComponents/SearchWithFilter";
import {AccountingFormat} from "../../configurations/Config"


const MainBankAccount = props => {
  //const [Tbal, setTbal] = useState("0");
  //const [Tclear, setTclear] = useState("0");
  //const [bankAccounts, setBankAccounts] = useState([]);
  const scrolled = useScroll();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(1000000);
  const [hasChanged, setHasChanged] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [bankAccountList, setBankAccountList] = useState([]);
  const banks = props.allBanks;

  let {
    totalBalance,
    totalCleared,
    currency,
    bankAccounts,
    totalCount,
    bankStatsLoading,
    bankAccountsLoading
  } = props.allBanks;
  const searchChange = (text) => {
    setFilterText(text);
    
    let filteredBankAccounts = bankAccounts.filter((bank) => bank.bank_account_name.toLowerCase().includes(text.toLowerCase()) || bank.law_firm_name.toLowerCase().includes(text.toLowerCase()) || bank.balance.toFixed(2).toString().includes(text) || AccountingFormat(bank.balance,currency).includes(text))
    setBankAccountList(filteredBankAccounts);
   //  if(!filteredBankAccounts.length){
   //    setBankAccountList(...bankAccounts);
   //  }
  };
  
  // useEffect(() => {
  //   props.getBankAccountsRequest();
  // }, []);
  const apiCall = (limit, offset) => {
    props.getBankAccountsRequest(
      limit,
      offset
      //,
      //   () => {
      //   setOffset(offset + limit);
      // }
    );
  };

  useEffect(() => {
    apiCall(limit, offset);
    setBankAccountList(bankAccounts);
  }, []);

  //On scroll effect
  // useEffect(() => {
  //   if (bankAccounts.length != totalCount && !bankAccountsLoading) {
  //     apiCall(limit, offset);
  //   }
  //   setBankAccountList(bankAccounts);
  // }, [scrolled]);

  useEffect(() => {
    setOffset(bankAccounts.length);
    setBankAccountList(bankAccounts);
  }, [bankAccounts.length]);

  // useEffect(() => {
  //   if (banks.bankLists) {
  //     const balCheck = typeof banks.bankLists.total_balance !== "undefined";
  //     const clearCheck = typeof banks.bankLists.total_cleared_balance !== "undefined";
  //     setTbal(balCheck ? banks.bankLists.total_balance : 0);
  //     setTclear(clearCheck ? banks.bankLists.total_cleared_balance : 0);
  //     setBankAccounts(banks.bankLists.bank_accounts);
  //   }
  // }, [banks.bankLists]);
  const size = useScreenWidth();
  console.log("props.allBanks",bankAccounts)
  return (
    <React.Fragment>
      <React.Fragment>
        <Row
          gutter={[40, 40]}
          type="flex"
          justify="center"
          align="stretch"
          className="textCenter"
        >
          <Col xs={24} md={12} lg={8}>
            <TransferTotalCard
              heading="Total Balance"
              // label="bank name"
              amount={
                bankStatsLoading ? <Icon type="loading" /> : AccountingFormat(totalBalance,currency)
              }
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <TransferTotalCard
              heading="Total Cleared"
              amount={
                bankStatsLoading ? <Icon type="loading" /> : AccountingFormat(totalCleared,currency)
              }
            />
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Row
              type="flex"
              align="center"
              justify="center"
              style={{ height: "100%", alignItems: "center" }}
            >
              <Col xs={24} md={16}>
                <Button block onClick ={()=>props.history.push("/transfer-journal")}>View Transfer Journal</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          gutter={[40, 100]}
          type="flex"
          justify="center"
          align="middle"
          className="textCenter no-mr"
        >
          <Col span={24} style={{paddingBottom:"0px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <h1 style={{width:"102%"}}>
              {size >= 992 ? null : (
                <Button
                  className="iconBtn"
                  onClick={() => props.history.push("/add-bank")}
                >
                  <FiPlus />
                </Button>
              )}{" "}
              Bank Accounts
            </h1>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{paddingTop:"0px"}}>
            <Row style={{display: "flex", justifyContent: "center", alignItems: "center"}} gutter={[0, 0]} >
              <Col xs={24} sm={12} >
                <SearchWithFilter
                  onChange={(e) => searchChange(e)}
                  value={filterText}
                  
                />
                </Col>
              </Row>
            </Col>
        </Row>
        <Row
          gutter={[40, 40]}
          type="flex"
          align="stretch"
          className="textCenter"
        >
          {!bankAccountsLoading && !bankAccountList.length ? (
            // <div className="textCenter" style={{ width: "100%" }}>
            //   <Icon type="loading" style={{ fontSize: "4rem" }} />
            // </div>
            <Col span={24}>
              <FiAlertOctagon />
              <h3 className="textCenter"> No Bank Exist</h3>
            </Col>
          ) : (
            <React.Fragment>
              {bankAccountList.map(bank => (
                <Col xs={24} md={12} lg={8}>
                  <BankAccountsCard val={bank} />
                </Col>
              ))}
              {bankAccountsLoading && (
                <Col xs={24}>
                  <div className="textCenter" style={{ width: "100%" }}>
                    <Icon type="loading" style={{ fontSize: "3.5rem" }} />
                  </div>
                </Col>
              )}
            </React.Fragment>
          )}

          {/* !bankAccounts.length ? (
              <Col span={24}>
                <FiAlertOctagon />
                <h3 className="textCenter"> No Bank Exist</h3>
              </Col>
            ) : (
              bankAccounts.map(bank => (
                <Col xs={24} md={12} lg={8}>
                  <BankAccountsCard val={bank} />
                </Col>
              ))
            )} */}
        </Row>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    allBanks: state.AddBankAccount
  };
};
export default connect(mapStateToProps, { getBankAccountsRequest })(
  MainBankAccount
);
