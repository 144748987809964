import { css } from "styled-components";

export const StripeStyle = css`
& {
    background-color: transparent;
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    border-radius: 5px;
    /* border-top: 0;
    border-right: 0;
    border-left: 0; */
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme[props.theme.mode].primary.main}66;
    /* font-style: italic; */
    padding: 0.7vw 0.8vw;
    
}
&:hover {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
}
&.StripeElement--focus {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  //box-shadow: none;
  box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
&:active {
  border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border-color: ${props => props.theme[props.theme.mode].primary.main} !important;
  //box-shadow: 0 0 0 2px ${props => props.theme[props.theme.mode].primary.main}66;
}
* {
  color: ${props => props.theme[props.theme.mode].primary.main};
}
@media screen and (max-width: 991px) {
  & {
    padding: 0.5rem;
  }
}
`;

// const StripeWrapper = (props) => {
//   console.log("CLASSNAME", props)
//   let StripeElement = props.stripeElement;
//   return (
//     <StripeElement className={props.className} style={
//       base: {
//         color: ,
//         fontSize: '16px',
//       }
//     }/>
//   )
// }

// export default styled(StripeWrapper).attrs({className: 'inputField'})`${StripeStyle}`;
