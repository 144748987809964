import React from "react";
import { connect } from "react-redux";
import Card from "../../../../components/card/index";
import Tag from "../../../../components/tag";
import { CardNumberElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Row, Col } from "antd";
import Button from "../../../../components/button/index";
import Input from "../../../EscrowTrakker/formcontrols/InputWithTitle/index";
import Select from "../../../EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/General";
import Options from "../../../../components/input/SelectOptions";
import Countries from "../../../../utils/jsons/CountriesWithCode.json";
import CardCVCWrapper from "../../../../components/stripeelements/CardCVCWrapper";
import CardNumberWrapper from "../../../../components/stripeelements/CardNumberWrapper";
import CardExpiryWrapper from "../../../../components/stripeelements/CardExpiryWrapper";
import useBuySubscriptionForm from "../../../EscrowTrakker/forms/subscription/useBuySubscriptionForm";
import { buySubscriptionRequest } from "../../../../actions/Subscription";

const plans = {
  basic: {
    price: "$9.99/month",
    features: [
      "2 Bank Accounts",
      "15 Customer Accounts",
      "Multi-Platform",
      "Multi-Device Login",
      "15 Custom Accounts"
    ]
  },
  professional: {
    price: "$39.99/month",
    features: [
      "10 Bank Accounts",
      "100 Customer Accounts",
      "Multi-Platform",
      "Multi-Device Login",
      "50 Custom Accounts"
    ]
  },
  enterprise: {
    price: "$69.99/month",
    features: [
      "200 Bank Accounts",
      "1000 Customer Accounts",
      "Multi-Platform",
      "Multi-Device Login",
      "100 Custom Accounts"
    ]
  },
  "professional annum": {
    price: "$24.99/year",
    features: [
      "20 bank accounts",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  },
  "enterprise annum": {
    price: "$64.99/year",
    features: [
      "200 bank accounts",
      "No other restrictions",
      "Multi-Platform",
      "Multi-Device Login",
      //"No auto-renew"
    ],
  },
};

const StripeForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const mainForm = useBuySubscriptionForm({
    initialValues: {
      email: "",
      cardNumber: 0,
      expiryDate: 0,
      cvc: 0,
      name: "",
      //country: ""
    },

    onSubmit: values => {
      const cardNumberElement = elements.getElement(CardNumberElement);

      // stripe.createToken(cardNumberElement, {
      //   name: values.name,
      //   email: values.email,
      //   address_country: values.country
      // }).then(result => {
      //   console.log("ABCDE", result)
      //   // Handle result.error or result.token
      // });

      // stripe.createPaymentMethod({
      //   type: "card",
      //   card: cardNumberElement,
      //   billing_details: {
      //     name: values.name,
      //     email: values.email,
      //     address: {
      //       country: values.country
      //     }
      //   },
      // }).then(result => {
      //   console.log("ABCDE", result);
      //   props.buySubscriptionRequest({
      //     receipt_data: result,
      //     package_name: props.plan
      //   })
      // })

      props.buySubscriptionRequest(() => 
        stripe.createPaymentMethod({
          type: "card",
          card: cardNumberElement,
          billing_details: {
            name: values.name,
            email: values.email,
            address: {
              country: values.country
            }
          },
        }), { package_name: props.plan }, props.callback
      )
    }
  });

  console.log("FORM", mainForm);

  return (
    <Card smallPadding>
      <Row type="flex" justify="center" align="middle" style={{ margin: "3rem 0" }}>
        <Col xs={24} lg={18}>
          <Row type="flex" gutter={[0, 30]}>
            <Col span={24}>
              <Card smallPadding>
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    <h4 className="no-mr">{props.plan
                  ? props.plan.split(" ").map(word => word[0].toUpperCase() +
                  word.substring(1)).join(" ")
                  : ""}</h4>
                  </Col>
                  <Col>
                    {" "}
                    <Tag style={{ width: "10rem" }}>{plans[props.plan].price}</Tag>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col xs={24} lg={24}>
              <Row type="flex" gutter={[15, 15]}>
                <Col span={24}>
                  <Input
                    title="Email"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={e => mainForm.customHandleChange(e, "email")}
                    onBlur={e => mainForm.customHandleBlur(e, "email")}
                  />
                  {mainForm.touched["email"] && mainForm.errors["email"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["email"] && mainForm.errors["email"]}
                    </div>
                  ) : null}
                </Col>

                <Col span={24}>
                  <Input
                    name="cardNumber"
                    customInput={CardNumberWrapper}
                    title="Card Details"
                    placeholder="CVC"
                    onReady={e => {
                      if(e._empty)
                        mainForm.customHandleChange(0, "cardNumber")
                      else if(e._invalid)
                        mainForm.customHandleChange(-1, "cardNumber")
                      else if(e._complete)
                        mainForm.customHandleChange(1, "cardNumber")
                    }}
                    onChange={e => {
                      if(e.empty) 
                        mainForm.customHandleChange(0, "cardNumber")
                      else if(e.error)
                        mainForm.customHandleChange(-1, "cardNumber")
                      else if(e.complete)
                        mainForm.customHandleChange(1, "cardNumber")
                    }}
                    onBlur={e => mainForm.customHandleBlur(e, "cardNumber")}
                  />
                  {mainForm.touched["cardNumber"] && mainForm.errors["cardNumber"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["cardNumber"] && mainForm.errors["cardNumber"]}
                    </div>
                  ) : null}
                </Col>

                <Col span={12}>
                  <CardExpiryWrapper
                  name="expiryDate"
                  onReady={e => {
                    if(e._empty)
                      mainForm.customHandleChange(0, "expiryDate")
                    else if(e._invalid)
                      mainForm.customHandleChange(-1, "expiryDate")
                    else if(e._complete)
                      mainForm.customHandleChange(1, "expiryDate")
                  }}
                  onChange={e => {
                    if(e.empty) 
                      mainForm.customHandleChange(0, "expiryDate")
                    else if(e.error)
                      mainForm.customHandleChange(-1, "expiryDate")
                    else if(e.complete)
                      mainForm.customHandleChange(1, "expiryDate")
                  }}
                  onBlur={e => mainForm.customHandleBlur(e, "expiryDate")}
                  />
                  {mainForm.touched["expiryDate"] && mainForm.errors["expiryDate"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["expiryDate"] && mainForm.errors["expiryDate"]}
                    </div>
                  ) : null}
                </Col>


                <Col span={12}>
                  <CardCVCWrapper 
                  name="cvc"
                  onReady={e => {
                    if(e._empty)
                      mainForm.customHandleChange(0, "cvc")
                    else if(e._invalid)
                      mainForm.customHandleChange(-1, "cvc")
                    else if(e._complete)
                      mainForm.customHandleChange(1, "cvc")
                  }}
                  onChange={e => {
                    if(e.empty) 
                      mainForm.customHandleChange(0, "cvc")
                    else if(e.error)
                      mainForm.customHandleChange(-1, "cvc")
                    else if(e.complete)
                      mainForm.customHandleChange(1, "cvc")
                  }}
                  onBlur={e => mainForm.customHandleBlur(e, "cvc")}
                  />
                  {mainForm.touched["cvc"] && mainForm.errors["cvc"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["cvc"] && mainForm.errors["cvc"]}
                    </div>
                  ) : null}
                </Col>

                <Col span={24}>
                  <Input
                    name="name"
                    title="Name On Card"
                    placeholder="Enter your name on card"
                    onChange={e => mainForm.customHandleChange(e, "name")}
                    onBlur={e => mainForm.customHandleBlur(e, "name")}
                  />
                  {mainForm.touched["name"] && mainForm.errors["name"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["name"] && mainForm.errors["name"]}
                    </div>
                  ) : null}
                </Col>

                {/* <Col span={24}>
                  <Select
                    name="country"
                    withoutAddButton
                    hideDeleteBtn
                    title="Country or Region"
                    placeholder="Select Country"
                    onChange={e => mainForm.customHandleChange(e && e.split("*")[0], "country")}
                    onBlur={e => mainForm.customHandleBlur(e, "country")}
                  >
                    {Countries.map(country => (
                      <Options value={`${country.code}*${country.name}`}>
                        {country.name}
                      </Options>
                    ))}
                  </Select>
                  {mainForm.touched["country"] && mainForm.errors["country"] ? (
                    <div className="dangerColor caption mr-t">
                      {mainForm.touched["country"] &&
                        mainForm.errors["country"]}
                    </div>
                  ) : null}
                </Col> */}
                <Col span={24}>
                  <Button block onClick={mainForm.handleSubmit} loading={props.subscription.buySubscriptionLoading}>
                    Pay {plans[props.plan].price}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(state => ({ subscription: state.Subscription }), { buySubscriptionRequest })(StripeForm);
