import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
// import { openModal, closeAllModal } from "../../actions/Modal";
import PopconfirmWrapper from "../../components/pop-confirm";
// import ButtonWrapper from "../../components/button";
// import { MdDelete } from "react-icons/md";
import TableWrapper from "../../components/react-table";
import TagWrapper from "../../components/tag";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  // searchTransactionsRequest,
  deleteTransactionRequest,
} from "../../actions/SearchTransactions";
import { getTransferRequest } from "../../actions/TransferDetails";
import { FiEdit, FiMoreHorizontal, FiAlertTriangle } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Divider } from "antd";
import { current_sprint } from "../../configurations/Constants";
import PopOver from "../../components/popover";
import BankFieldName from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import MonthPickerFilter from "../../appcomponents/EscrowTrakker/detailbanks/FiltersTransaction";
import YearPicker from "../../components/datepickers/DatePicker";
import HeaderWrapper from "../../components/header/index";
import PlusButton from "../../appcomponents/EscrowTrakker/formcontrols/IconButton";
import { AccountingFormat } from "../../configurations/Config";

let map = {
  DEPOSIT: "primary",
  WITHDRAWAL: "secondary",
  RECURRING: "third",
  TRANSFER: "fourth",
};

const TransferDetails = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment());
  const [tableChanged, setTableChanged] = useState(false);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    month: props.location.state
      ? props.location.state.month
      : "",
    year: props.location.state
      ? props.location.state.year
      : moment().format("YYYY"),
    bank_account_id: props.location.state
      ? props.location.state.bank && props.location.state.bank.bankId
      : "",
  });
  const [state] = useState({
    apiCall: (...params) => props.getTransferRequest(...params),
  });

  const [bank, setBank] = useState(
    props.location.state ? props.location.state.bank : null
  );

  const handleBankName = (e, bankName) => {
    // console.log("serachChange", e);
    setSearch(e);
    setFilters({ ...filters, bank_account_id: e });
    setBank({ bankId: e, bankName });
  };

  const handleYearChange = (val) => {
    setOpen(false);
    setFilters({ ...filters, year: val.format("YYYY") });
    setSelectedYear(val);
  };

  const handleFilterChange = (val) => {
    setFilters({ ...filters, month: val });
  };

  console.log(
    "Transfer Transaction Props",
    props.transactionsData.transferTransactions
  );
  return (
    <Row type="flex" align="middle" justify="space-between" gutter={[10, 20]}>
      <Col xs={24} sm={24} lg={24} xl={24}>
        <HeaderWrapper
          maintainSpans
          bisect
          // style={{marginLeft:"auto"}}
          externalscreen={1}
          rightAlign={[
            <PlusButton
              style={{ marginLeft: "auto" }}
              onClick={() =>
                props.history.push({
                  pathname: "/add-transaction",
                  state: {
                    tab: "transfer",
                    bank,
                    addType: true,
                  },
                })
              }
            >
              {/* {props.addBtnText} */}Add Transfer
            </PlusButton>,
          ]}
        />
      </Col>
      <Col xs={24} md={8}>
        {/* <Row type="flex" justify="start" align="middle" gutter={[20, 20]}> */}
        {/* <Col span={18}> */}
        <BankFieldName
          placeholder="Select Bank Account"
          addBtnText="Add Bank Account"
          withoutAddButton
          withAllOption
          initialValue={bank && bank.bankName}
          name="bank_account_id"
          onChange={handleBankName}
        />
        {/* </Col> */}
        {/* </Row> */}
      </Col>

      <Col xs={24} md={10}>
        <Row type="flex" justify="end" align="middle" gutter={[30, 10]}>
          <Col xs={24} sm={12} md={9}>
            <MonthPickerFilter
              months={{
                value: filters.month,
                onChange: (val) => handleFilterChange(val),
              }}
            />
          </Col>

          <Col xs={24} sm={12} md={9}>
            <YearPicker
              style={{ width: "100%" }}
              className="no-mr"
              allowClear={false}
              open={isOpen}
              picker="year"
              filters={filters}
              value={selectedYear}
              onOpenChange={(open) => setOpen(open)}
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              format="YYYY"
              noBorder
              mode="year"
              placeholder="Year"
              onPanelChange={handleYearChange}
            />
          </Col>
        </Row>
      </Col>

      {current_sprint > 1 ? (
        <Col span={24} style={{ margin: "40px 0px 50px 0px" }}>
          <TableWrapper
            tableData={props.transactionsData.transferTransactions}
            getData={state.apiCall}
            // search={search}
            filters={filters}
            pageSize={10}
            dataCount={parseInt(props.transactionsData.totalCount)}
            getTrProps={(state, rowInfo, column) => ({
              onClick: () =>
                props.history.push({
                  pathname: `/view-transaction/${rowInfo.original[0].transaction_source_id}`,
                  state: {
                    referrer: "/transfer-journal",
                    referrerState: {
                      month: filters.month,
                      year: filters.year,
                      bank,
                    },
                  },
                }),
              style: { cursor: "pointer" },
            })}
            // loading={props.transactionsData.tableLoading}
            //enableReInit={true}
            tableChanged={tableChanged}
            content={[
              {
                name: "Bank Account",
                id: "1",
                style: { color: "red" },
                render: (r) => (
                  <span>
                    {r.original[0].bank_account_name !== ""
                      ? r.original[0].bank_account_name
                      : "-"}
                  </span>
                ),
              },
              {
                name: "From",
                id: "2",
                render: (r) => (
                  console.log("r.original check kro", r.original),
                  (
                    <span
                      className={
                        r.original[0].account_type === "income"
                          ? "appColor"
                          : "secondaryAppColor"
                      }
                    >
                      {r.original[0].account_name !== ""
                        ? r.original[0].account_name
                        : "-"}
                    </span>
                  )
                ),
              },
              {
                name: "To",
                id: "3",
                render: (r) => (
                  console.log("r.original check kro", r.original),
                  (
                    <span
                      className={
                        r.original[1].is_split === "true"
                          ? null
                          : r.original[1].account_type === "income"
                          ? "appColor"
                          : "secondaryAppColor"
                      }
                    >
                      {r.original[1].is_split === "true" ? (
                        <span style={{ fontStyle: "italic" }}> Splits </span>
                      ) : r.original[1] ? (
                        r.original[1].account_name !== "" ? (
                          r.original[1].account_name
                        ) : (
                          "-"
                        )
                      ) : (
                        "-"
                      )}
                    </span>
                  )
                ),
              },
              {
                name: "Date",
                id: "4",
                render: (r) => (
                  <span>
                    {r.original[0].date_of_transaction
                      ? moment(r.original[0].date_of_transaction).format(
                          "MM/DD/YYYY"
                        )
                      : "-"}
                  </span>
                ),
              },

              {
                name: "Amount",
                id: "5",
                render: (r) => (
                  <span
                  style={{
                    color: 
                    // r.original[0].is_split === "true" ? "" :
                     "#7787FF",
                  //   fontStyle:
                  //     r.original[0].is_split === "true" ? "italic" : ""
                  }}
                  >
                    {r.original[0].is_split === "true"
                      ? AccountingFormat(
                          r.original[0].amount,
                          r.original[0].currency
                        )
                      : AccountingFormat(
                          r.original[0].amount,
                          r.original[0].currency
                        )}
                  </span>
                ),
              },

              {
                name: "Transaction",
                id: "6",
                large: true,
                render: (r) => (
                  <React.Fragment>
                    <TagWrapper
                      style={{ width: "100%" }}
                      className="small"
                      type={map[r.original[0].transaction_type.toUpperCase()]}
                    >
                      {r.original[0].transaction_type === "transfer"
                        ? "Transfer"
                        : r.original[0].transaction_type
                        ? r.original[0].transaction_type
                        : "-"}
                    </TagWrapper>
                    <span style={{ marginLeft: "1rem" }}>
                      <PopOver
                        // placement="left"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => e.stopPropagation()}
                        content={
                          <div onClick={(e) => e.stopPropagation()}>
                            <div
                              style={{ padding: "8px" }}
                              style={{
                                padding: "8px",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() =>
                                props.history.push({
                                  pathname: `/view-transaction/${r.original[0].transaction_source_id}`,
                                  state: {
                                    referrer: "/transfer-journal",
                                    referrerState: {
                                      month: filters.month,
                                      year: filters.year,
                                      bank,
                                    },
                                  },
                                })
                              }
                            >
                              <FiEdit onClick="" style={{ marginRight: 5 }} />
                              <span>Edit</span>
                            </div>

                            <Divider style={{ margin: 0 }} />

                            {/* poprConfirm */}

                            <PopconfirmWrapper
                              onClick={(e) => e.stopPropagation()}
                              title={
                                <h4>
                                  Are you sure you want to delete this
                                  transaction?
                                </h4>
                              }
                              icon={
                                <FiAlertTriangle
                                  style={{
                                    position: "absolute",
                                    color: "#f44336",
                                    left: 0,
                                    top: 0,
                                    marginTop: 6,
                                  }}
                                />
                              }
                              cancelText="No"
                              okText="Yes"
                              onConfirm={() =>
                                props.deleteTransactionRequest(
                                  r.original[0].transaction_source_id,
                                  () => setTableChanged(!tableChanged)
                                )
                              }
                            >
                              {/* <ButtonWrapper className="deleteBtn"> */}
                              <div
                                style={{
                                  padding: "8px",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <AiOutlineDelete
                                  className="deleteIcon"
                                  style={{ color: "#FF494C", marginRight: 5 }}
                                />
                                <span>Delete</span>
                              </div>
                              {/* </ButtonWrapper> */}
                            </PopconfirmWrapper>
                          </div>
                        }
                      >
                        <FiMoreHorizontal style={{ color: "#154D94" }} />
                      </PopOver>
                    </span>
                  </React.Fragment>
                ),
              },
            ]}
          />
        </Col>
      ) : null}
    </Row>
  );
};
export default withRouter(
  connect(
    (storeState) => ({
      transactionsData: storeState.TransferDeatilsTransactions,
    }),
    {
      // searchTransactionsRequest,
      getTransferRequest,
      deleteTransactionRequest,
    }
  )(TransferDetails)
);
