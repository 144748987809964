//Left side bar menu content
import React from "react";
import { Menu } from "antd";
import { withRouter, Link } from "react-router-dom";
import MenuWrapper from "../menu";
import {
  FiDollarSign,
  FiPieChart,
  FiPlus,
  FiFileText,
  FiSearch,
  FiUsers,
  FiSettings,
  FiLayers
} from "react-icons/fi";

const LeftSideBar = props => {
  return (
    <React.Fragment>
      {/* <div style={{ textAlign: "center", padding: "0.64rem" }}>
      
    </div> */}

      <MenuWrapper
        className="sidebarMenu"
        align="right"
        style={{ marginTop: "4rem", textAlign: "left" }}
        defaultSelectedKeys={[props.location.pathname]}
      >
        <Menu.Item className="sideBarItem" key="/">
          <Link className="font500" to="/">
            <FiDollarSign /> Bank Account
          </Link>
        </Menu.Item>

        <Menu.Item className="sideBarItem" key="/charts">
          <Link className="font500" to="/charts">
            <FiPieChart /> Charts
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/add-transaction">
          <Link className="font500" to="/add-transaction">
            <FiPlus /> Add Transaction
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/reports">
          <Link className="font500" to="/reports">
            <FiFileText /> Reports
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/search-transaction">
          <Link className="font500" to="/search-transaction">
            <FiSearch /> Search Transaction
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/view-contact">
          <Link className="font500" to="/view-contact">
            <FiUsers /> Contacts
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/settings">
          <Link className="font500" to="/settings">
            <FiSettings /> Settings
          </Link>
        </Menu.Item>
        <Menu.Item className="sideBarItem" key="/subscriptions">
          <Link className="font500" to="/subscriptions">
            <FiLayers /> Subscriptions
          </Link>
        </Menu.Item>
      </MenuWrapper>
      <div className="bankMenu">
        <Link className="font500" to={{ pathname: "/add-bank", state: { referrer: props.location.pathname } }}>
          <FiPlus /> Add Bank Account
        </Link>
      </div>
    </React.Fragment>
  );
};
export default withRouter(LeftSideBar);
