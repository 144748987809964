import React from "react";
import { connect } from "react-redux";
import CardWrapper from "../../../components/card";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import ButtonWrapper from "../../../components/button";
import { MdDelete } from "react-icons/md";
import { Row, Col } from "antd";
import { deleteBankAccountRequest } from "../../../actions/BankAccount";
import PopconfirmWrapper from "../../../components/pop-confirm";
import { FiAlertTriangle } from "react-icons/fi";
import { AccountingFormat } from "../../../configurations/Config";

const MainCard = styled.div`
  height: 100%;
  & .ant-card-body {
    padding: 2rem;
    position: relative;
    h3 {
      margin: 0.9rem 0 0.8rem;
    }
  }
  & .deleteBtn {
    background-color: ${props => props.theme[props.theme.mode].error.main};
    min-width: initial;
    padding: 0.1rem 0.4rem;
    font-size: 1rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }
`;

const BankAccountCard = props => {
  const deleteBank = id => {
    // props.dispatch
    props.deleteBankAccountRequest(id);
  };
  return (
    <React.Fragment>
      <MainCard>
        <CardWrapper className="textCenter">
          <React.Fragment>
            <Row
              gutter={[0, 20]}
              type="flex"
              align="middle"
              justify="center"
              className="textCenter"
              style={{height: "100%"}}
            >
              <PopconfirmWrapper
                title={
                  <h4>Are you sure you want to delete this bank account?</h4>
                }
                icon={
                  <FiAlertTriangle
                    style={{
                      position: "absolute",
                      color: "#f44336",
                      left: 0,
                      top: 0,
                      marginTop: 6
                    }}
                  />
                }
                cancelText="No"
                okText="Yes"
                onConfirm={function() {
                  deleteBank(props.val.bank_account_id);
                }}
              >
                <ButtonWrapper className="deleteBtn">
                  <MdDelete />
                </ButtonWrapper>
              </PopconfirmWrapper>

              <Row type="flex" align="top" style={{ width: "100%" }}>
                <Col
                  span={24}
                  style={{ paddingTop: "20px", paddingBottom: "10px" }}
                >
                  <h4
                    style={{
                      margin: "0.5rem 0 0 0",
                      fontWeight: 700,
                      padding: "0 1rem"
                    }}
                  >
                    {props.val.law_firm_name}
                  </h4>
                </Col>
                <Col span={24}>
                  <h4 style={{ fontWeight: 600 }}>
                    {props.val.bank_account_name}
                  </h4>
                </Col>
                <Col span={24}>
                  <h2 style={{ fontWeight: 400 }}>
                    {AccountingFormat(props.val.balance,props.val.currency)}
                    {/* {props.val.currency + props.val.balance.toLocaleString()} */}
                  </h2>
                </Col>
              </Row>
              <Row type="flex" align="bottom">
                <Col span={24}>
                  <ButtonWrapper
                    onClick={() =>
                      props.history.push(
                        `/bank-account-details/${props.val.bank_account_id}`
                      )
                    }
                  >
                    View Details
                  </ButtonWrapper>
                </Col>
              </Row>
            </Row>
          </React.Fragment>
        </CardWrapper>
      </MainCard>
    </React.Fragment>
  );
};
export default withRouter(
  connect(null, { deleteBankAccountRequest })(BankAccountCard)
);
