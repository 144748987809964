import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import "react-table/react-table.css";
import styled from "styled-components";
import Style from "./Style";
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';
import { DEFAULT_TABLE_PAGE_SIZE } from "../../configurations/Constants"

const StyledTable = styled(ReactTable)`
    ${Style}
`;


//Have to implement large column sizes, could store table data locally too
const TableWrapper = props => {
  const [pageSize] = useState(props.pageSize || DEFAULT_TABLE_PAGE_SIZE);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(props.tableData ? Math.ceil(props.tableData.length / pageSize) -
    1 : //1 : 
    -1);//0);
  //const [dataCount, setDataCount] = useState(props.dataCount ? props.dataCount : props.dataCount === 0 ? props.dataCount : props.tableData.length);
  
  let dataCount = 
  parseInt(props.dataCount) ?
  //props.dataCount !== undefined && props.dataCount !== null 
  props.dataCount : props.tableData ? props.tableData.length > 0 ? props.tableData.length : 1 : 1;
  const [pages, setPages] = useState(Math.ceil(dataCount / pageSize));
  const [minRows, setMinRows] = useState(dataCount % pageSize || pageSize);
  //dataCount > pageSize ? pageSize : dataCount < 1 ? 1 : dataCount);
  const [loading, setLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const [refreshToggle, setRefreshToggle] = useState(false);

  //console.log("TABLE", page, lastPage, pageSize, minRows);
  console.log("TABLE", page, pages, lastPage, minRows, props, hasMounted, refreshToggle, props.tableData);

  useEffect(() => {
    if(hasMounted) {
    // let isData = props.tableData ? props.tableData.length > 0 : false;
    let pages = Math.ceil(dataCount / pageSize);

    setPage((page + 1) > pages ? pages - 1 : page);
    setPages(pages);//A seperate effect can be made for this and it should happen here only if !prop.getData

    //if (props.getData && isData) {
    //console.log("HERE TOO", Math.ceil(props.tableData.length / pageSize) - 0)
    setLastPage(props.tableData ? Math.ceil(props.tableData.length / pageSize) -
      1 : //1 : 
      -1);
    setMinRows(dataCount % pageSize || pageSize);//This can be done with modulus and possibly moved outside the if
    //}

    // if (props.enableReInit) {//In case, table needs to be reset on new data, Could be replaced with enableReinitialize prop - done
    //   if ((props.getData && !isData) || !props.getData) {
    //     console.log("HERE THREE!")
    //     setPage(0);
    //     setLastPage(-1);//Probably only when props.getData
    //     setMinRows(dataCount % pageSize || pageSize);//Should minRows be reset here for the first page? I think so. Probably only when !props.getData
    //   }
    // }
    }
  }, [props.tableData]);

  useEffect(() => {
    if ((((page > lastPage) && (props.getData)) || (!hasMounted && props.getData)) && !props.noCallOnMount) {
      setLoading(true);
      //setLastPage(page);//May not be neccessary here
      //will pass callback into action which will be called in saga after data is fetched, api params will also be passed here
      props.getData({ 
        limit: hasMounted ? (page - lastPage) * pageSize : pageSize,
        offset: hasMounted ? pageSize + (pageSize * lastPage) : pageSize * page,
        ...props.filters 
        }, 
        (res) => {
        setLoading(false);
      }, page === 0);
    }
  }, [page, refreshToggle]);

  //This should be handled with an onClick rather than an effect
  useEffect(() => {
    if (hasMounted && props.getData) {
      setLoading(true);
      props.getData({
        limit: pageSize,
        offset: page * pageSize,
        ...props.filters
        }, 
        (res) => {
        setLoading(false);
      }, false, { page, pageSize, pages });
    }
  }, [props.tableChanged]);

  //Is it necessary to call this after mounting? Yes, otherwise, if cached data is present,
  //API will be called twice because of lastPage changing
  //Should also dispatch an action here to clear tableData rather than clearing it on a failed
  //request
  useEffect(() => {
    if (hasMounted) {
      resetTable();
    }
  }, [props.filters]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  function resetTable() {
    setPage(0);
    setLastPage(-1);
    setRefreshToggle(!refreshToggle)
  }

  // useEffect(() => {
  //   resetTable();
  // }, [props.userChanged]);

  return (
    <StyledTable
      {...props}
      data={props.tableData || []}
      noDataText="No data found"
      showPageSizeOptions={false}
      loading={props.loading === null || typeof props.loading === 'undefined' ? loading : props.loading}
      columns={
        props.content.map((val) => (
          {
            Header: val.sortable ? (
              <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                <div className="sortingHeader" style={{ display: "flex", fontSize: "1.3rem", flexDirection: "column", marginRight: 10 }}>
                  <div className="ascending"><FiChevronUp /></div>
                  <div className="descending"><FiChevronDown /></div>
                </div>
                <p className="fontBold">{val.name}</p>
              </div>
            ) : <p className="fontBold">{val.name}</p>,
            id: val.id,
            sortable: val.sortable || false,
            className: val.className,
            Cell: val.render ? val.render : (r) => r.original[val.id] || "-"
          }))}
      defaultPageSize={DEFAULT_TABLE_PAGE_SIZE}
      page={page}
      pageSize={pageSize}
      pages={pages}
      onPageChange={(nextPage) => {
        //Is it necessary to set MinRows after every page change when it's being set above
        let rowsToShow = dataCount - ((nextPage) * pageSize);
        setMinRows(rowsToShow > pageSize ? pageSize : rowsToShow < 1 ? 1 : rowsToShow);
        // if (nextPage <= lastPage || !props.getData) {
        //   let rowsToShow = dataCount - ((nextPage) * pageSize);
        //   setMinRows(rowsToShow > pageSize ? pageSize : rowsToShow < 1 ? 1 : rowsToShow);//Less than one check may not be necessary
        // }
        setPage(nextPage);
      }}
      minRows={props.adjustRows === false ? pageSize : minRows}
      resizable={false}
      pageText={""}
      previousText={<FiChevronLeft className="button"/>}
      nextText={<FiChevronRight className="button"/>}
    />
  )
}

export default TableWrapper;