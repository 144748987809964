import React from "react";

function withScreenWidth(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        screenWidth: window.innerWidth
      };
    }

    componentDidMount() { 
      window.addEventListener("resize", this.handleWidthChange);
    }
  
    componentWillUnmount() {
      window.removeEventListener("resize", this.handleWidthChange);
    }
  
    handleWidthChange = () => {
      this.setState({ screenWidth: window.innerWidth });
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent screenWidth={this.state.screenWidth} {...this.props} />;
    }
  };
}

export default withScreenWidth;