import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../../components/input/index";
import RootForm from "../../../components/form/index";
import { SendReportSchema } from "../../../configurations/Schemas/index";
import DatePicker from "../../../components/datepickers/DatePicker";
import TextArea from "../../../components/input/TextArea";
import CardWrapper from "../../../components/card";
import HeaderWrapper from "../../../components/header/index";
import { Row, Col } from "antd";
import moment from "moment";
import Loader from "../../../components/loading";
import { FaFileCsv, FaHtml5, FaFilePdf } from "react-icons/fa";
import { sendReportRequest } from "../../../actions/Reports";

import "../../auth/auth.css";
let current_date = moment();

class BankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to: "",
      cc: "",
      bcc: "",
      subject: "",
      report_url: props.location.state ? props.location.state.reportUrl : "",
      file: props.location.state ? props.location.state.reportType : ""
      //format: props.location.state ? props.location.state.reportFormat : "",
    };
  }

  handleChanged = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    let format =
      this.props.location.state && this.props.location.state.reportFormat;

    console.log("Send report", format, this.state.file);
    return this.props.location.state ? (
      <Row type="flex" justify="center" align="middle" gutter={[0, 40]}>
        <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col>
        <Col xs={24} sm={20} md={22} lg={12}>
          {this.props.match.path === "/edit-bank/:id" &&
          this.props.AddBankAccount.bankDetail === null ? (
            <Loader />
          ) : (
            <CardWrapper>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="textCenter"
              >
                <Col xs={24} sm={20} md={22} lg={14}>
                  <h4 className="mr-b-md mr-t-md">
                    {"Please enter the details to send"}
                  </h4>

                  <RootForm
                    schemaName={SendReportSchema}
                    initialValues={this.state}
                    enableReinitialize={this.state.enableRes}
                    // enableReinitialize={
                    //   this.props.AddBankAccount.bankLists
                    //     ? this.props.AddBankAccount.bankLists.bank_accounts
                    //       ? true
                    //       : false
                    //     : false
                    // }
                    apiCall={sendReportRequest}
                    loading={this.props.reports.sendReportLoading}
                    // resetAfterSubmit
                    cb={() =>
                      this.props.history.push(
                        this.props.location.state
                          ? this.props.location.state.referrer
                            ? this.props.location.state.referrerState
                              ? {
                                  pathname: this.props.location.state.referrer,
                                  state: this.props.location.state.referrerState
                                }
                              : this.props.location.state.referrer
                            : "/"
                          : "/"
                      )
                    }
                    controls={[
                      {
                        span: 24,
                        name: "to",
                        //value: law_firm_name,
                        handleChanged: this.handleChanged,
                        placeholder: "To",
                        Type: Input
                      },

                      {
                        span: 24,
                        name: "cc",
                        //value: bank_account_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Cc",
                        Type: Input
                      },
                      {
                        span: 24,
                        name: "bcc",
                        //value: bank_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Bcc",
                        Type: Input
                      },
                      {
                        span: 24,
                        name: "subject",
                        //value: balance,
                        handleChanged: this.handleChanged,
                        placeholder: "Subject",
                        Type: Input
                      },
                      {
                        span: 24,
                        name: "file",
                        //value: balance,
                        props: {
                          prefix:
                            format === "csv" ? (
                              <FaFileCsv />
                            ) : format === "html" ? (
                              <FaHtml5 />
                            ) : (
                              <FaFilePdf />
                            )
                        },
                        disabled: true,
                        handleChanged: this.handleChanged,
                        Type: Input
                      }
                    ]}
                    submitButton={{
                      span: 18,
                      buttonText: "Send",
                      className: "mr-t-md mr-b-lg"
                    }}
                  />
                </Col>
              </Row>
            </CardWrapper>
          )}
        </Col>
      </Row>
    ) : (
      <Redirect to="/" />
    );
  }
}

export default withRouter(
  connect(
    storeState => ({
      reports: storeState.Reports
    }),
    {
      sendReportRequest
    }
  )(BankAccount)
);
