import React, { useEffect } from "react";
import { STRIPE_PUBLISHABLE_API_KEY } from "./configurations/Constants"
import { setStripe } from "./actions/App"
import { connect } from "react-redux";
import { StripeProvider } from 'react-stripe-elements';
import "antd/dist/antd.css";
import { ThemeProvider } from "styled-components";
import { theme } from "./configurations/Theme";
import Routes from "./Routes";
import RootModal from "./components/modals";
// import FormView from "./components/formikwrapper/FormView"

function App(props) {

  // useEffect(() => {
  //   function handleScriptLoad() {
  //     props.dispatch(setStripe(window.Stripe(STRIPE_PUBLISHABLE_API_KEY)));
  //   }

  //   if (window.Stripe) {
  //     handleScriptLoad();
  //   } else {
  //     let script = document.querySelector('#stripe-js');
  //     script.addEventListener('load', handleScriptLoad);
  //     //script.onError = () => console.log("FAILED TO LOAD");
  //     //script.addEventListener('error', () => console.log("FAILED TO LOAD")); Working!!!
  //   }
  //   return () => {
  //     document.querySelector('#stripe-js').removeEventListener('load', handleScriptLoad);
  //   };
  // }, []);

  return (
    //<StripeProvider 
    //apiKey="pk_test_rBeOTa2VkXZ16aKpzBQiA8qT00SmODzsPk"
    //stripe={props.app.stripe}>
      <ThemeProvider theme={{ ...theme, mode: props.app.mode }}>
        <React.Fragment>
          <div id={props.modal.modals.length !== 0 ? 'filterModal' : ''} >
            <Routes />
            {/* <FormView /> */}
          </div>
          <RootModal />
        </React.Fragment>
      </ThemeProvider>
    //</StripeProvider>
  );
}

export default connect(
(storeState) => ({ app: storeState.App, modal: storeState.Modal }))
(App);
