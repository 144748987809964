import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  SIGNUP_VALIDATION_REQUEST,
  SIGNUP_VALIDATION_SUCCESS,
  SIGNUP_VALIDATION_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  RESEND_CODE_REQUEST,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CHANGE_HEADER_PROFILE_PICTURE,
  PROFILE_UPDATE_SUCCESS,
  SET_SUBSCRIPTION,
} from "../configurations/Types";

const INITIAL_STATE = {
  isAuthenticated: localStorage.getItem("escrowauth") ? true : false,
  //isAuthenticated: true,
  profileDetails: localStorage.getItem("escrowauth")
    ? JSON.parse(localStorage.getItem("escrowauth"))
    : null,
  passwordReset: null,
  verificationCode: null,
  changePassword: null,
  loading: false,
  // loading:
  // error:
};

export default (state = INITIAL_STATE, action) => {
  //console.log("AUTH STATE", state);
  switch (action.type) {
    case REGISTER_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case REGISTER_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case REGISTER_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case SIGNUP_VALIDATION_REQUEST: {
      return Object.assign({}, state, { loading: false });
    }
    case SIGNUP_VALIDATION_SUCCESS: {
      return Object.assign({}, state, { loading: false });
    }
    case SIGNUP_VALIDATION_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case LOGIN_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        isAuthenticated: true,
        profileDetails: action.payload,
        loading: false,
      });
    }
    case LOGIN_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case LOGOUT: {
      return Object.assign({}, state, {
        isAuthenticated: false,
        profileDetails: {},
      });
    }
    case PASSWORD_RESET_REQUEST: {
      return Object.assign({}, state, { loading: !action.resend });
    }
    case PASSWORD_RESET_SUCCESS: {
      return Object.assign({}, state, {
        passwordReset: action.payload,
        loading: false,
      });
    }
    case PASSWORD_RESET_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case RESEND_CODE_REQUEST: {
      return Object.assign({}, state, { loading: false });
    }
    case RESEND_CODE_SUCCESS: {
      return Object.assign({}, state, {
        passwordReset: action.payload,
        loading: false,
      });
    }
    case RESEND_CODE_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case VERIFY_TOKEN_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case VERIFY_TOKEN_SUCCESS: {
      return Object.assign({}, state, {
        verificationCode: action.payload,
        loading: false,
      });
    }
    case VERIFY_TOKEN_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case RESET_PASSWORD_REQUEST: {
      return Object.assign({}, state, { loading: true });
    }
    case RESET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        changePassword: action.payload,
        loading: false,
      });
    }
    case RESET_PASSWORD_ERROR: {
      return Object.assign({}, state, { loading: false });
    }
    case CHANGE_HEADER_PROFILE_PICTURE: {
      let newProfileDetails = {
        ...state.profileDetails,
        images: action.payload,
      };
      return Object.assign({}, state, { profileDetails: newProfileDetails });
    }

    case PROFILE_UPDATE_SUCCESS: {
      let newProfileDetails = {
        ...state.profileDetails,
        full_name: action.payload,
      };
      return Object.assign({}, state, { profileDetails: newProfileDetails });
    }

    case SET_SUBSCRIPTION: {
      let newProfileDetails = {
        ...state.profileDetails,
        subscription: action.status,
      };
      return Object.assign({}, state, { profileDetails: newProfileDetails });
    }

    default: {
      return state;
    }
  }
};
