import React, { useState } from "react";
import ImageUpload from "../../../../../components/input/ImageUpload";
import Field from "../index";

const Image = props => {
  console.log("Upload Image")
  return (
    <React.Fragment>
      <Field
        {...props}
        style={{ width: "100%" }}
        customInput={ImageUpload}
        
      />
    </React.Fragment>
  );
};

export default Image;
