import React, { useState, useRef, useEffect, Fragment } from "react";
import General from "./General";
import SelectOptions from "../../../../../components/input/SelectOptions";
import Icon from "../../../../../components/Icon";
import { FiAlertTriangle } from "react-icons/fi";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { debounce } from "debounce";
import { getAccountsRequest } from "../../../../../actions/FieldOptions";
import { deleteAccountHeadRequest } from "../../../../../actions/transaction/Delete";
import AddAccountName from "../../../../../views/shell/addtranscation/AddAccountName";
import { openModal } from "../../../../../actions/Modal";
import PopConfirm from "../../../../../components/pop-confirm";
import DeleteButton from "../../DeleteButton";
import EditButton from "../../EditButton";

const AccountName = (props) => {
  // console.log("allBanks", props.allBanks);
  // console.log("props", props);
  const [value, setValue] = useState(
    props.initialValue
      ? { value: props.initialValue[0], accountId: props.initialValue[1] }
      : {}
  );
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectLoading, setSelectLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [mount, setMount] = useState(false);

  const apiCall = useRef();

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    if (props.deletedAccount && props.deletedAccount === value.accountId) {
      props.onChange();
      setValue({ value: undefined });
    }
  }, [props.deletedAccount]);

  useEffect(() => {
    apiCall.current = debounce(
      (e) => {
        console.log("api call", e);
        setLoading(!options.length);
        props.getAccountsRequest(
          props.accountType,
          props.id,
          props.bankAccount,
          (error, data) => {
            if (error) {
              setLoading(false);
            } else {
              setLoading(false);

              setOptions(data);
              console.log("DATA IS: ", data);
            }
          }
        );
      },
      1000,
      true
    );
  }, [props.accountType, props.bankAccount, props.id, options]);

  useEffect(() => {
      setOptions([]);
      apiCall.current();
  }, [props.id]);
  useEffect(() => {
    if (props.bankAccount && mount) {
      props.onChange();
      setValue({ value: undefined });

      apiCall.current();
    }
  }, [props.bankAccount]);

  useEffect(() => {
    if (props.defaultReserveLawyer) {
      setSelectLoading(true);
      props.getAccountsRequest(undefined, undefined, undefined, (error, data) => {
        if (error) {
          setSelectLoading(false);
        } else {
          setSelectLoading(false);

          const lawyerReserve = data.find(
            (val) => val.name === "Lawyer’s Reserve"
          );
          if (lawyerReserve) {
            let id = lawyerReserve.id,
              name = lawyerReserve.name,
              type = lawyerReserve.type;

            props.onChange(id, type, name);
            setValue({ value: name, accountId: id });
          }
        }
      });
    }
  }, [props.defaultReserveLawyer]);

  //   const history = useHistory();
  console.log("Account props==>", props);
  //   let { ...props } = props;
  return (
    <div>
      <General
        {...props}
        {...value}
        optionFilterProp="searchValue"
        loading={selectLoading}
        onChange={(e) => {
          if (props.onChange) {
            console.log(e,'onChan')
            if (e) {
              let values = e.split("*");
              props.onChange(values[0], values[2], values[1]);
              setValue({ value: values[1], accountId: values[0] });
            } else {
              props.onChange(e);
              setValue({ value: e });
            }
          }
        }}
        onSearch={(Search) => {
          // if (open) {
          //   // let filtered = options.filter(e=>e.law_firm_name.includes(Search))
          //   // setOptions(filtered);
          //   // console.log("options", options);
          //   // apiCall.current(e);
          // }
        }}
        onDropdownVisibleChange={(open) => {
          // setOpen()
          if (open) {
            setOpen(true);
            apiCall.current();
            //debounce(apiCall, 1000)();
          } else {
            setOpen(false);

            //apiCall.clear();
          }
        }}
        open={open}
      >
        {loading ? (
          <SelectOptions
            className="loadingOption"
            disabled
            value={1}
            style={{ cursor: "normal" }}
          >
            <div
              className="textCenter"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Icon type="loading" style={{ fontSize: "1.5rem" }} />
            </div>
          </SelectOptions>
        ) : (
          options
            .filter((val) =>{
              console.log('VALUE',val)
              return props.filterIds ? !props.filterIds.includes(val.id) : true

            }
            )
            .map((item, index) => (
              console.log("item name: ", item.name),
              <SelectOptions
                searchValue={[item.name, item.account_name, item.type, item.account_type, item.currency, item.amount]}
                key={index}
                value={`${!props.isDisplay ? item.id : item.account_id}*${!props.isDisplay ? item.name : item.account_name}${
                  props.withType ? `*${ !props.isDisplay ? item.type : item.account_type}` : ``
                }`}
              >
                <Row
                  type="flex"
                  align="middle"
                  justify="space-between"
                  style={{
                    width: "100%",
                    color: !props.isDisplay ? item.type === "income" ? "#58BA27" : "#ff494c" : item.account_type === "income" ? "#58BA27" : "#ff494c",
                  }}
                >
                  {!props.hideDeleteBtn ? (
                    <Fragment>
                      <Col span={18} className="expandMe">
                        <div>{!props.isDisplay ? item.name : item.account_name}</div>
                        {/* <div className="hideMe">
                          {item.type}
                        </div> */}
                        {!props.isDisplay ?
                        <div className="hideMe">
                          {item.currency}
                          {item.amount}
                        </div>
                        : null }
                      </Col>
                      <Col
                        span={3}
                        className="hideMe"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EditButton
                          onClick={() =>
                            props.openModal(
                              null,
                              <AddAccountName
                                initialValues={item}
                                accType={item.type}
                              />,
                              null,
                              null,
                              { footer: null }
                            )
                          }
                        />
                      </Col>
                      <Col
                        span={3}
                        className="hideMe"
                        // onMouseDown={e => {
                        //   e.stopPropagation();
                        //   e.preventDefault();
                        // }}
                        onClick={(e) => {
                          e.stopPropagation();
                          //e.preventDefault();
                        }}
                      >
                        <PopConfirm
                          title="Are you sure you want to delete this account?"
                          icon={
                            <FiAlertTriangle
                              style={{
                                position: "absolute",
                                color: "#f44336",
                                left: 0,
                                top: 0,
                                marginTop: 6,
                              }}
                            />
                          }
                          cancelText="No"
                          okText="Yes"
                          onConfirm={function () {
                            props.deleteAccountHeadRequest(item.id, (res) => {
                              apiCall.current();

                              if (props.onDelete) props.onDelete(item.id);

                              // if(item.id == value.accountId) {
                              //   props.onChange();
                              //   setValue({ value: undefined });
                              // }
                            });
                          }}
                        >
                          <DeleteButton />
                        </PopConfirm>
                      </Col>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Col span={24} className="expandMe">
                        <div>{!props.isDisplay ? item.name : item.account_name}</div>
                        {/* <div className="hideMe">
                            {item.type}
                          </div> */}
                        {!props.isDisplay ?
                        <div className="hideMe">
                          {item.currency}
                          {item.amount}
                        </div>
                        : null }
                      </Col>
                    </Fragment>
                  )}
                </Row>
              </SelectOptions>
            ))
        )}
        {/* <SelectOptions value={2}>urturtu</SelectOptions> */}
      </General>
    </div>
  );
};

export default connect(null, {
  getAccountsRequest,
  deleteAccountHeadRequest,
  openModal,
})(AccountName);
