import React from "react";
import { Carousel } from "antd";
import styled from "styled-components";

const StyledCarousel = styled(Carousel)`
  /* & .slick-list {
  padding: 16px 0;
}
& .slick-dots-bottom {
  bottom: 24px;
} */
  &,
  & .slick-slider {
    height: 100%;
    padding-bottom: 2rem;
  }

  & .slick-dots li button {
    background: ${(props) => props.theme[props.theme.mode].primary.light};
  }

  & .slick-dots li.slick-active button {
    background: ${(props) => props.theme[props.theme.mode].primary.main};
    border: solid 1px;
    border-radius: 5px;
  }
`;

const CarouselWrapper = (props) => {
  return <StyledCarousel {...props} />;
};

export default CarouselWrapper;
