import React from "react";
import Button from "../../../components/button";
import styled from "styled-components";
import { FiMinus } from "react-icons/fi";

const IconButtonWrap = styled.div`
    & .ant-btn {
    border: none;
    box-shadow: none;
    color: ${props => props.theme[props.theme.mode].textColor.primary};
    font-size: ${props =>
      props.theme[props.theme.mode].textSizes.relative.links};
    display: flex;
    align-items: center;
    min-width: auto;
  }
  & svg {
    color: ${props => props.theme[props.theme.mode].common.white};
    background: ${props => props.theme[props.theme.mode].secondary.main};
    padding: 0.1rem;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    margin: 0 0.4rem;
  }
`;

const IconButton = props => {
    return (
        <IconButtonWrap>
            <Button  outlined {...props}><FiMinus />{props.children}</Button>
        </IconButtonWrap>
    )
};

export default IconButton
