import React, { Fragment, useState, useEffect } from "react";
import Icon from "../../../components/Icon";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import RecurringDeposit from "./recurring/Deposit";
import RecurringWithdraw from "./recurring/Withdraw";
import Transfer from "./Transfer";

import Empty from "./states/Empty";
import Loading from "./states/Loading";
import { recurringDeposit } from "../../../configurations/Schemas";
import { getTransactionRequest, clearTransaction } from "../../../actions/transaction/Transaction";
import { getUserSettingRequest } from "../../../actions/Settings";

let formMap = {
  deposit: Deposit,
  withdrawal: Withdraw,
  recurringDeposit: RecurringDeposit,
  recurringWithdraw: RecurringWithdraw,
  transfer: Transfer
}

const EditTransaction = props => {
  // let Component = this.state.type || null;
  let { transaction, transactionLoading: loading } = props.transactions;
  const [referrerState, setReferrerState] = useState(props?.location?.state?.referrerState ? props?.location?.state?.referrerState : null);
  useEffect(() => {
    props.getTransactionRequest(props.match.params.id)
    return () => {
      props.clearTransaction();
    }
  }, [props.match.params.id]);

  useEffect(() => {
    props.getUserSettingRequest();
  }, []);

  //let transaction = "this.props.location.state";
  //let transaction = "asd";

  //if (transaction) {
  let Form = formMap[
    //"transfer"
    //transaction && transaction[0] && transaction[0].transaction_type
    transaction && transaction[0] && (transaction[0].is_recurring === "true" ? transaction[0].transaction_type === "deposit" ? "recurringDeposit" : "recurringWithdraw" : transaction[0].transaction_type)
  ];
  //console.log("PROPS ARE : ", referrer, referrerState);
  return (
    loading ? (
      <Loading />
    ) : (
        Form ? (
          <Form
            edit={props.match.path === "/edit-transaction/:id"}
            transaction={transaction}
            referrerState={referrerState}
          />
        ) : (
            <Empty />
          )
      )
  );
  //}
  // else {
  //   return (
  //     <Redirect to="/" />
  //   )
  // }
}


export default
  connect(
    storeState => ({
      transactions: storeState.transaction
    }),
    {
      getTransactionRequest,
      clearTransaction,
      getUserSettingRequest
    }
  )(EditTransaction);
