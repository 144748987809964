import React from 'react';
import { Radio } from 'antd';
import styled from "styled-components";

const StyledRadioGroup = styled(Radio.Group)`

`;


const RadioGroupWrapper = (props)=>{
    return (
    <StyledRadioGroup {...props}/>
    );
}

export default RadioGroupWrapper;