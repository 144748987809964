import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "../../components/input/index";
import RootForm from "../../components/form/index";
import { addBank } from "../../configurations/Schemas/index";
import DatePicker from "../../components/datepickers/DatePicker";
import TextArea from "../../components/input/TextArea";
import CardWrapper from "../../components/card";
import HeaderWrapper from "../../components/header/index";
import { Row, Col } from "antd";
import moment from "moment";
import Loader from "../../components/loading";
import {
  addBankAccountRequest,
  updateBankAccountRequest,
  getSingleBankRequest,
  clearSingleBank,
} from "../../actions/BankAccount";
import info from "../../components/message/index";

import "../auth/auth.css";
let current_date = moment();

class BankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      law_firm_name: props.AddBankAccount.lastLawFirmName,
      bank_account_name: "",
      //props.AddBankAccount.lastBankAccountName,
      bank_name: props.AddBankAccount.lastBankName,
      balance: "0.00",
      additional_note: "",
      // current_date,
      edit: this.props.match.params.id,
      ...props.location.state,
      open_date: props.location.state
        ? moment(props.location.state.open_date)
        : current_date,
      enableRes: true,
    };
  }
  //will uncomment when implemented
  componentDidMount() {
    if (this.props.match.path === "/edit-bank/:id") {
      this.props.getSingleBankRequest(this.props.match.params.id);
    } else {
      this.props.clearSingleBank();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (this.props.AddBankAccount.bankDetail !== null) {
        const detail = this.props.AddBankAccount.bankDetail.bank_account;
        console.log("This is detail: ", detail);
        this.setState({
          id: this.props.match.params.id,
          law_firm_name: detail.law_firm_name,
          bank_name: detail.bank_name,
          bank_account_name: detail.bank_account_name,
          additional_note: detail.additional_note,
          // balance: detail.opening_balance,
          balance: "0.00",
          cleared_balance: detail.cleared_balance,
          open_date: moment(detail.open_date),
        });
      }
    }
  }

  handleChanged = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    console.log("BANK ACCOUNT", this.props);
    let {
      law_firm_name,
      bank_account_name,
      bank_name,
      balance,
      open_date,
      additional_note,
      edit,
    } = this.state;

    let { edit: extracted, ...initialValues } = this.state;
    // console.log(
    //   "Bank Account received: ",
    //   this.props.AddBankAccount.bankLists
    //     ? this.props.AddBankAccount.bankLists.bank_accounts
    //       ? true
    //       : false
    //     : false
    // );
    return (
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <HeaderWrapper externalscreen={1} />
        </Col>
        <Col xs={24} sm={20} md={22} lg={12}>
          {this.props.match.path === "/edit-bank/:id" &&
          this.props.AddBankAccount.bankDetail === null ? (
            <Loader />
          ) : (
            <CardWrapper>
              <Row
                type="flex"
                justify="center"
                align="middle"
                className="textCenter"
              >
                <Col xs={24} sm={20} md={22} lg={14}>
                  <h4 className="mr-b-md mr-t-md">
                    {edit ? "Edit Bank Account" : "Create Bank Account"}
                  </h4>

                  <RootForm
                    schemaName={addBank}
                    initialValues={initialValues}
                    enableReinitialize={this.state.enableRes}
                    // enableReinitialize={
                    //   this.props.AddBankAccount.bankLists
                    //     ? this.props.AddBankAccount.bankLists.bank_accounts
                    //       ? true
                    //       : false
                    //     : false
                    // }
                    apiCall={
                      edit ? updateBankAccountRequest : addBankAccountRequest
                    }
                    loading={this.props.AddBankAccount.loading}
                    // resetAfterSubmit
                    cb={() =>
                      this.props.history.push(
                        this.props.location.state
                          ? this.props.location.state.referrer
                          : "/"
                      )
                    }
                    controls={[
                      {
                        span: 24,
                        name: "law_firm_name",
                        value: law_firm_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Law Firm Name",
                        Type: Input,
                      },

                      {
                        span: 24,
                        name: "bank_account_name",
                        value: bank_account_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Bank Account Name",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "bank_name",
                        value: bank_name,
                        handleChanged: this.handleChanged,
                        placeholder: "Bank Name",
                        Type: Input,
                      },
                      {
                        span: 24,
                        name: "balance",
                        value: balance,
                        handleChanged: this.handleChanged,
                        placeholder: "Opening Balance",
                        Type: Input,

                        props: {
                          readOnly: true,
                          onClick: () =>
                            info(
                              "warning",
                              `Opening balances must be entered as a deposit transaction`
                            ),
                        },
                      },

                      {
                        span: 24,
                        name: "open_date",
                        handleChanged: this.handleChanged,
                        placeholder: "Pick Date",

                        props: {
                          format: "MM-DD-YY",
                        },
                        block: true,
                        allowClear: false,
                        //disabledDate: e => e && !moment().isAfter(e),
                        showToday: false,
                        Type: DatePicker,
                      },
                      {
                        span: 24,
                        name: "additional_note",
                        value: additional_note,
                        handleChanged: this.handleChanged,
                        placeholder: "Note",
                        Type: TextArea,
                        rows: 4,
                      },
                    ]}
                    submitButton={{
                      span: 18,
                      buttonText: edit ? "Save Changes" : "Create Bank Account",
                      className: "mr-t-md mr-b-lg",
                    }}
                  />
                </Col>
              </Row>
            </CardWrapper>
          )}
        </Col>
      </Row>
    );
  }


}



export default withRouter(
  connect(
    (storeState) => ({
      AddBankAccount: storeState.AddBankAccount,
    }),
    {
      // getBankAccountsRequest
      getSingleBankRequest,
      clearSingleBank,
    }
  )(BankAccount)
);
