import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import HeaderWrapper from "../../../../components/header/index";
import useRecurringDepositForm from "../../../../appcomponents/EscrowTrakker/forms/useRecurringDepositForm";
import BankFieldName from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/BankName";
import OptionsField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/OptionsField";
import AccountNameField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/AccountName";
import Field from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/index";
import DatePicker from "../../../../components/datepickers/DatePicker";
import JobNameField from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/JobName";
import Image from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import Switch from "../../../../components/switch/index";
import RecurringType from "../../../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/selectWithSearch/RecurringType";
//import Options from "../../../../components/input/SelectOptions"
import TextArea from "../../../../components/input/TextArea";
import Button from "../../../../components/button/index";
import Split from "../../../../appcomponents/EscrowTrakker/formcontrols/splits";
import { openModal, closeAllModal } from "../../../../actions/Modal";
import Job from "../../addtranscation/Job";
import { updateRecurringRequest } from "../../../../actions/transaction/RecurringTransaction";
import AddAccountName from "../../addtranscation/AddAccountName";

import TransactionTypeCard from "../../../../appcomponents/EscrowTrakker/edittransaction/TransactionTypeCard";
import ClearedCard from "../../../../appcomponents/EscrowTrakker/edittransaction/ClearedCard";

const RecurringDeposit = props => {
  let { transaction, edit } = props;

  const mainForm = useRecurringDepositForm({
    initialValues: {
      bankName: transaction[0].bank_account_id,
      lawyerName: transaction[0].lawyer_id,
      customerName: transaction[0].customer_id,
      amount: transaction.reduce((total, curr) => total + parseFloat(curr.amount), 0),
      accountName: transaction[0].account_head_id,
      jobName: transaction[0].job_id,
      payor: transaction[0].payor_id,
      frequency: transaction[0].frequency || undefined,
      startDate: transaction[0].start_date ? moment(transaction[0].start_date) : "",
      endDate: transaction[0].end_date ? moment(transaction[0].end_date) : "",
      //dateOfTransaction: moment(transaction[0].date_of_transaction),
      checkNumber: transaction[0].check_number,
      cleared: transaction[0].is_cleared === "true",
      additionalNote: transaction[0].additional_note,
      type: "deposit"
    },
    //validationSchema: recurringDeposit,
    //apiCall: { addTransactionRequest },

    onSubmit: values => props.updateRecurringRequest(values, props.match.params.id, () => {
      props.history.push({
        pathname: `/view-transaction/${props.match.params.id}`,
        state: props.location.state
      })
    })
  });

  console.log("FORM", mainForm);
  return (
    <React.Fragment>
      <Row type="flex" gutter={[30, 20]}>
      <Col span={24}>
        <HeaderWrapper
        maintainSpans
        bisect
         rightAlign={[
          <Button
          loading={props.loading}
          onClick={() => {
            if(props.edit)
              mainForm.handleSubmit();
            else {
              props.history.push({
                pathname: `/edit-transaction/${props.match.params.id}`,
                state: props.location.state
              })
            }
          }}
          >
            {props.edit ? "Save Changes" : "Edit Transaction"}
          </Button>
        ]} 
         externalscreen={1} 
         />
      </Col>
      <Col xs={24} md={8}>
        <Row type="flex" gutter={[0, 20]}>
          <Col span={24}>
            <TransactionTypeCard type="Deposit" recurring />
          </Col>
          <Col span={24}>
            <ClearedCard
              name="cleared"
              disabled={!edit}
              checked={mainForm.values.cleared}
              onChange={e => mainForm.customHandleChange(e, "cleared")}
              onBlur={e => mainForm.customHandleBlur(e, "cleared")}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={16} lg={12}>
        <Row type="flex" gutter={[20, 20]}>
        <Col xs={24} sm={12}>
          <BankFieldName
            title="Bank Account Name"
            placeholder="Select Bank Account"
            addBtnText="Add Bank Account"
            disabled={!edit}
            initialValue={transaction[0].bank_account_name}
            // value={mainForm.values.bankName}
            name="bankName"
            link="/add-bank"
            onChange={e => mainForm.customHandleChange(e, "bankName")}
            onBlur={e => mainForm.customHandleBlur(e, "bankName")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["bankName"] && mainForm.errors["bankName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Lawyer Name"
            addBtnText="Add Lawyer"
            name="lawyerName"
            link="/add-contact"
            disabled={!edit}
            initialValue={transaction[0].lawyer_name}
            contactType="lawyer"
            placeholder="Select Lawyer"
            onChange={e => mainForm.customHandleChange(e, "lawyerName")}
            onBlur={e => mainForm.customHandleBlur(e, "lawyerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["lawyerName"] && mainForm.errors["lawyerName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <OptionsField
            title="Customer Name"
            addBtnText="Add Customer"
            link="/add-contact"
            contactType="customer"
            disabled={!edit}
            initialValue={transaction[0].customer_name}
            placeholder="Select Customer"
            name="customerName"
            onChange={(e, name) => {
              mainForm.customHandleChange(e, "customerName");
              if(!mainForm.values.payor) {
                mainForm.customHandleChange(e, "payor");
                mainForm.customHandleChange(name, "customer");
              }
            }}
            onBlur={e => mainForm.customHandleBlur(e, "customerName")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["customerName"] &&
              mainForm.errors["customerName"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            title="Amount"
            placeholder={`${props.settings.currency || "$"}0.00`}
            value={mainForm.values.amount}
            disabled={!edit}
            value={mainForm.values.amount}
            //onChange={mainForm.handleTotalChange}
            //onBlur={mainForm.handleBlur}
            name="amount"
            onChange={e => mainForm.customHandleChange(e, "amount")}
            onBlur={e => mainForm.customHandleBlur(e, "amount")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["amount"] && mainForm.errors["amount"]}
          </span>
        </Col>

        {!(mainForm.values.splits && mainForm.values.splits.length) ? (
          <React.Fragment>
            <Col xs={24} sm={12}>
              <AccountNameField
                title="Account Name"
                addBtnText="Add Account Name"
                accountType="income"
                placeholder="Select Account Name"
                disabled={!edit}
                initialValue={[transaction[0].account_head_name, transaction[0].account_head_id]}
                onMouseDown={e => e.preventDefault()}
                addBtnClick={() =>
                  props.openModal(
                    null,
                    <AddAccountName accType="income" />,
                    null,
                    null,
                    { footer: null }
                  )
                }
                name="accountName"
                onChange={e => mainForm.customHandleChange(e, "accountName")}
                onBlur={e => mainForm.customHandleBlur(e, "accountName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["accountName"] &&
                  mainForm.errors["accountName"]}
              </span>
            </Col>

            <Col xs={24} sm={12}>
              <JobNameField
                title="Job Name"
                addBtnText="Add Job Name"
                disabled={!edit}
                initialValue={[transaction[0].job_name, transaction[0].job_id]}
                placeholder="Select Job"
                addBtnClick={() =>
                  props.openModal(null, <Job />, null, null, {
                    footer: null
                  })
                }
                name="jobName"
                onChange={e => mainForm.customHandleChange(e, "jobName")}
                onBlur={e => mainForm.customHandleBlur(e, "jobName")}
              />
              <span className="dangerColor caption textCenter">
                {mainForm.touched["jobName"] && mainForm.errors["jobName"]}
              </span>
            </Col>
          </React.Fragment>
        ) : null}

        <Col xs={24} sm={12}>
          <OptionsField
            title="Payor"
            addBtnText="Add Payors"
            disabled={!edit}
            initialValue={transaction[0].payor_name}
            customerValue={mainForm.values.customer}
            link="/add-contact"
            contactType="payor"
            placeholder="Select Payor"
            name="payor"
            onChange={e => mainForm.customHandleChange(e, "payor")}
            onBlur={e => mainForm.customHandleBlur(e, "payor")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["payor"] && mainForm.errors["payor"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <RecurringType
          title="Recurring"
          withoutAddButton
          disabled={!edit}
          value={parseInt(mainForm.values.frequency)}
          placeholder="Select Recurring"
          name="frequency"
          onChange={e => mainForm.customHandleChange(e, "frequency")}
            onBlur={e => mainForm.customHandleBlur(e, "frequency")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["frequency"] &&
              mainForm.errors["frequency"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            title="Start Date"
            customInput={DatePicker}
            disabled={!edit}
            value={mainForm.values.startDate}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="startDate"
            onChange={e => mainForm.customHandleChange(e, "startDate")}
            onBlur={e => mainForm.customHandleBlur(e, "startDate")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["startDate"] &&
              mainForm.errors["startDate"]}
          </span>
        </Col>

        <Col xs={24} sm={12}>
          <Field
            title="End Date"
            customInput={DatePicker}
            disabled={!edit}
            value={mainForm.values.endDate}
            placeholder="MM/DD/YY"
            format="MM-DD-YY"
            name="endDate"
            onChange={e => mainForm.customHandleChange(e, "endDate")}
            onBlur={e => mainForm.customHandleBlur(e, "endDate")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["endDate"] &&
              mainForm.errors["endDate"]}
          </span>
        </Col>

        {/* <Col xs={24} sm={12}>
          <Field
            title="Check Number"
            placeholder="Check Number"
            disabled={!edit}
            value={mainForm.values.checkNumber}
            name="checkNumber"
            onChange={e => mainForm.customHandleChange(e, "checkNumber")}
            onBlur={e => mainForm.customHandleBlur(e, "checkNumber")}
          />
          <span className="dangerColor caption textCenter">
            {mainForm.touched["checkNumber"] && mainForm.errors["checkNumber"]}
          </span>
        </Col> */}

        <Col xs={24} sm={12}>
          <Field
            title="Additional Notes"
            //name="additionalNotex"
            disabled={!edit}
            value={mainForm.values.additionalNote}
            placeholder="Note"
            style={{ height: "8.8rem" }}
            customInput={TextArea}
            name="additionalNote"
            onChange={e => mainForm.customHandleChange(e, "additionalNote")}
            onBlur={e => mainForm.customHandleBlur(e, "additionalNote")}
          />

          <span className="dangerColor caption textCenter">
            {mainForm.touched["additionalNote"] &&
              mainForm.errors["additionalNote"]}
          </span>
        </Col>
        </Row>
      </Col>
    </Row>
    </React.Fragment>
  );
};
const mapStateToProps = state => ({loading: state.transaction.updateRecurringLoading, settings: state.settings.settings});

export default withRouter(
  connect(mapStateToProps, { updateRecurringRequest, openModal, closeAllModal })(
    RecurringDeposit
  )
);
