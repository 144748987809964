import React, { useState } from "react";
import { Row, Col } from "antd";
import CardWrapper from "../../../../components/card";
import Select from "../../../../components/input/Select";
import Option from "../../../../components/input/SelectOptions";
import Button from "../../../../components/button";
import styled from "styled-components";

const ReportTypeContainer = styled.div`
  & {
    height: 100%;
  }
  & .ant-card-body {
    padding: 2rem 2rem;
  }
`;
const ReportTypeCard = props => {
  const [type, setType] = useState(props.initialValue)

  return (
    <React.Fragment>
      <ReportTypeContainer>
        <CardWrapper className="textCenter">
          <Row type="flex" justify="center" gutter={[0, 40]}>
            <Col>
                <h4 style={{marginBottom:"1.5rem" }}>{props.heading}</h4>
            </Col>
            <Col span={24}>
                <Select placeholder="Report Type" onChange={setType} value={type} style={{ width: "100%" }}>
                  <Option value="csv">CSV</Option>
                  <Option value="html">HTML</Option>
                  <Option value="pdf">PDF</Option>
                </Select>
            </Col>
            <Col span={12}>
                <Button block disabled={!type} loading={props.loading} onClick={() => props.onSubmit ? props.onSubmit(type) : null}>Continue</Button>
            </Col>
          </Row>
        </CardWrapper>
      </ReportTypeContainer>
    </React.Fragment>
  );
};
export default ReportTypeCard;
