import React from "react";
import { connect } from "react-redux";
import { Row, Col, Progress } from "antd";
import AuthContainer from "../../../appcomponents/shares/auth/AuthContainer";
import { Link } from "react-router-dom";
import Button from "../../../components/button/index";
import CodeWrapper from "../../../components/input/Code";
import { withTheme } from "styled-components";
import {
  verificationCodeRequest,
  passwordResetRequest
} from "../../../actions/Auth";


class VerificationCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      token: "",
      error: "",
      timer: false,
      countDown: 60,
      timerId: null,
      renderCodeInput: false,
    };
  }
  componentDidMount() {
    if(this.props.fromVerify)
      this.resendApiCall();
  }
  handleErrors = () => {
    let error = "";
    if (this.state.token.length == 0) {
      error = "Please enter the token ";
    }
    else if (this.state.token.length < 6 && this.state.token.length > 0) {
      error = "Incomplete Code ";
    }
    else {
      error = "";
    }
    this.setState({ error: error })
    return error;
  }
  handleChanged = val => {
    this.setState({
      token: val
    });
  };
  apiCall = () => {
    let isValid = this.handleErrors();
    if (isValid == "") {
      this.props.dispatch(
        verificationCodeRequest(
          {
            email: this.state.email,
            token: this.state.token
          },
          this.props.cb
        )
      );
    }
    else {
      console.error("Error: ", isValid);
    }
  }
  reRenderCodeInput = () => {
    if (this.state.renderCodeInput) {
      return (<React.Fragment>
        <CodeWrapper
          key="1"
          value=""
          renderCodeInput={this.state.renderCodeInput}
          className="mr-b"
          type="tel"
          fields="6"
          onChange={this.handleChanged}
        />
      </React.Fragment>);
    }
    else {
      return (<React.Fragment>
        <CodeWrapper
          key="2"
          value=""
          renderCodeInput={this.state.renderCodeInput}
          className="mr-b"
          type="tel"
          fields="6"
          onChange={this.handleChanged}
        />
      </React.Fragment>)
    }
  }
  resendApiCall = () => {
    this.setState({ token: "", renderCodeInput: !this.state.renderCodeInput });
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      }
      else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
    this.props.dispatch(
      passwordResetRequest(
        { email: this.state.email },
        () => {

          console.log("Reset Fields");
        },
        true
      )
    );
  };

  render() {
    // console.log("Verification Code State", this.state);
    // console.log("Verification Code State", this.state.token.length);
    // console.log("Verification Code Props", this.props);
    return (
      <AuthContainer
      heading="Recover Your Account"
      footer={<p>Already have an account? <Link to="/login">Sign In</Link></p>}
      steps={this.props.steps}
      backButtonLink="/main"
      >
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <p className="mr-b-md fontLight hintColor">
              ENTER THE VERIFICATION CODE YOU RECEIVED IN YOUR EMAIL.
            </p>
          </Col>
          <Col xs={24} sm={24}>
            {
              this.reRenderCodeInput()
            }
          </Col>
          <Col xs={24} sm={18}>
            <span style={{ color: "#f44336" }}>{this.state.error}</span>
          </Col>
          <Col className="mr-t mr-b caption" span={24}>
            {this.state.timer == true ? (
            <Progress
              //className="mr-t mr-b"
              width="50px"
              format={(per) => this.state.countDown}
              type="circle"
              strokeColor={{
                "0%": this.props.theme[this.props.theme.mode].primary.main,
                "100%": this.props.theme[this.props.theme.mode].secondary.main
              }}
              percent={(this.state.countDown / 60) * 100}
            />
          ) : (
              <React.Fragment>
                Didn't Receive a code?&nbsp; 
                <a
                  href="javascript:void(0)"
                  onClick={() => this.resendApiCall()}
                  className="caption"
                >
                  Resend
                </a>
              </React.Fragment>
            )}
          </Col>
          <Col xs={24} sm={18}>
            <Button
              loading={this.props.Auth.loading}
              onClick={() => {
                this.apiCall();
              }}
              className="mr-t-md mr-b-lg"
              size="large"
              style={{ width: "50%" }}
            >
              Verify
            </Button>
          </Col>
        </Row>
      </AuthContainer>
    );
  }
}

export default withTheme(connect(storeState => ({ Auth: storeState.Auth }))(
  VerificationCode)
);