import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  //   USERS_REQUEST,
  //   USER_REQUEST,
  //   CHANGE_USER_STATUS_REQUEST,
  //   INSTA_USER_REQUEST
  SERACH_TRANSACTIONS_REQUEST,
  DELETE_TRANSACTION_REQUEST,
  CLEARED_TRANSACTION_REQUEST
} from "../configurations/Types";
import {
  TRANSACTION_ENDPOINT,
  SERACH_TRANSACTIONS_ENDPOINT,
  DELETE_TRANSACTIONS_ENDPOINT,
  CLEARED_TRANSACTIONS_ENDPOINT
  //   USERS_ENDPOINT,
  //   USER_ENDPOINT,
  //   CHANGE_USER_STATUS_ENDPOINT,
  //   INSTA_MEDIA_ENDPOINT
} from "../configurations/Constants";
import {
  //   receivedUsers,
  //   usersError,
  //   receivedUser,
  //   userError,
  //   changeUserStatusSuccess,
  //   changeUserStatusError,
  //   instaUserSuccess
  searchTransactionsSuccess,
  searchTransactionsError,
  deleteTransactionSuccess,
  deleteTransactionError,
  ClearedTransactionsSuccess,
  ClearedTransactionsError
} from "../actions/SearchTransactions";
import moment from "moment";
import info from "../components/message/index";
// import { tableData } from "../mocks/Users"
import {
  privateAgent,
  CancelToken
  //   instaAgent
} from "../configurations/AxiosAgent";

let token;
// -- Search Transactions
const searchTransactionsAPI = (params, token) =>
  privateAgent.get(`${TRANSACTION_ENDPOINT}${SERACH_TRANSACTIONS_ENDPOINT}`, {
    cancelToken: token.token,
    params: params
  });

function* searchTransactions(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield searchTransactionsAPI(action.params, token);
    console.log("USER API GET", res.data);
    yield put(
      searchTransactionsSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(searchTransactionsError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
  //yield put(receivedUsers(res.data.data))
}

// -- Search Transactions

//-- Delete Transaction
const deleteTransaction = id => {
  console.log("deleteTransaction apicall", id);
  return privateAgent.delete(
    `${TRANSACTION_ENDPOINT}${DELETE_TRANSACTIONS_ENDPOINT}/${id}`
  );
};

function* deleteTransactionRequest(action) {
  let res;
  try {
    res = yield deleteTransaction(action.id);
    yield put(deleteTransactionSuccess());
    if (action.cb) {
      action.cb();
    }
    info("success", `${res.data.data.message}`);
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(deleteTransactionError(e));
  }
}
//-- Delete Transaction
const clearedTransactionApi = body => {
  let current_time = moment().format("YYYY-MM-DD HH:mm:ss");
  console.log("clearedTransactionApi", body);
  return privateAgent.post(
    `${TRANSACTION_ENDPOINT}${CLEARED_TRANSACTIONS_ENDPOINT}`,
    {
      ids: body.id,
      is_cleared: body.flag,
      current_time
    }
  );
};
// --Cleared Transactions
function* clearedTransaction(action) {
  let res;
  try {
    res = yield clearedTransactionApi(action);
    yield put(ClearedTransactionsSuccess());
    if (action.cb) {
      action.cb();
      info("success", `${res.data.data.message}`);
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(ClearedTransactionsError(e));
  }
}
// --Cleared Transactions

// function* userStatusRequest(action) {
//   console.log("USER STATUS SAGA RUN", action);
//   let res;
//   try {
//     res = yield userStatusAPI(action.params);
//     console.log("USER STATUS API GET", res.data.data);
//     yield put(changeUserStatusSuccess(res));
//     info("success", `${res.data.data}`);
//     if (action.cb) {
//       action.cb(res);
//     }
//   } catch (e) {
//     console.log("ERROR", e);
//     yield put(changeUserStatusError(e));
//     //info("error", `${e.response ? e.response.data.error.message : "Network error"}`);
//     // if (action.cb) {
//     //   action.cb();
//     // }
//   } finally {
//     if (yield cancelled()) {
//       console.log("CANCELLED");
//     }
//   }
// }
// Our watcher Saga: spawn a new Search Transactions task on each USERS_REQUEST
export function* watchSearchTransactions() {
  yield takeLatest(SERACH_TRANSACTIONS_REQUEST, searchTransactions);
  yield takeLatest(DELETE_TRANSACTION_REQUEST, deleteTransactionRequest);
  yield takeLatest(CLEARED_TRANSACTION_REQUEST, clearedTransaction);
  //   yield takeLatest(USER_REQUEST, userRequest)
  //   yield takeLatest(CHANGE_USER_STATUS_REQUEST, userStatusRequest)
  //   yield takeLatest(INSTA_USER_REQUEST, userInstaRequest)
}
