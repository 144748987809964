 import { put, takeLatest, cancelled } from "redux-saga/effects";
import {
  GET_BANK_TRANSACTION_REQUEST,
  GET_TRANSACTION_REQUEST,
  ADD_TRANSACTION_REQUEST,
  UPDATE_TRANSACTION_REQUEST,
  UPDATE_RECURRING_REQUEST,
  UPDATE_TRANSFER_REQUEST,
  RECORD_TRANSFER_REQUEST,
  RECORD_RECURRING_REQUEST,
  DELETE_ACCOUNT_HEAD_REQUEST,
  DELETE_JOB_REQUEST,
  BANK_ACCOUNT_SUMMARY_REQUEST,
  RECONCILED_REQUEST,
  GET_RECURRING_REQUEST
} from "../configurations/Types";
import {
  TRANSACTION_ENDPOINT,
  BANK_ACCOUNT_ENDPOINT,
  CREATE_TRANSACTION_ENDPOINT,
  UPDATE_TRANSACTION_ENDPOINT,
  UPDATE_RECURRING_ENDPOINT,
  UPDATE_TRANSFER_ENDPOINT,
  BANK_ACCOUNT__SUMMARY_ENDPOINT,
  GET_RECURRING_ENDPOINT,
  RECORD_TRANSFER_ENDPOINT,
  RECORD_RECURRING_ENDPOINT,
  DELETE_ACCOUNT_HEAD_ENDPOINT,
  UNRECONCILED_TRANSACTION_ENDPOINT,
  DELETE_JOB_ENDPOINT
} from "../configurations/Constants";
import {
  getBankTransactionsSuccess,
  getBankTransactionsError,
  getTransactionRequest,
  getTransactionSuccess,
  getTransactionError,
  getReconciledRequest,
  getReconciledSuccess,
  getReconciledError
} from "../actions/transaction/Transaction";
import {
  getBankAccountSummarySuccess,
  getBankAccountSummaryError,
  getRecurringSuccess,
  getRecurringError
} from "../actions/Transaction";
import {
  addTransactionSuccess,
  addTransactionError,
  updateTransactionSuccess,
  updateTransactionError,
} from "../actions/transaction/Deposit&WithDrawlTransaction";

import {
  recordTransferSuccess,
  recordTransferError,
  updateTransferSuccess,
  updateTransferError
} from "../actions/transaction/TransferTransaction";
import {
  recordRecurringSuccess,
  recordRecurringError,
  updateRecurringSuccess,
  updateRecurringError,
} from "../actions/transaction/RecurringTransaction";
import {
  deleteAccountHeadSuccess,
  deleteAccountHeadError,
  deleteJobSuccess,
  deleteJobError
} from "../actions/transaction/Delete";


import info from "../components/message/index";
import { privateAgent, CancelToken } from "../configurations/AxiosAgent";
import moment from "moment";
let current_time = () => moment().format("YYYY-MM-DD HH:mm:ss");
// Single bank get Transactions

let token;

const reconcilledAPI = (params, token) => {
  console.log("singleBankDetailsAPIRECONCILE", params);

  const { bankId: id, ...rest } = params;
  rest["bank_balance"] = rest.amount;
  delete rest.amount;
  //let balance = params.amount;
  //delete params.id;
  return privateAgent.get(
    `${BANK_ACCOUNT_ENDPOINT}/${id}${UNRECONCILED_TRANSACTION_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: {
        limit: 10,
        offset: 0,
        ...rest
      }
    }
  );
};

function* getreconcilled(action) {
  console.log("USER SAGA RUN I AM RUN RECON", action);
  console.log("YES", action.id);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield reconcilledAPI(action.params, token);
    console.log("USER API GET", res.data);
    yield put(
      getReconciledSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    if (action.cb) {
      action.cb(res.data.data, null);
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      if (action.cb) {
      action.cb(null, e);
    }
    }
    yield put( getReconciledError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  // if (action.cb) {
  //   action.cb(res);
  // }
}

const singleBankDetailsAPI = (params, token) => {
  console.log("singleBankDetailsAPI", params);

  let id = params.id;
  delete params.id;
  // delete params.offset;
  // params.limit=10000;
  return privateAgent.get(
    `${BANK_ACCOUNT_ENDPOINT}/${id}${TRANSACTION_ENDPOINT}`,
    {
      cancelToken: token.token,
      params: params
    }
  );
};

function* getSingleBankTransaction(action) {
  console.log("USER SAGA RUN", action);
  let res;
  try {
    if (token) {
      token.cancel();
    }
    token = CancelToken.source();
    res = yield singleBankDetailsAPI(action.params, token);
    console.log("USER API GET", res.data);
    yield put(
      getBankTransactionsSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    // if (action.callback) {
    //   action.callback();
    // }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getBankTransactionsError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}
// Single bank get Transactions

// addTransactions Deposit & WithDrawl
const addTransactionApi = body => {
  // current_time;
  console.log("body", body);
  // console.log("saga Field", body.bankName.split("*"[0]));

  if(body.splits) {
    for (const splitObj of body.splits){
      console.log("Split Obj: ", splitObj);
      for (const split in splitObj) {
        splitObj[split] = split === "amount" ? splitObj[split] ? parseFloat(splitObj[split]).toFixed(2) : "" : parseInt(splitObj[split]); // split_data.job_id
      }
    }
  }
  console.log("Body API before: ", body)
  let bodyAPI = {
    bank_account_id: parseInt(body.bankName),
    lawyer_id: parseInt(body.lawyerName),
    customer_id: parseInt(body.customerName),
    amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
    account_head_id: (body.accountName && parseInt(body.accountName)) || "",
    job_id: (body.jobName && parseInt(body.jobName)) || "",
    payor_id: (body.payor || body.payee) ? parseInt(body.type === "deposit" ? body.payor : body.payee) : "",
    date_of_transaction: body.dateOfTransaction && body.dateOfTransaction.format("YYYY-MM-DD HH:mm:ss"),
    check_number: body.checkNumber && parseInt(body.checkNumber),
    image: body.image,
    is_cleared: body.cleared||false,
    additional_notes: body.additionalNote,
    current_time: current_time(),
    transaction_type: body.type,
    is_split: body.is_split || false,
    split_data: body.splits && JSON.stringify(body.splits),
  };

  if(!body.is_split) {
    // delete bodyAPI.account_head_id;
    // delete bodyAPI.job_id;
    delete bodyAPI.split_data;
  }

  let formData = new FormData();
  console.log("obj k bhair");

  for (const key in bodyAPI) {
    console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    formData.append(key, bodyAPI[key]);
  }
  // Object.keys(bodyAPI).forEach((item, index) => {
  //   console.log("item sagasas",item)
  //   // formData.append(item);
  // });

  console.log("BODY API", bodyAPI);
  return privateAgent.post(
    `${TRANSACTION_ENDPOINT}${CREATE_TRANSACTION_ENDPOINT}`,
    formData,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
};
function* addTransactionRequest(action) {
  let res;
  try {
    res = yield addTransactionApi(action.body);
    yield put(addTransactionSuccess());
    info("success", res.data.data.message);
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(addTransactionError());
    }
  }
}

// updateTransactions Deposit & WithDrawl
const updateTransactionApi = (body, id) => {
  // current_time;
  console.log("body", body);
  // console.log("saga Field", body.bankName.split("*"[0]));

  if(body.splits) {
    for (const splitObj of body.splits){
      console.log("Split Obj: ", splitObj);
      for (const split in splitObj) {
        splitObj[split] = split === "amount" ? splitObj[split] ? parseFloat(splitObj[split]).toFixed(2) : "" : parseInt(splitObj[split]); // split_data.job_id
      }
    }
  }
  console.log("Body API before: ", body)
  let bodyAPI = {
    bank_account_id: parseInt(body.bankName),
    lawyer_id: parseInt(body.lawyerName),
    customer_id: parseInt(body.customerName),
    amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
    account_head_id: (body.accountName && parseInt(body.accountName)) || "",
    job_id: (body.jobName && parseInt(body.jobName)) || "",
    payor_id: (body.payor || body.payee) ? parseInt(body.type === "deposit" ? body.payor : body.payee) : "",
    date_of_transaction: body.dateOfTransaction && body.dateOfTransaction.format("YYYY-MM-DD HH:mm:ss"),
    check_number: body.checkNumber && parseInt(body.checkNumber),
    image: body.image,
    is_cleared: body.cleared||false,
    additional_notes: body.additionalNote,
    current_time: current_time(),
    transaction_type: body.type,
    is_split: body.is_split || false,
    split_data: body.splits && JSON.stringify(body.splits),
  };

  if(!body.is_split) {
    // delete bodyAPI.account_head_id;
    // delete bodyAPI.job_id;
    delete bodyAPI.split_data;
  }

  if(body.image && body.image.image_url) {
    // delete bodyAPI.account_head_id;
    // delete bodyAPI.job_id;
    bodyAPI.image_url = body.image.image_url;
    bodyAPI.thumb_image_url = body.image.thumb_image_url;
    delete bodyAPI.image;
  }

  let formData = new FormData();
  console.log("obj k bhair");

  for (const key in bodyAPI) {
    console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    formData.append(key, bodyAPI[key]);
  }
  // Object.keys(bodyAPI).forEach((item, index) => {
  //   console.log("item sagasas",item)
  //   // formData.append(item);
  // });

  console.log("BODY API", bodyAPI);
  return privateAgent.post(
    `${TRANSACTION_ENDPOINT}${UPDATE_TRANSACTION_ENDPOINT}/${id}`,
    formData,
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
};
function* updateTransaction(action) {
  let res;
  try {
    res = yield updateTransactionApi(action.body, action.id);
    yield put(updateTransactionSuccess());
    yield put(getTransactionRequest(action.id))
    info("success", res.data.data.message);
    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(updateTransactionError());
    }
  }
}

// get Bank Account Summary
const getSummaryAPI = (bank_account_id, month, year) => {
  console.log("getSummaryAPI", year);
  return privateAgent.get(
    `${BANK_ACCOUNT_ENDPOINT}${BANK_ACCOUNT__SUMMARY_ENDPOINT}`,
    {
      params: {
        bank_account_id,
        month,
        year
      }
    }
  );
};

function* getBankSummary(action) {
  let res;
  try {
    res = yield getSummaryAPI(action.id, action.month, action.year);
    console.log("function*", res.data.data);
    yield put(getBankAccountSummarySuccess(res.data.data));
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getBankAccountSummaryError(e));
  }
}

// get Recurring Request
let recurringToken;
const getRecurringAPI = (params, token) =>
  privateAgent.get(`${TRANSACTION_ENDPOINT}${GET_RECURRING_ENDPOINT}`, {
    cancelToken: token.token,
    params: params
  });

function* recurringRequest(action) {
  console.log("USER SAGA RUN RECURRING", action);
  let res;
  try {
    if (recurringToken) {
      recurringToken.cancel();
    }
    recurringToken = CancelToken.source();
    res = yield getRecurringAPI(action.params, recurringToken);
    console.log("USER API GET RECURRING", res.data.data);
    yield put(
      getRecurringSuccess(res.data.data, action.clearFlag, action.ofPage)
    );
    if (action.callback) {
      action.callback();
    }
  } catch (e) {
    console.log("ERROR", e);
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
    }
    yield put(getRecurringError(e));
  } finally {
    if (yield cancelled()) {
      console.log("CANCELLED");
    }
  }
  //console.log("In saga", res);
  if (action.callback) {
    action.callback(res);
  }
}

// get Recurring Request

// Record Transfer transaction
const recordTransferApi = (body) => {
  return privateAgent.post(
    `${TRANSACTION_ENDPOINT}${RECORD_TRANSFER_ENDPOINT}`,
    body
  );
};

function* recordTransfer(action) {
  let res;
  try {

    let body = action.body;

    if(body.splits) {
      for (const splitObj of body.splits){
        console.log("Split Obj: ", splitObj);
        for (const split in splitObj) {
          splitObj[split] = split === "amount" ? splitObj[split] ? parseFloat(splitObj[split]).toFixed(2) : "" : parseInt(splitObj[split]); // split_data.job_id
        }
      }
    }

    console.log("Body API before: ", body)
    let bodyAPI = {
      bank_account_id: parseInt(body.bankName),
      from: {
        lawyer_id: parseInt(body.fromLawyerName),
        customer_id: parseInt(body.fromCustomerName),
        account_head_id: (body.fromAccountName && parseInt(body.fromAccountName)) || "",
        job_id: (body.fromJobName && parseInt(body.fromJobName)) || "",
      },
      to: {
        lawyer_id: parseInt(body.toLawyerName),
        customer_id: parseInt(body.toCustomerName),
        account_head_id: (body.toAccountName && parseInt(body.toAccountName)) || "",
        job_id: (body.toJobName && parseInt(body.toJobName)) || "",
      },
      amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
      date_of_transaction: body.dateOfTransaction && body.dateOfTransaction.format("YYYY-MM-DD HH:mm:ss"),
      additional_note: body.additionalNote,
      current_time: current_time(),
      //account_type: body.fromAccountType,
      transaction_type: body.type,
      is_split: "false"
      //(body.is_split && body.is_split.toString()) || "false",
      //split_data: (body.splits && body.splits) || "",
    };
    
    console.log("OBJECT", bodyAPI, body)

    // let formData = new FormData();

    // for (const key in bodyAPI) {
    //   console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    //   formData.append(key, bodyAPI[key]);
    // }

    res = yield recordTransferApi(bodyAPI);

    yield put(recordTransferSuccess());
    info("success", res.data.data.message)

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(recordTransferError());
    }
  }
}

// Update Transfer transaction
const updateTransferApi = (body, id) => {
  return privateAgent.put(
    `${TRANSACTION_ENDPOINT}${UPDATE_TRANSFER_ENDPOINT}/${id}`,
    body
  );
};

function* updateTransfer(action) {
  let res;
  try {

    let body = action.body;

    if(body.splits) {
      for (const splitObj of body.splits){
        console.log("Split Obj: ", splitObj);
        for (const split in splitObj) {
          splitObj[split] = split === "amount" ? splitObj[split] ? parseFloat(splitObj[split]).toFixed(2) : "" : parseInt(splitObj[split]); // split_data.job_id
        }
      }
    }

    console.log("Body API before: ", body)
    let bodyAPI = {
      bank_account_id: parseInt(body.bankName),
      from: {
        lawyer_id: parseInt(body.fromLawyerName),
        customer_id: parseInt(body.fromCustomerName),
        account_head_id: (body.fromAccountName && parseInt(body.fromAccountName)) || "",
        job_id: (body.fromJobName && parseInt(body.fromJobName)) || "",
      },
      to: {
        lawyer_id: parseInt(body.toLawyerName),
        customer_id: parseInt(body.toCustomerName),
        account_head_id: (body.toAccountName && parseInt(body.toAccountName)) || "",
        job_id: (body.toJobName && parseInt(body.toJobName)) || "",
      },
      amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
      date_of_transaction: body.dateOfTransaction && body.dateOfTransaction.format("YYYY-MM-DD HH:mm:ss"),
      additional_note: body.additionalNote,
      current_time: current_time(),
      //account_type: body.fromAccountType,
      transaction_type: body.type,
      is_split: "false"
      //(body.is_split && body.is_split.toString()) || "false",
      //split_data: (body.splits && body.splits) || "",
    };
    
    console.log("OBJECT", bodyAPI, body)

    // let formData = new FormData();

    // for (const key in bodyAPI) {
    //   console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    //   formData.append(key, bodyAPI[key]);
    // }

    res = yield updateTransferApi(bodyAPI, action.id);

    yield put(updateTransferSuccess());
    yield put(getTransactionRequest(action.id))
    info("success", res.data.data.message)

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(updateTransferError());
    }
  }
}

// Record Recurring transaction
const recordRecurringApi = (body) => {
  return privateAgent.post(
    `${TRANSACTION_ENDPOINT}${RECORD_RECURRING_ENDPOINT}`,
    body
  );
};

function* recordRecurring(action) {
  let res;
  try {

    let body = action.body;

    console.log("Body API before: ", body)
    let bodyAPI = {
      bank_account_id: body.bankName,
      lawyer_id: body.lawyerName,
      customer_id: body.customerName,
      amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
      account_head_id: (body.accountName && body.accountName) || "",
      job_id: (body.jobName && body.jobName) || "",
      payor_id: body.type === "deposit" ? body.payor : body.payee,
      frequency: body.frequency.toString(),
      start_date: body.startDate && body.startDate.format("YYYY-MM-DD HH:mm:ss"),
      end_date: body.endDate && body.endDate.format("YYYY-MM-DD HH:mm:ss"),
      check_number: body.checkNumber && body.checkNumber,
      cleared: (body.cleared && body.cleared.toString()) || "false",
      additional_notes: body.additionalNote,
      current_time: current_time(),
      transaction_type: body.type,
    };
    
    console.log("OBJECT", bodyAPI, body)

    // let formData = new FormData();

    // for (const key in bodyAPI) {
    //   console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    //   formData.append(key, bodyAPI[key]);
    // }

    res = yield recordRecurringApi(bodyAPI);

    yield put(recordRecurringSuccess());
    info("success", res.data.data.message)

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(recordRecurringError());
    }
  }
}

// Update Recurring transaction
const updateRecurringApi = (body, id) => {
  return privateAgent.put(
    `${TRANSACTION_ENDPOINT}${UPDATE_RECURRING_ENDPOINT}/${id}`,
    body
  );
};

function* updateRecurring(action) {
  let res;
  try {

    let body = action.body;

    console.log("Body API before: ", body)
    let bodyAPI = {
      bank_account_id: body.bankName,
      lawyer_id: body.lawyerName,
      customer_id: body.customerName,
      amount: body.amount ? parseFloat(body.amount).toFixed(2) : "",
      account_head_id: (body.accountName && body.accountName) || "",
      job_id: (body.jobName && body.jobName) || "",
      payor_id: body.type === "deposit" ? body.payor : body.payee,
      frequency: body.frequency.toString(),
      start_date: body.startDate && body.startDate.format("YYYY-MM-DD HH:mm:ss"),
      end_date: body.endDate && body.endDate.format("YYYY-MM-DD HH:mm:ss"),
      check_number: body.checkNumber && body.checkNumber,
      cleared: (body.cleared && body.cleared.toString()) || "false",
      additional_notes: body.additionalNote,
      current_time: current_time(),
      transaction_type: body.type,
    };
    
    console.log("OBJECT", bodyAPI, body)

    // let formData = new FormData();

    // for (const key in bodyAPI) {
    //   console.log(`Form Data: ${key} = ${bodyAPI[key]}`);
    //   formData.append(key, bodyAPI[key]);
    // }

    res = yield updateRecurringApi(bodyAPI, action.id);

    yield put(updateRecurringSuccess());
    yield put(getTransactionRequest(action.id))
    info("success", res.data.data.message)

    if (action.cb) {
      action.cb();
    }
  } catch (e) {
    if (e.response) {
      info("error", `${e.response.data.error.message}`);
      yield put(updateRecurringError());
    }
  }
}

//Delete Account Head
const deleteAccountHeadApi = (id) => {
  return privateAgent.delete(
    `${TRANSACTION_ENDPOINT}${DELETE_ACCOUNT_HEAD_ENDPOINT}/${id}`
  );
};

function* deleteAccountHead(action) {
  let res;
  try {
    res = yield deleteAccountHeadApi(action.id);
    yield put(deleteAccountHeadSuccess());
    info("success", "Account successfully deleted");
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response) info("error", `${e.response.data.error.message}`);
    yield put(deleteAccountHeadError());
  }
}

//Delete Job
const deleteJobApi = (id) => {
  return privateAgent.delete(
    `${TRANSACTION_ENDPOINT}${DELETE_JOB_ENDPOINT}/${id}`
  );
};

function* deleteJob(action) {
  let res;
  try {
    res = yield deleteJobApi(action.id);
    yield put(deleteJobSuccess());
    info("success", "Job successfully deleted");
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response) info("error", `${e.response.data.error.message}`);
    yield put(deleteJobError());
  }
}

//Get Transaction
const getTransactionApi = (id) => {
  return privateAgent.get(
    `${TRANSACTION_ENDPOINT}/${id}`
  );
};

function* getTransaction(action) {
  let res;
  try {
    res = yield getTransactionApi(action.id);
    console.log("RESPONSE", res.data)
    yield put(getTransactionSuccess(res.data.data.transaction));
    if (action.cb) action.cb();
  } catch (e) {
    if (e.response) info("error", `${e.response.data.error.message}`);
    yield put(getTransactionError());
  }
}

// Saga Watcher Watch All requests opertions
export function* watchTransactions() {
  yield takeLatest(GET_BANK_TRANSACTION_REQUEST, getSingleBankTransaction);
  yield takeLatest(GET_TRANSACTION_REQUEST, getTransaction);
  yield takeLatest(ADD_TRANSACTION_REQUEST, addTransactionRequest);
  yield takeLatest(UPDATE_TRANSACTION_REQUEST, updateTransaction);
  yield takeLatest(UPDATE_RECURRING_REQUEST, updateRecurring);
  yield takeLatest(UPDATE_TRANSFER_REQUEST, updateTransfer);
  yield takeLatest(RECORD_TRANSFER_REQUEST, recordTransfer);
  yield takeLatest(RECORD_RECURRING_REQUEST, recordRecurring);
  yield takeLatest(DELETE_ACCOUNT_HEAD_REQUEST, deleteAccountHead);
  yield takeLatest(DELETE_JOB_REQUEST, deleteJob);
  yield takeLatest(GET_RECURRING_REQUEST, recurringRequest);
  yield takeLatest(BANK_ACCOUNT_SUMMARY_REQUEST, getBankSummary);
  yield takeLatest(RECONCILED_REQUEST, getreconcilled);
}
