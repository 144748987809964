import useFormikWrapper from "../../../../components/formikwrapper/FormikWrapper";
import {
  BankAccountLedgerSchema
} from "../../../../configurations/Schemas/index";

import moment from "moment";

const useBankAccountLedgerForm = props => {

  const formik = useFormikWrapper({
    ...props,
    validationSchema: BankAccountLedgerSchema
  });

  //Reset all date values when date type changes
  formik.onChangeDateType = (e) => {
    let date_type = e.target ? e.target.value : e;

    formik.setValues({
      ...formik.values,
      date_type,
      month : date_type === "monthly" ? moment().month() + 1 : "",
      year: date_type === "custom" ? "" : formik.values.year || moment().year(),
      start_date: date_type === "custom" ? moment() : "",
      start_day: date_type === "custom" ? moment().date() : date_type === "quaterly" ? moment().startOf("quarter").date() : "",
      start_month: date_type === "custom" ? moment().month() + 1 : date_type === "quaterly" ? moment().startOf("quarter").month() + 1 : "",
      start_year: date_type === "custom" ? moment().year() : date_type === "quaterly" ? formik.values.year || moment().year() : "",
      end_date: "",
      end_day: date_type === "quaterly" ? moment().endOf("quarter").date() : "",
      end_month: date_type === "quaterly" ? moment().endOf("quarter").month() + 1 : "",
      end_year: date_type === "quaterly" ? formik.values.year || moment().year() : "",
      quarter: date_type === "quaterly" ? "q" + moment().quarter() : undefined
    });

    formik.setTouched({
      ...formik.touched,
      month : undefined,
      year: date_type === "custom" ? undefined : formik.touched.year,
      start_date: undefined,
      start_day: undefined,
      start_month: undefined,
      start_year: undefined,
      end_date: undefined,
      end_day: undefined,
      end_month: undefined,
      end_year: undefined,
      quarter: undefined
    });
  }

  formik.onChangeQuarterYear = (e) => {
    if(formik.values.quarter) {
      // let start_year = e.year();
      // let end_year = e.year();
      // let start_month = formik.values.quarter === "q1" ? 0 : formik.values.quarter === "q2" ? 3 : formik.values.quarter === "q3" ? 6 : formik.values.quarter === "q4" ? 9 : "";
      // let end_month = formik.values.quarter === "q1" ? 2 : formik.values.quarter === "q2" ? 5 : formik.values.quarter === "q3" ? 8 : formik.values.quarter === "q4" ? 11 : "";
      // let start_day = 
      let quarter = moment().year(e.year()).quarter(formik.values.quarter === "q1" ? 1 : formik.values.quarter === "q2" ? 2 : formik.values.quarter === "q3" ? 3 : formik.values.quarter === "q4" ? 4 : "");

      formik.setValues({
        ...formik.values,
        year: e.year(),
        start_day: quarter.startOf("quarter").date(),
        start_month: quarter.startOf("quarter").month() + 1,
        start_year: e.year(),
        end_day: quarter.endOf("quarter").date(),
        end_month: quarter.endOf("quarter").month() + 1,
        end_year: e.year()
      });
    } else {
      formik.customHandleChange(e.year(), "year");
    }
  }

  formik.onChangeQuarter = (e) => {
    if(formik.values.year) {
      // let start_year = e.year();
      // let end_year = e.year();
      // let start_month = formik.values.quarter === "q1" ? 0 : formik.values.quarter === "q2" ? 3 : formik.values.quarter === "q3" ? 6 : formik.values.quarter === "q4" ? 9 : "";
      // let end_month = formik.values.quarter === "q1" ? 2 : formik.values.quarter === "q2" ? 5 : formik.values.quarter === "q3" ? 8 : formik.values.quarter === "q4" ? 11 : "";
      // let start_day = 
      let quarter = moment().year(formik.values.year).quarter(e === "q1" ? 1 : e === "q2" ? 2 : e === "q3" ? 3 : e === "q4" ? 4 : "");

      formik.setValues({
        ...formik.values,
        quarter: e,
        //year: formik.values.year,
        start_day: quarter.startOf("quarter").date(),
        start_month: quarter.startOf("quarter").month() + 1,
        start_year: formik.values.year,
        end_day: quarter.endOf("quarter").date(),
        end_month: quarter.endOf("quarter").month() + 1,
        end_year: formik.values.year
      });
    } else {
      formik.customHandleChange(e, "quarter");
    }
  }

  formik.onStartDateChange = (e, name) => {
    formik.setValues({
      ...formik.values,
      start_date: e,
      start_day: e ? e.date() : "",
      start_month: e ? e.month() + 1 : "",
      start_year: e ? e.year() : "",
    });
  }

  formik.onEndDateChange = (e, name) => {
    formik.setValues({
      ...formik.values,
      end_date: e,
      end_day: e ? e.date() : "",
      end_month: e ? e.month() + 1 : "",
      end_year: e ? e.year() : "",
    });
  }

  return formik;
};

export default useBankAccountLedgerForm;
