import React from "react";
import { connect } from "react-redux";

import ReportTypeCard from "./General";
import EscrowDisclosureStatementForm from "../forms/EscrowDisclosureStatement";

import { openModal, closeAllModal } from "../../../../actions/Modal"


const EscrowDisclosureStatement = props => (
  <ReportTypeCard
  initialValue={props.initialType || "pdf"} 
  heading="Escrow Disclosure Statement" 
  onSubmit={format => {
    props.openModal(
      null,
      <EscrowDisclosureStatementForm initialValues={props.initialFormValues} format={format} />,
      null,
      null,
      {
        footer: null
      }
    );
  }} 
  />
)

export default connect(null, { openModal, closeAllModal })(EscrowDisclosureStatement);
