import React from 'react';
import { Checkbox } from 'antd';
import styled from "styled-components";

const StyledCheckbox = styled(Checkbox)`
&:hover .ant-checkbox-inner {
  border: 2px solid ${props => props.theme[props.theme.mode].primary.main};
}

& .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border: 2px solid ${props => props.theme[props.theme.mode].primary.main}66;
  border-radius: 10px;
}

.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${props => props.theme[props.theme.mode].primary.main};
}

.ant-checkbox-checked::after {
  //border: 1px solid ${props => props.theme[props.theme.mode].primary.main};
  border: 0px solid ${props => props.theme[props.theme.mode].primary.main};
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${props => props.theme[props.theme.mode].primary.main};
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: transparent;
  border: 2px solid ${props => props.theme[props.theme.mode].primary.main};
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 12px;
  background-color: ${props => props.theme[props.theme.mode].primary.main};
}

/* & .ant-checkbox-inner:hover {
  border: 2px solid ${props => props.theme[props.theme.mode].primary.main};
} */
`;


const CheckboxWrapper = (props)=>{
    return (
    <StyledCheckbox {...props}/>
    );
}

export default CheckboxWrapper;