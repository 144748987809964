import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteStatementsRequest } from "../../actions/Statements";
import { useEffect, useState } from "react";
import ContactForm from "../../appcomponents/shares/landing/ContactForm";
import Button from "../../components/button/index";
import Image from "../../appcomponents/EscrowTrakker/formcontrols/InputWithTitle/uploadImageWithTitle";
import HeaderWrapper from "../../components/header/index";
import useStatementForm from "./useStatementForm";
import PDFICON from "../../assets/images/pdf.png";
import PopconfirmWrapper from "../../components/pop-confirm";
import StatementModal from "../shell/StatementModal";
import { openModal} from "../../actions/Modal";
import { FiEdit, FiMoreHorizontal, FiAlertTriangle } from "react-icons/fi";
import {
  uploadStatementsRequest,
  getStatementsRequest,
} from "../../actions/Statements";
import { useDispatch } from "react-redux";
import { FiAlertOctagon, FiPlus } from "react-icons/fi";
import { RiDeleteBin7Line } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
const StatementTable = (props) => {
  const { id, fileName, URL } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const deleteStatement = (id) => {
    dispatch(deleteStatementsRequest(id));
  };
  return (
    <Row
      style={{
        // overFlow: "auto",
        // height: "10vh",
        borderTop: "1px solid #f5f5f5",
        borderBottom: "1px solid #f5f5f5",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Col span={14} style={{ display: "flex", flexWrap: "wrap" }}>
        <img
          style={{ marginRight: "5px" }}
          src={PDFICON}
          alt="PDF ICON NOt Found"
        />
        <p style={{ alignSelf: "center" }}> {fileName}</p>
      </Col>
      <Col span={3}>
        <Button
          //bankDetail={state}

          // props.history.push({
          //   pathname: `/edit-bank/${filters.id}`
          // })
          onClick={() =>
            history.push({
              pathname: "/reports/send-report",
              state: {
                referrer: location.pathname,
                referrerState: location.state,
                reportUrl: URL,
                reportFormat: "pdf",
              },
            })
          }
        >
          Email
        </Button>
      </Col>
      <Col span={4}>
        <Button
          outlined
          // onClick={() =>
          //   history.push({
          //     pathname: "/print-cheques",
          //     state: {
          //       id: props.bank,
          //       referrer: `/bank-account-details/${props.bank.bankId}`,
          //     },
          //   })
          // }
          onClick={() => window.open(URL)}
        >
          Print
        </Button>
      </Col>
      {/* <Col span={4}><RiDeleteBin7Line style={{cursor: "pointer", height:"15px", color:"red"}}  onClick={(e) => { e.preventDefault();
        deleteStatement(id);
      }}/>Delete</Col> */}
      <Col span={4}>
      <div
        style={{
          padding: "8px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={() => {
          props.openModal(
              null,
              <StatementModal fileID={id} fileName={fileName} />,
              null,
              null,
              {
                footer: null
              }
            );
      }}
        //onClick={
          // history.push({
          //   pathname: `/view-transaction/${r.original.transaction_source_id}`,
          //   state: {
          //     referrer: "/search-transaction",
          //     // referrerState: {
          //     //   transaction_type:
          //     //     filters.transaction_type,
          //     //   month: filters.month,
          //     //   year: filters.years,
          //     //   like: filters.like,
          //     // },
          //   },
          // })
        //}
      >
        <FiEdit style={{ marginRight: 5 }} className={"appColor"} />
        <span>Edit</span>
        </div>
        <PopconfirmWrapper
          title={<h4>Are you sure you want to delete this Bank Statement?</h4>}
          icon={
            <FiAlertTriangle
              style={{
                position: "absolute",
                color: "#f44336",
                left: 0,
                top: 0,
                marginTop: 6,
              }}
            />
          }
          cancelText="No"
          okText="Yes"
          onConfirm={() => dispatch(deleteStatementsRequest(id))}
        >
          {/* <ButtonWrapper className="deleteBtn"> */}
          <div
            style={{
              padding: "8px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AiOutlineDelete
              className="deleteIcon"
              style={{ color: "#FF494C", marginRight: 5 }}
            />
            <span>Delete</span>
          </div>
          {/* </ButtonWrapper> */}
        </PopconfirmWrapper>
      </Col>
    </Row>
  );
};

export default withRouter(connect(state => ({ reports: state.Reports, settings: state.settings.settings }), { openModal })(StatementTable));
