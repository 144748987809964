import React, { Fragment } from "react";
import { Collapse, Row, Col } from "antd";
import Styled from "styled-components";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const { Panel } = Collapse;
const Accordion = Styled(Collapse)`
  background: transparent;
  border: none;
  & .ant-collapse-content-box{
      padding-top: 4px !important;
      padding-left: 2.1rem;
  }
  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
    padding: 1.5rem 2.1rem;
    margin-bottom: 0;
  } 
  & > .ant-collapse-item, & .ant-collapse-item .ant-collapse-content {
    border: none;
  }
  & > .ant-collapse-item {
    box-shadow: 0px 5px 20px ${(props) =>
      props.theme[props.theme.mode].textColor.primary}0D;
    margin-bottom: 15px;
  }
  & > .ant-collapse-item:last-child {
    border-radius: 1rem;
    margin-bottom: 0;
  }
  & > .ant-collapse-item .ant-collapse-header {
    font-size: ${(props) =>
      props.theme[props.theme.mode].textSizes.relative.captions};
    font-weight: 700;
  }
  & .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: ${(props) => props.theme[props.theme.mode].primary.main}
  }
  & .ant-collapse-arrow{
    margin-right:0;
  }
  @media screen and (max-width: 991px) {
    & > .ant-collapse-item .ant-collapse-header {
      font-size: calc(${(props) =>
        props.theme[props.theme.mode].textSizes.relative.captions} - 4px);
    }
  }
  @media screen and (max-width: 767px) {
    & > .ant-collapse-item .ant-collapse-header {
      font-size: ${(props) =>
        props.theme[props.theme.mode].textSizes.actual.links};
    }
    &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding-right: 3rem;
    }
  }
`;
const faqs = [
  {
    id: 1,
    question: "Who is EscrowTrakker designed for?",
    answer: (
      <React.Fragment>
        <p style={{ color: "#1E1E22" }}>
          EscrowTrakker is designed for anyone who does escrow accounting for
          any size law firm.
          {/* <br />
          <br />
          <ol style={{ paddingInlineStart: "15px" }}>
            <li>
              Subtract each customer balance from the EscrowTrakker Bank account
              starting balance
            </li>
            <li>
              Enter a new Deposittransaction in EscrowTrakker for the customer
              balance to the appropriate Greenaccount, customer and job.
            </li>
            <li>
              A starting balance of $0 will transfer all accounts from your old
              books to EscrowTrakker. It’s that easy.
            </li>
          </ol> */}
        </p>
      </React.Fragment>
    ),
  },

  {
    id: 2,
    question: "Do I need to know how to do accounting to use this app?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        EscrowTrakker was designed to be incredibly simple for people with no
        accounting experience. Just enter your transactions accurately and you
        will have precise escrow accounting and easy to access reports.
      </p>
    ),
  },
  {
    id: 3,
    question: "How do I switch from my current system to EscrowTrakker?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        To transfer your escrow accounting from your old system to
        EscrowTrakker, you will:
        <br />
        <br />
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>
            When you create an EscrowTrakker bank account, you will see a bank
            balanceof $0
          </li>
          <li>
            Using your most recent reconciled bank statement, enter each
            customer balance as a deposit, until total balance in EscrowTrakker
            equals bank statement balance. You will be prompted to create a new
            customer and job for each deposit
          </li>
          <li>
            Add all subsequent new deposits and withdrawals directly into
            EscrowTrakker
          </li>
          <li>Then, simply add future deposits and withdrawals</li>
          <li>
            Once you enter your initial customer balances, you will be able to
            reconcile your bank statement with those opening transactions and
            print a three-way reconciliation
          </li>
        </ol>
      </p>
    ),
  },
  {
    id: 4,
    question: "How do I do my monthly bank statement reconciliation?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>
            Check the box next to each transaction that appears on your bank
            statement
          </li>
          <li>
            Enter new transactions in EscrowTrakker for fees, bank charges and
            interest
          </li>
          <li>
            After bank accounts are reconciled with bank statements, you can
            generate a 3-way reconciliation report for each bank account by
            clicking on “Reports” and selecting “Reconciliation Reports”
          </li>
        </ol>
      </p>
    ),
  },

  {
    id: 5,
    question:
      "How do I create a buffer balance in my escrow account for future fees and bank charges?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>
            Deposit funds in the bank account and assign them to customer: law
            firm name, job: law firm name admin, and account: Lawyers Reserve
            account
          </li>
          <li>
            You can also transfer funds from Client Retainer account to Lawyers
            Reserve account once the funds are earned
          </li>
        </ol>
      </p>
    ),
  },

  {
    id: 6,
    question: "How do I transfer earned funds to lawyer's reserve?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>Select “Add Transaction”</li>
          <li>Select “Transfer”</li>
          <li>Select a bank account</li>
          <li>Enter “From” information</li>
          <li>
            Enter “To” information. Note: Customer Name will be the name of your
            law firm
          </li>
          <li>
            To split a transfer between accounts:
            <div style={{ marginLeft: "2rem" }}>
              <p>accounts: o Select “Add Splits”</p>
              <p>o Enter the following for each split:</p>
            </div>
            <div style={{ marginLeft: "-3rem" }}>
              <ol>
                <li>Account</li>
                <li>Job name</li>
                <li>Amount</li>
                <li>
                  To view your transfers, select “Transfer Journal” from the
                  app’s home page
                </li>
              </ol>
            </div>
          </li>
        </ol>
      </p>
    ),
  },

  {
    id: 7,
    question:
      "How do I see escrow deposits and withdrawals for a particular customer and/or job?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>
            Select “Pie Charts”. Select customer and/or job, for all accounts
          </li>
          <li>
            Results will be displayed on 3 interactive pie charts and a
            histogram
          </li>
          <li>You can print or e-mail results with corresponding ledger</li>
        </ol>
      </p>
    ),
  },
  {
    id: 8,
    question: "How do I see all client balances in Client Retainer account?",

    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>Select “Pie Charts"</li>
          <li>Enter Client Retainer, all customers, all jobs</li>
          <li>Tap “Preview Report"</li>
        </ol>
      </p>
    ),
  },
  {
    id: 9,
    question: "Can I print checks?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        Yes. Any withdrawal can be printed on your pre-printed check forms.
        EscrowTrakker accommodates 1 to a page or 3 to a page format.
        <br />
        <br />
        To print checks:
        <br />
        <br />
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>Load pre-printed checks into printer</li>
          <li>Select “Print Checks” on the transaction screen</li>
          <li>Select the withdrawal transactions you want to print</li>
          <li>Select “Print”</li>
        </ol>
      </p>
    ),
  },
  {
    id: 10,
    question: "Will EscrowTrakker notify me if I try to overdraw an account?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        Yes. If you attempt to overdraw a customer or job balance or bank
        account balance, you will be notified.
      </p>
    ),
  },
  {
    id: 11,
    question:
      "What kind of reports can I export from EscrowTrakker and how do I export reports?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        To print any of the following reports, go to “Reports,” create the
        report, and select “Export” to send it to your printer or email the file
        in HTML, PDF or CSV format:
        <br />
        <br />
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>
            <b>Chart of accounts</b> – list of pre-loaded income and expense
            accounts, fully customizable
          </li>
          <li>
            <b>3-Way reconciliation report</b> – The law requires you to
            reconcile each IOLTA and trust account two ways at the end of the
            month – once by the bank account ledger and once by customer
            balance. These totals must match eachother. And they must match the
            totals on your bank statement, resulting in a 3-way reconciliation
            report. This currently manual process has been completely mechanized
            and can be done automatically at the push of a button on
            EscrowTrakker for up to 200 separate bank accounts at a time
          </li>
          <li>
            <b>Trial balance summary</b> – Balance of all accounts - Print for
            the entire company or for an individual lawyer
          </li>
          <li>
            <b>Profit and loss</b> – Create a detailed or summary report for the
            entire company or for an individual lawyer
          </li>
        </ol>
      </p>
    ),
  },
  {
    id: 12,
    question: "Will I be automatically enrolled after my 28-day trial period?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        No, you will not be automatically enrolled after your trial period, but
        you will be prompted to continue your EscrowTrakker enrollment.
      </p>
    ),
  },
  {
    id: 13,
    question: "How to do I upgrade or cancel my account?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        Go to settings, where you will click on My Plans and select the package
        you would like or choose to unsubscribe.
      </p>
    ),
  },
  {
    id: 14,
    question: "How do I contact customer service?",
    answer: (
      <p style={{ color: "#1E1E22" }}>
        <ol style={{ paddingInlineStart: "15px" }}>
          <li>Go to “Settings”</li>
          <li>Select “Contact Support”</li>
          <li>Send a message through the app</li>
        </ol>
      </p>
    ),
  },
];
const Faq = () => {
  return (
    <Fragment>
      <Row gutter={[20, 40]} type="flex" align="middle" justify="center">
        <Col span={24}>
          <h1 className="fontBold textCenter" style={{ color: "#1E1E22" }}>
            Frequently Asked Questions
          </h1>
        </Col>
        <Col span={24}>
          {faqs.map((faq) => (
            <Accordion
              key={faq.id}
              expandIconPosition={"right"}
              expandIcon={(e) =>
                (e.isActive && (
                  <FiChevronUp style={{ fontSize: "1.7rem" }} />
                )) || <FiChevronDown style={{ fontSize: "1.7rem" }} />
              }
            >
              <Panel header={faq.question}>
                <div>{faq.answer}</div>
              </Panel>
            </Accordion>
          ))}
        </Col>
      </Row>
    </Fragment>
  );
};

export default Faq;
