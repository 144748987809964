import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import Tabs from "../../components/tabs";
import FilterRecurring from "../../appcomponents/EscrowTrakker/detailbanks/FilterRecurring"
import Deposit from "./addtranscation/Deposit";
import WithDrawal from "./addtranscation/WithDrawal";
import Transfer from "./addtranscation/Transfer";
import styled from "styled-components";
import Recurring from "./addtranscation/Recurring";
import PlusButton from "../../appcomponents/EscrowTrakker/formcontrols/IconButton";
import { getUserSettingRequest } from "../../actions/Settings";

const TransactionWrapper = styled.div`
  margin: 2.4rem 0;
`;
const AddTrasaction = props => {
  console.log("AddTrasaction", props.location.state);
  const [tab, setTab] = useState(props.location.state && props.location.state.tab ? props.location.state.tab : "deposit");
  const [frequency, setfrequency] = useState(props.location.state && props.location.state.frequency ? props.location.state.frequency : "");
  const [bank, setBank] = useState(props.location.state && props.location.state.bank ? props.location.state.bank : null);
  const [isRedirect, setisRedirect] = useState(props?.location?.state && props?.location?.state?.isRedirect ? props?.location?.state?.isRedirect : null);
  const [referrerState, setReferrerState] = useState(props?.location?.state?.referrerState ? props?.location?.state?.referrerState : null);
  const handleFilterChange = val => {
    console.log("val month", val);
    // fun(val);
    setfrequency(val);
  };

  useEffect(() => {
    if(props.location.state && props.location.state.addType) {
      setBank(null);
    }
  }, [])

  useEffect(() => {
    props.getUserSettingRequest();
  }, []);

  // useEffect(() => {
  //   if (props.location.state) {
  //     if (props.location.state.tab) {
  //       setTab(props.location.state.tab);
  //     }
  //   } else {
  //     setTab("deposit");
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Row type="flex" justify="center" align="middle">
        <Col span={24}>
          <Row gutter={20} type="flex" justify="center" align="middle" gutter={[{ xs: 0, sm: 10 }, 20]}>
            <Col md={4} xl={4}>
              {tab === "recurring" && (
                <PlusButton
                  onClick={() =>
                    props.history.push({
                      pathname: "/add-recurring",
                      state: { referrer: "/add-transaction", isRedirect: isRedirect, referrerState: { tab: "recurring", bank, frequency }, bank }
                    })
                  }
                >
                  Add New
                </PlusButton>
              )}
            </Col>
            <Col xs={24} md={16} xl={16}>
              <Tabs
                tabWrap={{
                  onChange: e => setTab(e),
                  activeKey: tab
                }}
                tabItem={[
                  {
                    tab: "Deposit",
                    key: "deposit"
                  },
                  {
                    tab: "Withdrawal",
                    key: "withdrawal"
                  },
                  {
                    tab: "Transfer",
                    key: "transfer"
                  },
                  {
                    tab: "Recurring",
                    key: "recurring"
                  }
                ]}
              />
            </Col>
            <Col xs={10} md={4} xl={4}>
              {tab === "recurring" && (
                <FilterRecurring
                frequencies={{
                    value: frequency,
                    onChange: val => handleFilterChange(val)
                  }}
                
                />
               
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          lg={tab === "recurring" ? 24 : 20}
          xl={tab === "recurring" ? 24 : 15}
        >
          {tab === "deposit" ? (
            <TransactionWrapper>
              <Deposit bank={bank} isRedirect={isRedirect} referrerState={referrerState}/>
            </TransactionWrapper>
          ) : tab === "withdrawal" ? (
            <TransactionWrapper>
              <WithDrawal bank={bank} isRedirect={isRedirect} referrerState={referrerState}/>
            </TransactionWrapper>
          ) : tab === "transfer" ? (
            <TransactionWrapper>
              <Transfer bank={bank} isRedirect={isRedirect}/>
            </TransactionWrapper>
          ) : tab === "recurring" ? (
            <TransactionWrapper>
              <Recurring bank={bank} frequencies={frequency} isRedirect={isRedirect}/>
            </TransactionWrapper>
          ) : null}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default connect(null, { getUserSettingRequest })(AddTrasaction);
