import React, { useState } from "react";
import RootForm from "../../../components/form/index";
import { contactForm } from "../../../configurations/Schemas/index";
import Input from "../../../components/input";
import Option from "../../../components/input/SelectOptions";
import TextArea from "../../../components/input/TextArea";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { contactRequest } from "../../../actions/App";
import { Row } from "antd";

const ContactForm = props => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: ""
  });
  const handleChange = e => {
    //const { name, value } = e.target;
    setState({
      ...state
    });
  };
  const btnstyles =
    props.size >= 991
      ? {
          //marginRight: 0
          margin: "2rem 4rem auto",
          borderRadius: "3px"
        }
      : {
          // marginRight: "auto"
        };
  return (
    <Row type="flex" justify="center" align="middle">
      <RootForm
        schemaName={contactForm}
        initialValues={state}
        apiCall={props.contactRequest}
        // changeEmail={props.changeEmail}
        autoWidthSubmit
        resetAfterSubmit
        loading={props.App.contactFormLoading}
        // cb={() => {props.history.push("/")}}
        controls={[
          {
            span: 24,
            name: "name",
            className: "lightBg",
            label: {
              name: "Name",
              attr: {
                className: "whiteColor textLeft medium mr-b-sm",

              }
            },
            value: state.name,
            handleChanged: handleChange,
            placeholder: "Enter Name",
            Type: Input
          },
          {
            span: 24,
            name: "email",
            className: "lightBg",
            label: {
              name: "Email",
              attr: {
                className: "whiteColor textLeft medium mr-b-sm",
              }
            },
            value: state.email,
            handleChanged: handleChange,
            placeholder: "Enter Email",
            Type: Input
          },

          {
            span: 24,
            rows: 4,
            className: "lightBg",
            name: "message",
            label: {
              name: "Message",
              attr: {
                className: "whiteColor textLeft medium mr-b-sm",
              }
            },
            value: state.message,
            handleChanged: handleChange,
            placeholder: "Enter Message",
            Type: TextArea
          }
        ]}
        submitButton={{
          span: 24,
          buttonText: "Send",
          style: {
            // display: "block",
            width: "50%",
            margin: "2.rem auto",
            padding: ".5rem 0",
            marginTop:"1rem",
            rows:4,
            ...btnstyles
          }
        }}
      />
    </Row>
  );
};

// ContactForm.propTypes = {
//   contactRequest: PropTypes.func.isRequired
// };

export default connect(state => ({ App: state.App }), { contactRequest })(
  ContactForm
);
